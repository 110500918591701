.region--list {
    padding-bottom: 60px;

    .region--list-cont {

        .region--list-flex {
            @include flex;
            align-items: center;
            width: 100%;
            margin-bottom: 80px;

            @include mq($until: tablet) {
                flex-wrap: wrap;
                flex-direction: row !important;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:nth-child(even) {
                flex-direction: row-reverse;

                .region--item-img {
                    @include radius-left;
                }

            }

            &:nth-child(odd) {

                .region--item-img {
                    @include radius-right;
                }

            }

            .region--item-img {
                display: inline-block;
                width: 65%;
                position: relative;
                overflow: hidden;

                @include mq($until: laptop-l) {
                    width: 55%;
                }

                @include mq($until: laptop-s) {
                    width: 50%;
                }

                @include mq($until: tablet) {
                    width: 95%;
                    @include radius-right;
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                }

                img, video {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

            }

            .region--item-copy {
                display: inline-block;
                width: 35%;
                padding: 0 40px;

                @include mq($until: laptop-l) {
                    width: 45%;
                }

                @include mq($until: laptop-s) {
                    width: 50%;
                }

                @include mq($until: tablet) {
                    width: 100%;
                    margin-top: 30px;
                    padding: 0 25px;
                }

                a {
                    text-decoration: none;

                    h2 {
                        @include font(34px, white);
                        @include font-weight(semibold);
                        margin: 0 0 10px 0;
                        position: relative;
                        text-decoration: none;
                        padding-right: 35px;
    
                        i {
                            display: inline-block;
                            position: absolute;
                            right: 0;
                            top: 0;
                            height: 100%;
        
                            img {
                                display: block;
                                width: 30px;
                                height: 30px;
                                @include valign;
                            }
                        
                        }
    
                    }

                }

                h5 {
                    @include font(20px, $gold);
                    @include font-weight(regular);
                    margin: 12px 0;
                }

                p {
                    @include font(18px, white);
                    @include font-weight(regular);
                }

                ul {
                    display: block;

                    li {
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    
                        a {
                            text-decoration: underline;
                            display: block;
                            @include font(18px, $light-blue);
                            @include font-weight(medium);
                        }

                    }

                }

            }

        }

    }

}