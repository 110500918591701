.cheese--filter {
    @include blue-grad;
    padding-top: 180px;
    padding-bottom: 60px;

    @include mq($until: tablet) {
        padding-top: 120px;
    }

    .cheese--filter-banner-copy {
        display: block;
        text-align: right;
        width: 100%;
        margin: 0;
        @include flex;
        flex-direction: column;
        align-items: flex-end;

        @include mq($until: tablet) {
            align-items: flex-start;
            text-align: left;
        }

        h1 {
            font-family: $relation;
            font-size: 84px;
            color: $gold;
            margin: 0;
            line-height: 1;

            @include mq($until: laptop-s) {
                font-size: 62px;
            }

            @include mq($until: tablet) {
                text-align: left;
            }

            @include mq($until: mobile-l) {
                font-size: 48px;
            }

        }

        p {
            display: block;
            width: 85%;
            text-align: right;
            @include font(34px, white);
            @include font-weight(light);
            padding-bottom: 0;

            @include mq($until: laptop-s) {
                width: 100%;
                max-width: 100%;
                font-size: 32px;
            }

            @include mq($until: tablet) {
                text-align: left;
            }

            @include mq($until: mobile-l) {
                font-size: 24px;
            }

            sup {
                font-size: 18px;
                top: 0;
            }

        }

    }

    .cheese--filter-header {
        @include flex;
        justify-content: space-between;
        margin: 60px 0 40px 0;

        @include mq($until: tablet) {
            margin-top: 35px;
        }

        .cheese--filter-header-left {
            display: inline-flex;
            display: -webkit-inline-flex;
            // width: 40%;
            width: 100%;

            .open--filter {
                display: inline-flex;
                display: -webkit-inline-flex;
                align-items: center;
                background-color: white;
                padding: 15px 20px;
                text-align: center;
                border-radius: 5px;
                cursor: pointer;
                @include animate;
                border: 1px solid white;
                justify-content: center;

                @include mq($until: mobile-l) {
                    width: 49%;
                    padding: 15px 15px;
                }

                &:hover {
                    background-color: $bg-grey;
                }

                &.active {
                    background-color: $blue;
                    color: white;

                    span {
                        color: white;
                    }

                    i {
                        display: none;

                        &.close {
                            display: inline-block;
                        }

                    }

                }
                
                span {
                    @include font(16px, $blue);
                    @include font-weight(medium);
                }

                i {
                    display: inline-block;
                    height: 100%;
                    margin-right: 12px;

                    @include mq($until: mobile) {
                        max-width: 20px;
                        margin-right: 10px;
                    }

                    &.close {
                        display: none;
                    }
        
                    img, picture {
                        display: block;
                        width: 100%;
                        height: auto;
                        @include valign;
                    }
                
                }

            }

            .filter-spacer {
                display: inline-block;
                width: 1px;
                background-color: white;
                margin: 0 15px;
            }

            .cheese--viewall {
                display: inline-flex;
                display: -webkit-inline-flex;
                align-items: center;
                padding: 15px 35px;
                background-color: $gold;
                text-align: center;
                border-radius: 5px;
                cursor: pointer;
                @include animate;
                text-decoration: none;
                @include font(16px, white);
                @include font-weight(medium);
                justify-content: center;

                @include mq($until: mobile-l) {
                    width: 49%;
                    padding: 15px 15px;
                }

                &:hover {
                    background-color: $dark-gold;
                }

            }

        }

        .cheese--filter-header-right {
            display: inline-flex;
            display: -webkit-inline-flex;
            align-items: center;

            p {
                display: inline-block;
                @include font(16px, white);
                @include font-weight(medium);
                text-align: right;
                padding: 0;
                margin: 0 20px 0 0;
            }

            .sort--type {
                display: inline-block;
                background-color: white;
                appearance: none;
                -webkit-appearance: none;
                width: 170px;
                border: none;
                @include font(16px, $blue);
                @include font-weight(medium);
                text-align: left;
                padding: 15px 15px;
                border-radius: 5px;
                background-image: url('../static/icon-blue-arrow.svg');
                background-repeat: no-repeat;
                background-position: center right 12px;
                background-size: 13px 6px;
                box-shadow: none;
                cursor: pointer;
            }

        }

    }

    .cheese--list {
        @include flex;
        align-items: flex-start;

        &.active {
            .cheese--list-main {
                @include mq($until: laptop-s) {
                    grid-template-columns: repeat(2, 1fr);
                }

                .cheese--list-item {
                    .cheese--list-item-img {
                        height: 17.6cqw;
    
                        @include mq($until: laptop-s) {
                            height: 28.6cqw;
                        }
    
                        @include mq($until:tablet) {
                            height: 45cqw !important;
                        }
    
                    }
                }
            
            }
        }

        .cheese--list-filter {
            display: none;
            width: 20%;
            min-width: 20%;
            padding-right: 30px;
            opacity: 0;
            min-width: 280px;

            @include mq($until: tablet) {
                position: fixed;
                overflow-x: hidden;
                overflow-y: scroll;
                left: 0;
                top: 0;
                bottom: 0;
                z-index: 100;
                width: 100%;
                max-width: 100%;
                background-color: $blue;
                padding: 20px 20px 50px 20px;
            }

            &.active {
                display: inline-block;
                opacity: 1;
            }

            .close--filter {
                display: none;
                align-items: center;
                background-color: $gold;
                padding: 15px 20px;
                text-align: center;
                cursor: pointer;
                @include animate;
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                justify-content: center;

                @include mq($until: tablet) {
                    display: inline-flex;
                    display: -webkit-inline-flex;
                }
                
                span {
                    @include font(16px, white);
                    @include font-weight(medium);
                }

                i {
                    display: inline-block;
                    height: 100%;
                    margin-right: 12px;
        
                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                    }
                
                }

            }

            .filter-form-group {
                display: block;
                margin-bottom: 40px;

                &:last-child {
                    margin-bottom: 0;
                }

                &.open {
                    .filter-form-dropdown {
                        i {
                            svg {
                                .vertical {
                                    display: none;
                                }
                            }
                        }
                    }

                    .filter-form-dropdown-content {
                        height: auto;
                        opacity: 1;
                        @include animate;
                    }
                }

                .filter-form-dropdown {
                    display: block;
                    @include font(16px, white);
                    @include font-weight(medium);
                    text-align: left;
                    padding: 20px 20px;
                    border: 1px solid white;
                    border-radius: 5px;
                    line-height: 1;
                    position: relative;
                    cursor: pointer;

                    i {
                        display: inline-block;
                        position: absolute;
                        right: 12px;
                        top: 0;
                        height: 100%;
            
                        svg {
                            display: block;
                            width: 20px;
                            height: 20px;
                            @include valign;
                        }
                    
                    }

                }

                .filter-form-dropdown-content {
                    height: 0;
                    overflow: hidden;
                    opacity: 0;
                    margin-top: 20px;
                    margin-left: 20px;
                    @include animate;

                    .filter-form-dropdown-check {
                        display: block;
                        width: 100%;
                        @include font(16px, white);
                        @include font-weight(regular);
                        position: relative;
                        cursor: pointer;
                        user-select: none;
                        padding-left: 25px;
                        padding-bottom: 10px;

                        &:last-child {
                            padding-bottom: 0;
                        }

                        input[type="checkbox"] {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            height: 0;
                            width: 0;
                        }

                        .checkmark {
                            display: inline-block;
                            position: absolute;
                            top: 2px;
                            left: 0;
                            width: 16px;
                            height: 16px;
                            background-color: white;
                            border-radius: 0;

                            &:after {
                                content: '';
                                position: absolute;
                                display: none;
                                width: 5px;
                                top: 2px;
                                left: 5px;
                                height: 10px;
                                border: solid white;
                                border-width: 0 2px 2px 0;
                                transform: rotate(45deg);
                            }

                        }

                        input:checked ~ .checkmark {
                            background-color: $gold;

                            &:after {
                                display: block;
                            }

                        }

                    }

                }

            }

        }

        .cheese--list-main {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 30px;
            grid-row-gap: 30px;

            @include mq($until: laptop-m) {
                grid-template-columns: repeat(3, 1fr);
                grid-column-gap: 20px;
                grid-row-gap: 20px;
            }

            @include mq($until: tablet) {
                grid-template-columns: repeat(2, 1fr);
            }

            .cheese--list-item {
                display: inline-block;
                text-decoration: none;

                .cheese--list-item-img {
                    display: block;
                    width: 100%;
                    container: card / size;
                    height: 22.4cqw;
                    border-radius: 20px;
                    position: relative;
                    overflow: hidden;

                    @supports not (container-type: inline-size) {
                        height: auto;
                        max-height: 170px;
                    }

                    @include mq($until:tablet) {
                        height: 45cqw;
                    }

                    img, picture {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                
                }

                .cheese--list-item-copy {
                    display: block;
                    width: 100%;
                    margin-top: 20px;

                    h2 {
                        @include font(28px, white);
                        @include font-weight(semibold);
                        margin: 0 0 8px 0;
                        text-decoration: none;
                        position: relative;
                        padding-right: 35px;
                        font-size: 1.5cqw;

                        @include mq($until: laptop-l) {
                            font-size: 1.7cqw; 
                        }

                        @include mq($until: laptop-s) {
                            font-size: 2.2cqw; 
                        }

                        @include mq($until: tablet) {
                            font-size: 4.5cqw; 

                            @supports not (container-type: inline-size) {
                                font-size: 18px;
                            }

                        }

                        i {
                            display: inline-block;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            top: 0;
                
                            img {
                                display: block;
                                width: 30px;
                                height: 30px;
                                @include valign;

                                @include mq($until:tablet) {
                                    width: 25px;
                                    height: 25px;
                                }

                            }
                        
                        }

                    }

                    p {
                        @include font(16px, white);
                        @include font-weight(regular);
                        padding: 0;
                        text-decoration: none;

                        @include mq($until: tablet) {
                            font-size: 14px; 
                        }

                    }

                }

            }

        }

    }

}

body.noscroll {
    @include mq($until: tablet) {
        overflow: hidden;
        position: fixed;
    }
}