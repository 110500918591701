.home--banner {
    @include blue-grad;
    padding-top: 200px;
    padding-bottom: 25vw;

    @include mq($until: tablet) {
        padding-top: 140px;
    }

    .header--flex {
        @include flex;
        align-items: center;

        .home--banner-left {
            display: inline-block;
            width: 65%;
            overflow: hidden;
            @include radius-right;
            flex-shrink: 2;

            @include mq($until: laptop-s) {
                width: 50%;
                flex-shrink: 5;
            }

            @include mq($until: tablet) {
                display: none;
            }
    
            img, picture {
                display: block;
                width: 100%;
                height: auto;
            }
    
        }
    
        .home--banner-copy {
            display: inline-block;
            width: 55%;
            padding: 0 40px 0 60px;

            @include mq($until: laptop-s) {
                padding: 0 0 0 40px;
                width: 50%;
            }

            @include mq($until: tablet) {
                width: 100%;
                padding: 0 20px;
            }
    
            h1 {
                @include font(78px, white);
                @include font-weight(light);
                line-height: 1;
                margin: 0 0 60px 0;

                @media screen and (max-width:1300px) {
                    font-size: 5.6cqw;
                    margin-bottom: 3.5cqw;
                }

                @include mq($until: tablet) {
                    font-size: 44px;
                }

                @include mq($until: mobile-m) {
                    font-size: 12cqw;
                }
    
                span {
                    font-family: $relation;
                    font-size: 100px;
                    font-weight: normal;
                    color: $gold;
                    line-height: 0.7;

                    @media screen and (max-width:1300px) {
                        font-size: 6.5cqw;
                    }

                    @include mq($until: tablet) {
                        font-size: 60px;
                    }

                    @include mq($until: mobile-m) {
                        font-size: 15cqw;
                    }

                }
    
            }

            .home--banner-subcopy {
                display: block;
                width: 83%;

                @include mq($until: laptop-s) {
                    width: 100%;
                    padding-right: 30px;
                }

                @include mq($until: tablet) {
                    padding-right: 0;
                }

                h3 {
                    @include font(18px, $gold);
                    @include font-weight(regular);
                    margin: 0 0 20px 0;
                }
        
                p {
                    @include font(18px, white);
                    @include font-weight(regular);

                    @include mq($until: tablet) {
                        font-size: 16px;
                    }
        
                    a {
                        color: $light-blue;
                        text-decoration: underline;
                    }
        
                }

            }
    
        }

        .home--banner-right {
            display: inline-block;
            width: 18%;
            overflow: hidden;
            @include radius-left;
            align-self: flex-start;
            margin-top: -100px;

            @media screen and (max-width:1380px) {
                margin-top: -70px;
            }

            @include mq($until: laptop-s) {
                display: none;
            }
            
            img, picture {
                display: block;
                width: 100%;
                height: auto;
            }

        }

    }

}