.cheese--content {
    background-color: white;
    padding: 80px 0;

    @include mq($until: tablet) {
        padding: 40px 0;
    }

    .cheese--content-flex {
        @include flex;
        justify-content: space-between;

        @include mq($until: laptop-s) {
            flex-wrap: wrap;
        }

        &.no-notes {

            @include mq($until: laptop-s) {
                .cheese--content-img {
                    display: none;
                }
                .cheese--content-copy {
                    width: 100%;
                    padding: 20px 0 0 0;
                }
            }

        }

        .cheese--content-region {
            display: inline-block;
            width: calc(100% / 3);
            margin-right: 20px;
            @include radius-left;
            @include radius-right;
            position: relative;
            overflow: hidden;
            text-decoration: none;

            @include mq($until: laptop-s) {
                width: 100%;
                margin: 0 0 20px 0;
                height: 35vw;
            }

            img, picture {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .cheese--content-region-name {
                display: block;
                position: absolute;
                text-decoration: none;
                bottom: 30px;
                left: 0;
                right: 0;
                @include font(28px, white);
                @include font-weight(medium);
                width: calc(100% - 80px);
                margin: 0 auto;
                padding: 0 50px 0 0;

                @include mq($until: tablet) {
                    width: calc(100% - 40px);
                    bottom: 20px;
                }

                @include mq($until: mobile-l) {
                    font-size: 5.5vw;
                }

                i {
                    display: inline-block;
                    position: absolute;
                    right: 0;
                    height: 100%;

                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                        @include valign;
                    }
                
                }

            }

        }

        .cheese--content-img {
            display: inline-block;
            width: calc(100% / 3);
            @include radius-left;
            @include radius-right;
            position: relative;
            overflow: hidden;
            margin-right: 20px;

            @include mq($until: laptop-s) {
                width: 100%;
                margin: 0 0 20px 0;
                height: 30vw;
            }

            img, picture {
                display: block;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

        }

        .cheese--content-tasting {
            display: inline-block;
            width: calc(100% / 3);
            background-color: $bg-grey;
            @include radius-left;
            @include radius-right;
            padding: 40px 40px;
            margin-right: 20px;

            @include mq($until: laptop-s) {
                width: calc(50% - 20px);
            }

            @media screen and (max-width: 650px) {
                width: 100%;
                margin-right: 0;
                padding: 20px;
            }

            .tasting-note {
                display: inline-block;
                width: 100%;
                border-bottom: 1px solid #D1D1D1;
                padding: 30px 0;

                &:first-child {
                    padding-top: 0;
                }

                &:last-child {
                    border: none;
                    padding-bottom: 0;
                }

                h3 {
                    @include font(34px, $blue);
                    @include font-weight(semibold);
                    margin: 0 0 20px 0;
                }

                p {
                    @include font(18px, $blue);
                    @include font-weight(regular);
                }

            }

        }

        .cheese--content-copy {
            display: inline-block;
            width: calc(100% / 3);
            padding: 40px 0 40px 20px;

            @include mq($until: laptop-s) {
                width: 50%;
            }

            @media screen and (max-width: 650px) {
                width: 100%;
                padding: 20px 0 0 0;
            }

            h4 {
                @include font(34px, $blue);
                @include font-weight(bold);
                margin: 0 0 20px 0;
            }

            p, li {
                @include font(18px, $blue);
                @include font-weight(regular);
            }

            a {
                color: $gold;
                text-decoration: underline;
            }

            ul {
                list-style: disc;
                padding-left: 20px;
                padding-bottom: 10px;
            }

            ol {
                padding-left: 20px;
            }

        }

    }

}

.cheese--content.butter {

    .cheese--content-flex {
        flex-wrap: nowrap;

        @include mq($until: tablet) {
            flex-wrap: wrap;
        }
        
        .cheese--content-img {
            width: 75%;
            margin-right: 40px;

            @include mq($until: laptop-s) {
                width: 50%;
                height: auto;
                margin-right: 0;
            }

            @include mq($until: tablet) {
                width: 100%;
                height: 30vw;
            }

        }
        .cheese--content-copy {
            @include mq($until: laptop-s) {
                width: 50%;
                padding-left: 40px;
            }

            @include mq($until: tablet) {
                width: 100%;
                padding: 20px 0 0 0;
            }
        }
    }

}