.cheese--banner {
    @include blue-grad;
    padding-top: 200px;
    padding-bottom: 60px;

    @media screen and (max-width:920px) {
        padding-top: 120px;
    }

    @include mq($until: tablet) {
        padding-bottom: 30px;
    }

    .cheese--banner-flex {
        @include flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width:920px) {
            flex-wrap: wrap;
        }

        .cheese--banner-copy {
            display: inline-block;
            width: 45%;
            padding-left: 40px;

            @media screen and (max-width:920px) {
                width: 100%;
                padding: 0 20px;
                order: 3;
            }

            &.-mob {
                display: none;
                order: 1;

                @media screen and (max-width:920px) {
                    display: block;
                    width: 50%;

                    h1, h2 {
                        display: block;
                    }

                }

                @include mq($until: mobile-l) {
                    width: 100%;
                }

                p {
                    display: none;
                }
            }

            h1 {
                @include font(78px, white);
                @include font-weight(light);
                margin: 0 0 20px 0;
                line-height: 1.2;

                @include mq($until: laptop-m) {
                    font-size: 7vw;
                }

                @media screen and (max-width:920px) {
                    display: none;
                }

                @include mq($until: tablet) {
                    font-size: 9vw;
                }

                span {
                    font-size: 40px;

                    @include mq($until: tablet) {
                        font-size: 5cqw;
                    }

                }

            }

            h2 {
                font-family: $relation;
                font-size: 58px;
                color: $gold;
                margin: 0 0 20px 0;
                line-height: 1.2;

                @include mq($until: laptop-m) {
                    font-size: 5.5vw;
                } 
                
                @media screen and (max-width:920px) {
                    display: none;
                }

                @include mq($until: tablet) {
                    font-size: 6.5vw;
                }

            }

            p {
                @include font(18px, white);
                @include font-weight(regular);

                @include mq($until: tablet) {
                    font-size: 16px;
                }

                a {
                    color: $gold;
                    text-decoration: underline;
                    @include font-weight(semibold);
                }

            }

        }

        .cheese--banner-img {
            display: inline-block;
            width: 45%;
            overflow: hidden;
            position: relative;
            @include radius-left;
            background-color: $blue;

            @media screen and (max-width:920px) {
                // display: none;
                width: 50%;
                order: 2;
                margin-bottom: 30px;
            }

            @include mq($until: mobile-l) {
                width: 100%;
                @include radius-right;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                margin-right: 20px;
            }

            img, picture {
                width: 100%;
                height: auto;
                object-fit: cover;
            }

            .butter--stamp {
                display: block;
                position: absolute;
                bottom: 20px;
                right: 20px;
                width: 22%;

                img, picture {
                    display: block;
                    width: 100%;
                    height: auto;
                    object-fit: fill;
                    aspect-ratio: 1;
                }

            }

        }

    }

    .cheese--banner-usp {
        @include flex;
        justify-content: space-between;
        padding: 0 40px;
        margin: 100px auto 0 auto;

        @media screen and (max-width:920px) {
            padding: 0 20px;
        }

        @include mq($until: tablet) {
            flex-wrap: wrap;
            margin-top: 30px;
        }

        .cheese--usp {
            display: inline-block;
            width: 25%;
            margin: 0 40px;
            max-width: 300px;

            @include mq($until: laptop-m) {
                max-width: 100%;
                margin: 0 15px;
            }

            @include mq($until: tablet) {
                width: calc(50% - 10px);
                margin-left: 0;
                margin-right: 0;

                &:nth-child(1), &:nth-child(2) {
                    margin-bottom: 20px;
                }

                &:nth-child(1), &:nth-child(3) {
                    margin-right: 20px;
                }

            }

            @include mq($until: mobile-l) {
                display: inline-flex;   
                display: -webkit-inline-flex;
                align-items: center;
            }

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }

            .cheese--usp-icon {
                display: block;
                width: 100%;
                margin: 0 auto;
                text-align: center;
                height: 110px;

                @include mq($until: mobile-l) {
                    display: inline-block;
                    width: 70px;
                    height: auto;
                    max-height: 70px;
                }

                img {
                    display: block;
                    margin: 0 auto;
                    @include valign;

                    @include mq($until: mobile-l) {
                        width: auto;
                        height: auto;
                        transform: translateY(0);
                        top: auto;
                        max-height: 70px;
                    }

                }

            }

            .cheese--usp-copy {
                display: block;
                width: 100%;
                text-align: center;

                @include mq($until: mobile-l) {
                    padding-left: 8px;
                }

                h2, h3 {
                    @include font(18px, $gold);
                    @include font-weight(regular);
                    margin: 20px 0 0 0;
                    text-align: center;
                    text-transform: uppercase;

                    @include mq($until: mobile-l) {
                        margin-top: 0;
                        font-size: 16px;
                    }

                }

            }

        }

    }

}