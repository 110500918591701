footer.footer {
    background-color: white; 
    border-top: 1px solid $bg-grey;
    padding: 45px 0;

    .footer--flex {
        @include flex;
        align-items: flex-start;
        justify-content: space-between;

        .footer--left {
            display: inline-flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 60%;

            @include mq($until: laptop-s) {
                width: 90%;
            }

            @include mq($until: mobile-l) {
                width: 75%;
            }

            .footer--links {
                display: inline-block;
                margin-right: 20px;

                &:nth-child(2) {
                    @include mq($until: mobile-l) {
                        display: none;
                    }
                }

                h5 {
                    @include font(14px, $gold);
                    @include font-weight(medium);
                    margin: 0 0 15px 0;
                }

                ul {
                    li {
                        display: block;
                        margin-bottom: 8px;

                        &.hide-mob {
                            display: none;

                            @include mq($until: mobile-l) {
                                display: block;
                            }

                        }

                        a {
                            display: block;
                            @include font(14px, $blue);
                            @include font-weight(regular);
                            text-decoration: none;
                        }

                    }
                }

            }

        }

        .footer--right {
            display: inline-block;

            .social--links {
                text-align: right;

                h5 {
                    @include font(14px, $gold);
                    @include font-weight(medium);
                    margin: 0 0 15px 0;
                    text-align: right;
                }

                ul {
                    li {
                        display: block;
                        text-align: right;
                        margin-bottom: 8px;

                        a {
                            display: block;
                            @include font(14px, $blue);
                            @include font-weight(regular);
                            text-decoration: none;
                        }

                    }
                }

            }

        }

    }

    .footer-copyright {
        @include flex;
        width: 100%;
        border-top: 1px solid $blue;
        padding-top: 20px;
        justify-content: space-between;
        margin-top: 40px;
        align-items: center;

        @include mq($until: mobile-l) {
            flex-wrap: wrap
        }

        .copyright {
            @include font(14px, $blue);
            @include font-weight(regular);
            text-align: left;
            display: inline-block;

            @include mq($until: mobile-l) {
                width: 100%;
                order: 2;
                margin-top: 20px;
            }

        }

        .footer--policies {
            display: inline-block;
            text-align: right;

            @include mq($until: mobile-l) {
                width: 100%;
                text-align: left;
                order: 1;
            }

            ul {
                li {
                    display: inline-block;

                    a {
                        @include font(14px, $blue);
                        @include font-weight(regular);
                        text-decoration: none;
                        display: block;
                    }

                }
            }

        }

    }

}