.latest--news-section {
    background-color: $bg-grey;
    padding: 100px 0;
    
    @include mq($until: tablet) {
        padding: 40px 0;
    }

    h2 {
        @include font(72px, $blue);
        @include font-weight(light);
        margin: 0;

        @include mq($until: laptop-l) {
            font-size: 5.5vw;
        }

        @include mq($until: tablet) {
            font-size: 8vw;
        }

    }

    .latest--news-flex {
        @include flex;
        margin-top: -6vw;

        @media screen and (max-width: 920px) {
            // flex-wrap: wrap;
            width: calc(100% - 60px);
            margin: 30px auto 0 auto;
        } 

        .latest--news-item {
            display: inline-block;
            width: calc(100% / 3 - 14px);
            text-decoration: none;
            container-type: inline-size;
            container-name: latestNews;
            margin-right: 20px;

            @media screen and (max-width: 920px) {
                width: calc(50% - 20px);
                @include flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &:nth-child(1) {
                margin-top: calc(6vw + 40px);

                @media screen and (max-width: 920px) {
                    margin-top: 0;
                }

            }

            &.-recipe {
                margin-right: 0;
                margin-top: 6vw;

                @media screen and (max-width: 920px) {
                    margin-top: 0;
                }

                .latest--news-item-copy {
                    background-color: $blue;
 
                    h3 {
                        color: white;
                    }

                    .latest--news-item-read {
                        p {
                            color: white;
                        }
                    }

                }

            }

            .latest--news-item-copy {
                background-color: white;
                padding: 40px;
                @include radius-top;
                position: relative;

                @include mq($until: laptop-l) {
                    padding: 30px;
                }

                @include mq($until: laptop-s) {
                    padding: 20px;
                }

                @media screen and (max-width:920px) {
                    height: calc(100% - 70cqw);
                    @include flex;
                    flex-direction: column;
                    padding-bottom: 40px;
                    // justify-content: space-between;
                }

                p {
                    @include font(16px, $gold);
                    @include font-weight(regular);
                    padding: 0;
                }

                h3 {
                    @include font(28px, $blue);
                    @include font-weight(semibold);
                    margin: 20px 0 0 0;
                }

                .latest--news-item-read {
                    @include flex;
                    width: 100%;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 30px;

                    @media screen and (max-width:920px) {
                        position: absolute;
                        bottom: 20px;
                        width: calc(100% - 40px);
                        margin: 0 auto;
                    }

                    p {
                        @include font(16px, $blue);
                        @include font-weight(regular);
                        padding: 0 30px 0 0;
                        position: relative;

                        i {
                            display: inline-block;
                            width: 20px;
                            height: 100%;
                            position: absolute;
                            right: 0;
                            top: 0;

                            img {
                                display: block;
                                width: 20px;
                                height: 20px;
                            }

                        }

                    }

                }

            }

            .latest--news-item-img {
                @include radius-bottom;
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 70cqw;

                img, picture {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

            }

        }

    }

}