@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600&display=swap");
/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:
	Eric Meyer					:: http://meyerweb.com
	HTML5 Doctor				:: http://html5doctor.com
	and the HTML5 Boilerplate	:: http://html5boilerplate.com
-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 16px;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

button:focus {
  outline: none;
}

main, article, aside, figure, footer, header, nav, section, details, summary, img {
  display: block;
}

/* Handle box-sizing while better addressing child elements:
   http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/* Responsive images and other embedded objects */
/* if you don't have full control over `img` tags (if you have to overcome attributes), consider adding height: auto */
img,
object,
embed {
  max-width: 100%;
}

/*
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
	In fact, it *will* cause problems with Google Maps' controls at small size.
	If this is the case for you, try uncommenting the following:
#map img {
		max-width: none;
}
*/
/* force a vertical scrollbar to prevent a jumpy page */
html {
  overflow-y: scroll;
}

/* we use a lot of ULs that aren't bulleted.
	you'll have to restore the bullets within content,
	which is fine because they're probably customized anyway */
ul {
  list-style: none;
  margin-before: 1em;
  margin-after: 1em;
  margin-start: 0px;
  margin-end: 0px;
  padding-start: 0;
}

blockquote, q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

pre {
  white-space: pre; /* CSS2 */
  white-space: pre-wrap; /* CSS 2.1 */
  white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word; /* IE */
}

input[type=radio] {
  vertical-align: text-bottom;
}

input[type=checkbox] {
  vertical-align: bottom;
}

select, input, textarea {
  font: 99% sans-serif;
}

table {
  font-size: inherit;
  font: 100%;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

td, td img {
  vertical-align: top;
}

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif;
}

/* hand cursor on clickable elements */
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer;
}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0;
}

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible;
}

.container {
  width: 100%;
  max-width: 100vw;
  margin: 0 auto;
  overflow: visible;
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
}
@media (max-width: 47.99em) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.site-wide {
  width: 100%;
  max-width: 1612px;
  margin: 0 auto;
  overflow: visible;
  position: relative;
  padding-right: 25px;
  padding-left: 25px;
}
@media (min-width: 34.375em) {
  .site-wide {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media (min-width: 100.75em) {
  .site-wide {
    padding-right: 154px;
    padding-left: 154px;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.grid-container:not(.grid-container--no-gap) {
  grid-row-gap: 50px;
}
@media (min-width: 26.875em) {
  .grid-container:not(.grid-container--no-gap) {
    grid-gap: 50px;
  }
}
.grid-container.grid-container--half .grid-column {
  grid-column: span 6;
}
@media (min-width: 64em) {
  .grid-container.grid-container--half .grid-column:nth-of-type(odd) {
    grid-column: 1/span 3;
  }
  .grid-container.grid-container--half .grid-column:nth-of-type(even) {
    grid-column: 4/span 3;
  }
}
.grid-container.grid-container--indent .grid-column {
  grid-column: span 6;
}
@media (min-width: 64em) {
  .grid-container.grid-container--indent .grid-column:nth-of-type(odd) {
    grid-column: 2/span 2;
  }
  .grid-container.grid-container--indent .grid-column:nth-of-type(even) {
    grid-column: 4/span 2;
  }
}
.grid-container.grid-container--4 .grid-column {
  grid-column: span 6;
}
@media (min-width: 64em) {
  .grid-container.grid-container--4 .grid-column {
    grid-column: 2/span 4;
  }
}
.grid-container.grid-container--3 .grid-column {
  grid-column: span 6;
}
@media (min-width: 48em) {
  .grid-container.grid-container--3 .grid-column {
    grid-column: span 3;
  }
}
@media (min-width: 64em) {
  .grid-container.grid-container--3 .grid-column {
    grid-column: span 2;
  }
}
.grid-container.grid-container--4-2 .grid-column {
  grid-column: span 6;
}
@media (min-width: 64em) {
  .grid-container.grid-container--4-2 .grid-column:nth-of-type(odd) {
    grid-column: span 4;
  }
  .grid-container.grid-container--4-2 .grid-column:nth-of-type(even) {
    grid-column: span 2;
  }
}
@media (min-width: 64em) {
  .grid-container.grid-container--4-2.grid-container--reverse .grid-column:nth-of-type(odd) {
    grid-column: span 2;
  }
  .grid-container.grid-container--4-2.grid-container--reverse .grid-column:nth-of-type(even) {
    grid-column: span 4;
  }
}
.grid-container.grid-container--5-1 .grid-column {
  grid-column: span 6;
}
@media (min-width: 64em) {
  .grid-container.grid-container--5-1 .grid-column:nth-of-type(odd) {
    grid-column: span 5;
  }
  .grid-container.grid-container--5-1 .grid-column:nth-of-type(even) {
    grid-column: span 1;
  }
}

@font-face {
  font-family: "Jost";
  src: url("https://www.sodiaal.co.uk/assets/fonts/Jost-VariableFont_wght.ttf") format("truetype-variations");
  font-weight: 1 1000;
}
p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 400;
  padding-bottom: 20px;
}
p:last-child {
  padding-bottom: 0;
}

html.stop-scroll {
  overflow: hidden;
}

body {
  font-family: "Jost", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.4;
  color: #0E4A8C;
  position: relative;
  overflow: hidden;
}

/*
* Hide only visually, but have it available for screenreaders: h5bp.com/v
*/
.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/*
* Extends the .sr-only class to allow the element to be focusable
* when navigated to via the keyboard: h5bp.com/p
*/
.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.blue--grad {
  background: rgb(14, 74, 140);
  background: -moz-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: -webkit-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.swiper-button-prev:after, .swiper-button-next:after {
  background-image: url("../static/swiper-arrow.svg");
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px 31px;
  width: 18px;
  height: 31px;
}

.swiper-button-prev:after {
  transform: rotate(180deg);
}

footer.footer {
  background-color: white;
  border-top: 1px solid #F2F2F2;
  padding: 45px 0;
}
footer.footer .footer--flex {
  display: flex;
  display: -webkit-flex;
  align-items: flex-start;
  justify-content: space-between;
}
footer.footer .footer--flex .footer--left {
  display: inline-flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 60%;
}
@media (max-width: 63.99em) {
  footer.footer .footer--flex .footer--left {
    width: 90%;
  }
}
@media (max-width: 34.365em) {
  footer.footer .footer--flex .footer--left {
    width: 75%;
  }
}
footer.footer .footer--flex .footer--left .footer--links {
  display: inline-block;
  margin-right: 20px;
}
@media (max-width: 34.365em) {
  footer.footer .footer--flex .footer--left .footer--links:nth-child(2) {
    display: none;
  }
}
footer.footer .footer--flex .footer--left .footer--links h5 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 14px;
  color: #B4A74A;
  font-weight: 500;
  margin: 0 0 15px 0;
}
footer.footer .footer--flex .footer--left .footer--links ul li {
  display: block;
  margin-bottom: 8px;
}
footer.footer .footer--flex .footer--left .footer--links ul li.hide-mob {
  display: none;
}
@media (max-width: 34.365em) {
  footer.footer .footer--flex .footer--left .footer--links ul li.hide-mob {
    display: block;
  }
}
footer.footer .footer--flex .footer--left .footer--links ul li a {
  display: block;
  font-family: "Jost", Arial, sans-serif;
  font-size: 14px;
  color: #0E4A8C;
  font-weight: 400;
  text-decoration: none;
}
footer.footer .footer--flex .footer--right {
  display: inline-block;
}
footer.footer .footer--flex .footer--right .social--links {
  text-align: right;
}
footer.footer .footer--flex .footer--right .social--links h5 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 14px;
  color: #B4A74A;
  font-weight: 500;
  margin: 0 0 15px 0;
  text-align: right;
}
footer.footer .footer--flex .footer--right .social--links ul li {
  display: block;
  text-align: right;
  margin-bottom: 8px;
}
footer.footer .footer--flex .footer--right .social--links ul li a {
  display: block;
  font-family: "Jost", Arial, sans-serif;
  font-size: 14px;
  color: #0E4A8C;
  font-weight: 400;
  text-decoration: none;
}
footer.footer .footer-copyright {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  border-top: 1px solid #0E4A8C;
  padding-top: 20px;
  justify-content: space-between;
  margin-top: 40px;
  align-items: center;
}
@media (max-width: 34.365em) {
  footer.footer .footer-copyright {
    flex-wrap: wrap;
  }
}
footer.footer .footer-copyright .copyright {
  font-family: "Jost", Arial, sans-serif;
  font-size: 14px;
  color: #0E4A8C;
  font-weight: 400;
  text-align: left;
  display: inline-block;
}
@media (max-width: 34.365em) {
  footer.footer .footer-copyright .copyright {
    width: 100%;
    order: 2;
    margin-top: 20px;
  }
}
footer.footer .footer-copyright .footer--policies {
  display: inline-block;
  text-align: right;
}
@media (max-width: 34.365em) {
  footer.footer .footer-copyright .footer--policies {
    width: 100%;
    text-align: left;
    order: 1;
  }
}
footer.footer .footer-copyright .footer--policies ul li {
  display: inline-block;
}
footer.footer .footer-copyright .footer--policies ul li a {
  font-family: "Jost", Arial, sans-serif;
  font-size: 14px;
  color: #0E4A8C;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

header.header {
  margin-top: 40px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
@media (max-width: 63.99em) {
  header.header {
    margin-top: 20px;
  }
}
header.header .header--flex {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
}
@media (max-width: 63.99em) {
  header.header .header--flex {
    align-items: center;
  }
}
header.header .header--flex .logo {
  display: inline-block;
}
header.header .header--flex .logo a {
  display: block;
  width: 100%;
  max-width: 350px;
}
@media screen and (max-width: 1500px) {
  header.header .header--flex .logo a {
    max-width: 250px;
  }
}
@media screen and (max-width: 1070px) {
  header.header .header--flex .logo a {
    max-width: 200px;
  }
}
header.header .header--flex .logo a img {
  display: block;
  width: 100%;
  height: auto;
}
header.header .header--flex .header--nav {
  display: inline-block;
}
@media screen and (max-width: 1380px) {
  header.header .header--flex .header--nav {
    width: 77%;
  }
}
header.header .header--flex .burger {
  height: 20px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  z-index: 95;
}
@media (max-width: 63.99em) {
  header.header .header--flex .burger {
    display: flex;
  }
}
header.header .header--flex .burger .burger__line {
  width: 25px;
  height: 2px;
  border-radius: 2px;
  background-color: white;
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}
header.header .header--flex .burger:hover .burger__line {
  background-color: #80C0E7;
}
header.header .header--flex .burger.active .burger__line.burger__line--top {
  transform: translateY(9px) rotate(45deg);
}
header.header .header--flex .burger.active .burger__line.burger__line--middle {
  opacity: 0;
  visibility: hidden;
  transform: translateX(30px);
}
header.header .header--flex .burger.active .burger__line.burger__line--bottom {
  transform: translateY(-9px) rotate(-45deg);
}

nav.nav {
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
}
@media screen and (max-width: 1380px) {
  nav.nav {
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
@media (max-width: 63.99em) {
  nav.nav {
    position: fixed;
    left: 0;
    top: 0;
    background-color: #0E4A8C;
    width: 100%;
    max-width: 360px;
    display: none;
    height: 100%;
    padding: 20px;
    z-index: 100;
  }
}
@media (max-width: 34.365em) {
  nav.nav {
    max-width: 100%;
  }
}
nav.nav .mob--nav-header {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  display: none;
  width: 100%;
  position: relative;
  border-bottom: 1px solid white;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
@media (max-width: 63.99em) {
  nav.nav .mob--nav-header {
    display: flex;
    display: -webkit-flex;
    justify-content: flex-end;
  }
}
nav.nav .mob--nav-header .close--nav {
  display: inline-block;
  cursor: pointer;
  width: 18px;
  height: 18px;
  position: relative;
  z-index: 5;
}
nav.nav .mob--nav-header .close--nav img {
  display: block;
  width: 100%;
  height: auto;
}
nav.nav .mob--nav-header .mob--nav-header-copy {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 600;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}
nav.nav .main--nav {
  display: inline-block;
  margin-right: 20px;
}
@media screen and (max-width: 1380px) {
  nav.nav .main--nav {
    width: 100%;
    margin-right: 0;
  }
}
@media (max-width: 63.99em) {
  nav.nav .main--nav {
    display: block;
    width: 100%;
  }
}
nav.nav .main--nav ul {
  display: flex;
  display: -webkit-flex;
  align-items: center;
}
@media screen and (max-width: 1380px) {
  nav.nav .main--nav ul {
    align-items: flex-end;
    justify-content: flex-end;
  }
}
@media (max-width: 63.99em) {
  nav.nav .main--nav ul {
    display: block;
  }
}
nav.nav .main--nav ul li {
  display: inline-block;
  text-align: center;
  margin: 0 12px;
  line-height: 1.2;
}
@media (max-width: 63.99em) {
  nav.nav .main--nav ul li {
    display: block;
    text-align: left;
    margin: 0 0 20px 0;
  }
}
nav.nav .main--nav ul li:first-child {
  margin-left: 0;
}
nav.nav .main--nav ul li:last-child {
  margin-right: 0;
}
nav.nav .main--nav ul li a {
  text-decoration: none;
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 500;
}
@media (max-width: 63.99em) {
  nav.nav .main--nav ul li a {
    display: block;
    width: 100%;
    position: relative;
    font-size: 16px;
  }
}
nav.nav .main--nav ul li a.active {
  color: #B4A74A;
}
nav.nav .main--nav ul li a i {
  display: none;
}
@media (max-width: 63.99em) {
  nav.nav .main--nav ul li a i {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-image: url("../static/icon-arrow.svg");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 20px 20px;
    transform: translateY(-50%) rotate(180deg);
    position: absolute;
    right: 0;
    top: 50%;
  }
}
nav.nav .secondary--nav {
  display: inline-block;
  min-width: 100px;
}
@media screen and (max-width: 1380px) {
  nav.nav .secondary--nav {
    width: 100%;
    margin-top: 10px;
  }
}
@media (max-width: 63.99em) {
  nav.nav .secondary--nav {
    display: block;
    width: 100%;
    min-width: auto;
  }
}
nav.nav .secondary--nav ul {
  text-align: right;
}
nav.nav .secondary--nav ul li {
  display: inline-block;
  margin: 0 10px;
}
nav.nav .secondary--nav ul li:first-child {
  margin-left: 0;
}
nav.nav .secondary--nav ul li:last-child {
  margin-right: 0;
}
@media (max-width: 63.99em) {
  nav.nav .secondary--nav ul li {
    display: block;
    text-align: left;
    margin: 0 0 20px 0;
  }
}
nav.nav .secondary--nav ul li a {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: #80C0E7;
  font-weight: 400;
  text-decoration: none;
}
nav.nav .secondary--nav ul li a.active {
  color: #B4A74A;
}
nav.nav.active {
  display: block;
}

.nav--overlay {
  display: none;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 90;
}
.nav--overlay.active {
  display: block;
  opacity: 1;
}

.basic--banner.not-found {
  padding-top: 250px;
  padding-bottom: 100px;
}
@media (max-width: 47.99em) {
  .basic--banner.not-found {
    padding-top: 150px;
    padding-bottom: 50px;
  }
}
.basic--banner.not-found .basic--banner-copy {
  margin-bottom: 0;
  text-align: left;
  align-items: flex-start;
}
.basic--banner.not-found .basic--banner-copy h1, .basic--banner.not-found .basic--banner-copy p {
  text-align: left;
}

.home--regions-map {
  width: 46%;
}
.home--regions-map svg#regionMap {
  display: block;
  width: 100%;
  height: auto;
}
.home--regions-map svg#regionMap .region {
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
.home--regions-map svg#regionMap .region:hover .region-bg {
  fill: #B4A74A;
}
.home--regions-map svg#regionMap .region.active .region-bg {
  fill: #B4A74A;
}
.home--regions-map svg#regionMap .region.active .region-button .button-bg {
  fill: #D3C459;
}
.home--regions-map svg#regionMap .region.active .region-button .vertical {
  opacity: 0;
}
.home--regions-map svg#regionMap .blue-bg {
  fill: #4F85B2;
}
.home--regions-map svg#regionMap .region-bg {
  fill: #80c0e7;
}
.home--regions-map svg#regionMap .region-border {
  fill: #96d8ff;
}
.home--regions-map svg#regionMap .main-border {
  fill: #fff;
}
.home--regions-map svg#regionMap .active-button {
  fill: #d3c459;
}
.home--regions-map svg#regionMap .button-bg {
  fill: #0e4a8b;
}

.home--banner {
  background: rgb(14, 74, 140);
  background: -moz-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: -webkit-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 200px;
  padding-bottom: 25vw;
}
@media (max-width: 47.99em) {
  .home--banner {
    padding-top: 140px;
  }
}
.home--banner .header--flex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
}
.home--banner .header--flex .home--banner-left {
  display: inline-block;
  width: 65%;
  overflow: hidden;
  border-top-right-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  flex-shrink: 2;
}
@media (max-width: 63.99em) {
  .home--banner .header--flex .home--banner-left {
    width: 50%;
    flex-shrink: 5;
  }
}
@media (max-width: 47.99em) {
  .home--banner .header--flex .home--banner-left {
    display: none;
  }
}
.home--banner .header--flex .home--banner-left img, .home--banner .header--flex .home--banner-left picture {
  display: block;
  width: 100%;
  height: auto;
}
.home--banner .header--flex .home--banner-copy {
  display: inline-block;
  width: 55%;
  padding: 0 40px 0 60px;
}
@media (max-width: 63.99em) {
  .home--banner .header--flex .home--banner-copy {
    padding: 0 0 0 40px;
    width: 50%;
  }
}
@media (max-width: 47.99em) {
  .home--banner .header--flex .home--banner-copy {
    width: 100%;
    padding: 0 20px;
  }
}
.home--banner .header--flex .home--banner-copy h1 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 78px;
  color: white;
  font-weight: 300;
  line-height: 1;
  margin: 0 0 60px 0;
}
@media screen and (max-width: 1300px) {
  .home--banner .header--flex .home--banner-copy h1 {
    font-size: 5.6cqw;
    margin-bottom: 3.5cqw;
  }
}
@media (max-width: 47.99em) {
  .home--banner .header--flex .home--banner-copy h1 {
    font-size: 44px;
  }
}
@media (max-width: 26.865em) {
  .home--banner .header--flex .home--banner-copy h1 {
    font-size: 12cqw;
  }
}
.home--banner .header--flex .home--banner-copy h1 span {
  font-family: "relation-one", Arial, sans-serif;
  font-size: 100px;
  font-weight: normal;
  color: #B4A74A;
  line-height: 0.7;
}
@media screen and (max-width: 1300px) {
  .home--banner .header--flex .home--banner-copy h1 span {
    font-size: 6.5cqw;
  }
}
@media (max-width: 47.99em) {
  .home--banner .header--flex .home--banner-copy h1 span {
    font-size: 60px;
  }
}
@media (max-width: 26.865em) {
  .home--banner .header--flex .home--banner-copy h1 span {
    font-size: 15cqw;
  }
}
.home--banner .header--flex .home--banner-copy .home--banner-subcopy {
  display: block;
  width: 83%;
}
@media (max-width: 63.99em) {
  .home--banner .header--flex .home--banner-copy .home--banner-subcopy {
    width: 100%;
    padding-right: 30px;
  }
}
@media (max-width: 47.99em) {
  .home--banner .header--flex .home--banner-copy .home--banner-subcopy {
    padding-right: 0;
  }
}
.home--banner .header--flex .home--banner-copy .home--banner-subcopy h3 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: #B4A74A;
  font-weight: 400;
  margin: 0 0 20px 0;
}
.home--banner .header--flex .home--banner-copy .home--banner-subcopy p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 400;
}
@media (max-width: 47.99em) {
  .home--banner .header--flex .home--banner-copy .home--banner-subcopy p {
    font-size: 16px;
  }
}
.home--banner .header--flex .home--banner-copy .home--banner-subcopy p a {
  color: #80C0E7;
  text-decoration: underline;
}
.home--banner .header--flex .home--banner-right {
  display: inline-block;
  width: 18%;
  overflow: hidden;
  border-top-left-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  align-self: flex-start;
  margin-top: -100px;
}
@media screen and (max-width: 1380px) {
  .home--banner .header--flex .home--banner-right {
    margin-top: -70px;
  }
}
@media (max-width: 63.99em) {
  .home--banner .header--flex .home--banner-right {
    display: none;
  }
}
.home--banner .header--flex .home--banner-right img, .home--banner .header--flex .home--banner-right picture {
  display: block;
  width: 100%;
  height: auto;
}

.home--section-blocks {
  position: relative;
  margin-top: -15vw;
  margin-bottom: 100px;
}
@media (max-width: 47.99em) {
  .home--section-blocks {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 660px) {
  .home--section-blocks {
    margin-bottom: 0;
  }
}
.home--section-blocks .home--section-blocks-flex {
  display: flex;
  display: -webkit-flex;
}
@media screen and (max-width: 660px) {
  .home--section-blocks .home--section-blocks-flex {
    flex-wrap: wrap;
  }
}
.home--section-blocks .home--section-blocks-flex .home--section-block {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  width: 25%;
  margin-right: 20px;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  container-type: inline-size;
  container-name: card;
}
@media screen and (max-width: 660px) {
  .home--section-blocks .home--section-blocks-flex .home--section-block {
    width: calc(50% - 10px);
    height: 100%;
    margin-bottom: 20px;
  }
  .home--section-blocks .home--section-blocks-flex .home--section-block:nth-child(odd) {
    margin-right: 20px !important;
  }
  .home--section-blocks .home--section-blocks-flex .home--section-block:nth-child(even) {
    margin-right: 0px !important;
  }
}
.home--section-blocks .home--section-blocks-flex .home--section-block:last-child {
  margin-right: 0;
}
.home--section-blocks .home--section-blocks-flex .home--section-block:hover .home--section-block-copy {
  height: auto;
  bottom: 0;
}
.home--section-blocks .home--section-blocks-flex .home--section-block .home--section-block-img {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-top-left-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  border-top-right-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
@media (pointer: coarse) {
  .home--section-blocks .home--section-blocks-flex .home--section-block .home--section-block-img {
    height: auto;
  }
}
.home--section-blocks .home--section-blocks-flex .home--section-block .home--section-block-img img, .home--section-blocks .home--section-blocks-flex .home--section-block .home--section-block-img picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home--section-blocks .home--section-blocks-flex .home--section-block .home--section-block-copy {
  display: block;
  position: absolute;
  bottom: -100%;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  width: 100%;
  padding: 6cqw 6cqw 0 6cqw;
  background-color: white;
}
@media (pointer: coarse) {
  .home--section-blocks .home--section-blocks-flex .home--section-block .home--section-block-copy {
    position: static;
    height: auto;
  }
}
@media (max-width: 47.99em) {
  .home--section-blocks .home--section-blocks-flex .home--section-block .home--section-block-copy {
    position: static;
    height: auto;
  }
}
.home--section-blocks .home--section-blocks-flex .home--section-block .home--section-block-copy h2 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 34px;
  color: #0E4A8C;
  font-weight: 600;
  margin: 0;
  text-decoration: none;
  position: relative;
  padding: 0 50px 0 0;
}
@media (max-width: 76.615em) {
  .home--section-blocks .home--section-blocks-flex .home--section-block .home--section-block-copy h2 {
    font-size: 10cqw;
    padding-right: 12cqw;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .home--section-blocks .home--section-blocks-flex .home--section-block .home--section-block-copy h2 {
    font-size: 24px;
    margin-bottom: 5px;
  }
}
@media (max-width: 47.99em) {
  @supports not (container-type: inline-size) {
    .home--section-blocks .home--section-blocks-flex .home--section-block .home--section-block-copy h2 {
      font-size: 24px;
    }
  }
}
.home--section-blocks .home--section-blocks-flex .home--section-block .home--section-block-copy h2 span {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 30px;
}
@media (max-width: 76.615em) {
  .home--section-blocks .home--section-blocks-flex .home--section-block .home--section-block-copy h2 span {
    width: 20px;
  }
}
.home--section-blocks .home--section-blocks-flex .home--section-block .home--section-block-copy h2 span img {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.home--section-blocks .home--section-blocks-flex .home--section-block .home--section-block-copy p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: #0E4A8C;
  font-weight: 400;
  padding-bottom: 0;
  text-decoration: none;
  font-size: 5cqw;
}
@media (max-width: 34.365em) {
  .home--section-blocks .home--section-blocks-flex .home--section-block .home--section-block-copy p {
    font-size: 8cqw;
  }
}

.our--brands {
  background-color: white;
  margin-bottom: 60px;
}
.our--brands .our--brands-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
@media (max-width: 63.99em) {
  .our--brands .our--brands-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 34.365em) {
  .our--brands .our--brands-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
.our--brands .our--brands-grid .our--brands-brand {
  display: inline-block;
  position: relative;
  background-color: white;
  border: 1px solid #EBEBEB;
}
.our--brands .our--brands-grid .our--brands-brand:hover .our--brands-brand-logo {
  opacity: 0;
}
.our--brands .our--brands-grid .our--brands-brand:hover .our--brands-brand-img img, .our--brands .our--brands-grid .our--brands-brand:hover .our--brands-brand-img picture {
  transform: scale(1.2);
  transition-duration: 3.5s;
  transition-timing-function: ease-out;
}
.our--brands .our--brands-grid .our--brands-brand .our--brands-brand-logo {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 2;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
.our--brands .our--brands-grid .our--brands-brand .our--brands-brand-logo picture {
  display: block;
  width: 70%;
  height: auto;
  position: absolute;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  text-align: center;
}
.our--brands .our--brands-grid .our--brands-brand .our--brands-brand-img {
  display: block;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.our--brands .our--brands-grid .our--brands-brand .our--brands-brand-img img, .our--brands .our--brands-grid .our--brands-brand .our--brands-brand-img picture {
  display: block;
  width: 100%;
  height: 100%;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  object-fit: cover;
}
.our--brands .our--brands-grid .our--brands-brand.-intro {
  padding: 40px 40px 40px 40px;
}
@media (max-width: 76.615em) {
  .our--brands .our--brands-grid .our--brands-brand.-intro {
    grid-column: 1/span 2;
  }
}
@media (max-width: 63.99em) {
  .our--brands .our--brands-grid .our--brands-brand.-intro {
    grid-column: 1/span 3;
    padding: 20px;
  }
}
.our--brands .our--brands-grid .our--brands-brand.-intro h2 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 34px;
  color: #0E4A8C;
  font-weight: 600;
  margin: 0 0 20px 0;
}
.our--brands .our--brands-grid .our--brands-brand.-intro h4 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: #B4A74A;
  font-weight: 400;
  margin: 0 0 20px 0;
}
.our--brands .our--brands-grid .our--brands-brand.-intro p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: #0E4A8C;
  font-weight: 400;
  padding: 0;
}

.home--regions {
  background: rgb(14, 74, 140);
  background: -moz-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: -webkit-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 100px 0 0 0;
  margin-bottom: 100px;
}
@media (max-width: 73.74em) {
  .home--regions {
    padding-top: 50px;
  }
}
@media (max-width: 47.99em) {
  .home--regions {
    padding-top: 30px;
    margin-bottom: 30px;
  }
}
.home--regions h2 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 80px;
  color: white;
  font-weight: 300;
  margin: 0 0 70px 0;
  line-height: 1;
}
@media screen and (max-width: 1580px) {
  .home--regions h2 {
    font-size: 4.5cqw;
  }
}
@media (max-width: 73.74em) {
  .home--regions h2 {
    margin-bottom: 30px;
  }
}
@media (max-width: 47.99em) {
  .home--regions h2 {
    font-size: 44px;
  }
}
@media (max-width: 34.365em) {
  .home--regions h2 {
    font-size: 9.5cqw;
  }
}
.home--regions .home--regions-flex {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
}
@media (max-width: 47.99em) {
  .home--regions .home--regions-flex {
    flex-wrap: wrap;
  }
}
.home--regions .home--regions-flex .home--regions-content {
  display: inline-block;
  width: 46%;
}
@media (max-width: 73.74em) {
  .home--regions .home--regions-flex .home--regions-content {
    width: 50%;
  }
}
.home--regions .home--regions-flex .home--regions-content .home--region-bg {
  display: none;
}
@media (max-width: 47.99em) {
  .home--regions .home--regions-flex .home--regions-content .home--region-bg {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
  }
  .home--regions .home--regions-flex .home--regions-content .home--region-bg.active {
    display: block;
  }
}
.home--regions .home--regions-flex .home--regions-content .home--regions-region {
  display: none;
  flex-direction: column;
  height: 100%;
}
@media (max-width: 47.99em) {
  .home--regions .home--regions-flex .home--regions-content .home--regions-region {
    display: none;
    position: fixed;
    background-color: #0E4A8C;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 90%;
    height: 95%;
    z-index: 100;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px 20px 0 20px;
    border-top-right-radius: 2.2vw;
    border-top-left-radius: 2.2vw;
    border-bottom-right-radius: 2.2vw;
    border-bottom-left-radius: 2.2vw;
  }
}
.home--regions .home--regions-flex .home--regions-content .home--regions-region.active {
  display: block;
}
.home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  height: 100%;
}
@media (max-width: 47.99em) {
  .home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container {
    margin: 0 auto;
    min-height: 100%;
    height: auto;
    overflow: auto;
  }
}
.home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container h3 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 34px;
  color: white;
  font-weight: 600;
  margin: 0 0 5px 0;
  position: relative;
  padding: 0;
}
@media (max-width: 47.99em) {
  .home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container h3 {
    padding-left: 50px;
  }
}
.home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container h3 span {
  position: absolute;
  left: 0;
  height: 100%;
  display: none;
}
@media (max-width: 47.99em) {
  .home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container h3 span {
    display: none;
  }
}
.home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container h3 span img {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container h3 .map-close {
  display: none;
}
@media (max-width: 47.99em) {
  .home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container h3 .map-close {
    display: inline-block;
    transform: rotate(45deg);
  }
}
.home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container h5 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: #B4A74A;
  font-weight: 400;
  margin: 0 0 20px 0;
}
.home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container .home--regions-region-flex {
  display: flex;
  display: -webkit-flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;
}
@media screen and (max-width: 1000px) {
  .home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container .home--regions-region-flex {
    margin-bottom: 30px;
  }
}
@media (max-width: 47.99em) {
  .home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container .home--regions-region-flex {
    flex-wrap: wrap;
    display: block;
  }
}
.home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container .home--regions-region-flex .home--regions-region-copy {
  display: inline-block;
  width: 60%;
}
@media screen and (max-width: 1000px) {
  .home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container .home--regions-region-flex .home--regions-region-copy {
    width: 100%;
  }
}
.home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container .home--regions-region-flex .home--regions-region-copy p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 400;
}
.home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container .home--regions-region-flex .home--regions-region-copy p:last-child, .home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container .home--regions-region-flex .home--regions-region-copy p:only-child {
  padding-bottom: 0;
}
.home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container .home--regions-region-flex .home--regions-region-links {
  display: inline-block;
}
@media screen and (max-width: 1000px) {
  .home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container .home--regions-region-flex .home--regions-region-links {
    display: none;
  }
}
@media (max-width: 47.99em) {
  .home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container .home--regions-region-flex .home--regions-region-links {
    display: block;
    width: 100%;
    margin-top: 30px;
  }
}
.home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container .home--regions-region-flex .home--regions-region-links p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 400;
}
.home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container .home--regions-region-flex .home--regions-region-links ul li {
  display: block;
  padding-bottom: 10px;
}
.home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container .home--regions-region-flex .home--regions-region-links ul li:last-child {
  padding-bottom: 0;
}
.home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container .home--regions-region-flex .home--regions-region-links ul li a {
  display: block;
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: #80C0E7;
  font-weight: 500;
  text-decoration: underline;
}
.home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container .home--regions-region-video {
  display: block;
  width: 100%;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  position: relative;
  overflow: hidden;
  margin-top: auto;
  align-self: flex-end;
}
.home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container .home--regions-region-video img, .home--regions .home--regions-flex .home--regions-content .home--regions-region .scroll-container .home--regions-region-video video {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}
.home--regions .home--regions-flex .home--regions-map {
  display: inline-block;
  padding-bottom: 80px;
}
@media (max-width: 47.99em) {
  .home--regions .home--regions-flex .home--regions-map {
    width: 100%;
    padding-bottom: 20px;
  }
}

.cheese--banner {
  background: rgb(14, 74, 140);
  background: -moz-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: -webkit-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 200px;
  padding-bottom: 60px;
}
@media screen and (max-width: 920px) {
  .cheese--banner {
    padding-top: 120px;
  }
}
@media (max-width: 47.99em) {
  .cheese--banner {
    padding-bottom: 30px;
  }
}
.cheese--banner .cheese--banner-flex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 920px) {
  .cheese--banner .cheese--banner-flex {
    flex-wrap: wrap;
  }
}
.cheese--banner .cheese--banner-flex .cheese--banner-copy {
  display: inline-block;
  width: 45%;
  padding-left: 40px;
}
@media screen and (max-width: 920px) {
  .cheese--banner .cheese--banner-flex .cheese--banner-copy {
    width: 100%;
    padding: 0 20px;
    order: 3;
  }
}
.cheese--banner .cheese--banner-flex .cheese--banner-copy.-mob {
  display: none;
  order: 1;
}
@media screen and (max-width: 920px) {
  .cheese--banner .cheese--banner-flex .cheese--banner-copy.-mob {
    display: block;
    width: 50%;
  }
  .cheese--banner .cheese--banner-flex .cheese--banner-copy.-mob h1, .cheese--banner .cheese--banner-flex .cheese--banner-copy.-mob h2 {
    display: block;
  }
}
@media (max-width: 34.365em) {
  .cheese--banner .cheese--banner-flex .cheese--banner-copy.-mob {
    width: 100%;
  }
}
.cheese--banner .cheese--banner-flex .cheese--banner-copy.-mob p {
  display: none;
}
.cheese--banner .cheese--banner-flex .cheese--banner-copy h1 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 78px;
  color: white;
  font-weight: 300;
  margin: 0 0 20px 0;
  line-height: 1.2;
}
@media (max-width: 73.74em) {
  .cheese--banner .cheese--banner-flex .cheese--banner-copy h1 {
    font-size: 7vw;
  }
}
@media screen and (max-width: 920px) {
  .cheese--banner .cheese--banner-flex .cheese--banner-copy h1 {
    display: none;
  }
}
@media (max-width: 47.99em) {
  .cheese--banner .cheese--banner-flex .cheese--banner-copy h1 {
    font-size: 9vw;
  }
}
.cheese--banner .cheese--banner-flex .cheese--banner-copy h1 span {
  font-size: 40px;
}
@media (max-width: 47.99em) {
  .cheese--banner .cheese--banner-flex .cheese--banner-copy h1 span {
    font-size: 5cqw;
  }
}
.cheese--banner .cheese--banner-flex .cheese--banner-copy h2 {
  font-family: "relation-one", Arial, sans-serif;
  font-size: 58px;
  color: #B4A74A;
  margin: 0 0 20px 0;
  line-height: 1.2;
}
@media (max-width: 73.74em) {
  .cheese--banner .cheese--banner-flex .cheese--banner-copy h2 {
    font-size: 5.5vw;
  }
}
@media screen and (max-width: 920px) {
  .cheese--banner .cheese--banner-flex .cheese--banner-copy h2 {
    display: none;
  }
}
@media (max-width: 47.99em) {
  .cheese--banner .cheese--banner-flex .cheese--banner-copy h2 {
    font-size: 6.5vw;
  }
}
.cheese--banner .cheese--banner-flex .cheese--banner-copy p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 400;
}
@media (max-width: 47.99em) {
  .cheese--banner .cheese--banner-flex .cheese--banner-copy p {
    font-size: 16px;
  }
}
.cheese--banner .cheese--banner-flex .cheese--banner-copy p a {
  color: #B4A74A;
  text-decoration: underline;
  font-weight: 600;
}
.cheese--banner .cheese--banner-flex .cheese--banner-img {
  display: inline-block;
  width: 45%;
  overflow: hidden;
  position: relative;
  border-top-left-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  background-color: #0E4A8C;
}
@media screen and (max-width: 920px) {
  .cheese--banner .cheese--banner-flex .cheese--banner-img {
    width: 50%;
    order: 2;
    margin-bottom: 30px;
  }
}
@media (max-width: 34.365em) {
  .cheese--banner .cheese--banner-flex .cheese--banner-img {
    width: 100%;
    border-top-right-radius: 2.2vw;
    border-bottom-right-radius: 2.2vw;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-right: 20px;
  }
}
.cheese--banner .cheese--banner-flex .cheese--banner-img img, .cheese--banner .cheese--banner-flex .cheese--banner-img picture {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.cheese--banner .cheese--banner-flex .cheese--banner-img .butter--stamp {
  display: block;
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 22%;
}
.cheese--banner .cheese--banner-flex .cheese--banner-img .butter--stamp img, .cheese--banner .cheese--banner-flex .cheese--banner-img .butter--stamp picture {
  display: block;
  width: 100%;
  height: auto;
  object-fit: fill;
  aspect-ratio: 1;
}
.cheese--banner .cheese--banner-usp {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  padding: 0 40px;
  margin: 100px auto 0 auto;
}
@media screen and (max-width: 920px) {
  .cheese--banner .cheese--banner-usp {
    padding: 0 20px;
  }
}
@media (max-width: 47.99em) {
  .cheese--banner .cheese--banner-usp {
    flex-wrap: wrap;
    margin-top: 30px;
  }
}
.cheese--banner .cheese--banner-usp .cheese--usp {
  display: inline-block;
  width: 25%;
  margin: 0 40px;
  max-width: 300px;
}
@media (max-width: 73.74em) {
  .cheese--banner .cheese--banner-usp .cheese--usp {
    max-width: 100%;
    margin: 0 15px;
  }
}
@media (max-width: 47.99em) {
  .cheese--banner .cheese--banner-usp .cheese--usp {
    width: calc(50% - 10px);
    margin-left: 0;
    margin-right: 0;
  }
  .cheese--banner .cheese--banner-usp .cheese--usp:nth-child(1), .cheese--banner .cheese--banner-usp .cheese--usp:nth-child(2) {
    margin-bottom: 20px;
  }
  .cheese--banner .cheese--banner-usp .cheese--usp:nth-child(1), .cheese--banner .cheese--banner-usp .cheese--usp:nth-child(3) {
    margin-right: 20px;
  }
}
@media (max-width: 34.365em) {
  .cheese--banner .cheese--banner-usp .cheese--usp {
    display: inline-flex;
    display: -webkit-inline-flex;
    align-items: center;
  }
}
.cheese--banner .cheese--banner-usp .cheese--usp:first-child {
  margin-left: 0;
}
.cheese--banner .cheese--banner-usp .cheese--usp:last-child {
  margin-right: 0;
}
.cheese--banner .cheese--banner-usp .cheese--usp .cheese--usp-icon {
  display: block;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  height: 110px;
}
@media (max-width: 34.365em) {
  .cheese--banner .cheese--banner-usp .cheese--usp .cheese--usp-icon {
    display: inline-block;
    width: 70px;
    height: auto;
    max-height: 70px;
  }
}
.cheese--banner .cheese--banner-usp .cheese--usp .cheese--usp-icon img {
  display: block;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 34.365em) {
  .cheese--banner .cheese--banner-usp .cheese--usp .cheese--usp-icon img {
    width: auto;
    height: auto;
    transform: translateY(0);
    top: auto;
    max-height: 70px;
  }
}
.cheese--banner .cheese--banner-usp .cheese--usp .cheese--usp-copy {
  display: block;
  width: 100%;
  text-align: center;
}
@media (max-width: 34.365em) {
  .cheese--banner .cheese--banner-usp .cheese--usp .cheese--usp-copy {
    padding-left: 8px;
  }
}
.cheese--banner .cheese--banner-usp .cheese--usp .cheese--usp-copy h2, .cheese--banner .cheese--banner-usp .cheese--usp .cheese--usp-copy h3 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: #B4A74A;
  font-weight: 400;
  margin: 20px 0 0 0;
  text-align: center;
  text-transform: uppercase;
}
@media (max-width: 34.365em) {
  .cheese--banner .cheese--banner-usp .cheese--usp .cheese--usp-copy h2, .cheese--banner .cheese--banner-usp .cheese--usp .cheese--usp-copy h3 {
    margin-top: 0;
    font-size: 16px;
  }
}

.cheese--content {
  background-color: white;
  padding: 80px 0;
}
@media (max-width: 47.99em) {
  .cheese--content {
    padding: 40px 0;
  }
}
.cheese--content .cheese--content-flex {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
}
@media (max-width: 63.99em) {
  .cheese--content .cheese--content-flex {
    flex-wrap: wrap;
  }
}
@media (max-width: 63.99em) {
  .cheese--content .cheese--content-flex.no-notes .cheese--content-img {
    display: none;
  }
  .cheese--content .cheese--content-flex.no-notes .cheese--content-copy {
    width: 100%;
    padding: 20px 0 0 0;
  }
}
.cheese--content .cheese--content-flex .cheese--content-region {
  display: inline-block;
  width: 33.3333333333%;
  margin-right: 20px;
  border-top-left-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  border-top-right-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  position: relative;
  overflow: hidden;
  text-decoration: none;
}
@media (max-width: 63.99em) {
  .cheese--content .cheese--content-flex .cheese--content-region {
    width: 100%;
    margin: 0 0 20px 0;
    height: 35vw;
  }
}
.cheese--content .cheese--content-flex .cheese--content-region img, .cheese--content .cheese--content-flex .cheese--content-region picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cheese--content .cheese--content-flex .cheese--content-region .cheese--content-region-name {
  display: block;
  position: absolute;
  text-decoration: none;
  bottom: 30px;
  left: 0;
  right: 0;
  font-family: "Jost", Arial, sans-serif;
  font-size: 28px;
  color: white;
  font-weight: 500;
  width: calc(100% - 80px);
  margin: 0 auto;
  padding: 0 50px 0 0;
}
@media (max-width: 47.99em) {
  .cheese--content .cheese--content-flex .cheese--content-region .cheese--content-region-name {
    width: calc(100% - 40px);
    bottom: 20px;
  }
}
@media (max-width: 34.365em) {
  .cheese--content .cheese--content-flex .cheese--content-region .cheese--content-region-name {
    font-size: 5.5vw;
  }
}
.cheese--content .cheese--content-flex .cheese--content-region .cheese--content-region-name i {
  display: inline-block;
  position: absolute;
  right: 0;
  height: 100%;
}
.cheese--content .cheese--content-flex .cheese--content-region .cheese--content-region-name i img {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.cheese--content .cheese--content-flex .cheese--content-img {
  display: inline-block;
  width: 33.3333333333%;
  border-top-left-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  border-top-right-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  position: relative;
  overflow: hidden;
  margin-right: 20px;
}
@media (max-width: 63.99em) {
  .cheese--content .cheese--content-flex .cheese--content-img {
    width: 100%;
    margin: 0 0 20px 0;
    height: 30vw;
  }
}
.cheese--content .cheese--content-flex .cheese--content-img img, .cheese--content .cheese--content-flex .cheese--content-img picture {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.cheese--content .cheese--content-flex .cheese--content-tasting {
  display: inline-block;
  width: 33.3333333333%;
  background-color: #F2F2F2;
  border-top-left-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  border-top-right-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  padding: 40px 40px;
  margin-right: 20px;
}
@media (max-width: 63.99em) {
  .cheese--content .cheese--content-flex .cheese--content-tasting {
    width: calc(50% - 20px);
  }
}
@media screen and (max-width: 650px) {
  .cheese--content .cheese--content-flex .cheese--content-tasting {
    width: 100%;
    margin-right: 0;
    padding: 20px;
  }
}
.cheese--content .cheese--content-flex .cheese--content-tasting .tasting-note {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #D1D1D1;
  padding: 30px 0;
}
.cheese--content .cheese--content-flex .cheese--content-tasting .tasting-note:first-child {
  padding-top: 0;
}
.cheese--content .cheese--content-flex .cheese--content-tasting .tasting-note:last-child {
  border: none;
  padding-bottom: 0;
}
.cheese--content .cheese--content-flex .cheese--content-tasting .tasting-note h3 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 34px;
  color: #0E4A8C;
  font-weight: 600;
  margin: 0 0 20px 0;
}
.cheese--content .cheese--content-flex .cheese--content-tasting .tasting-note p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: #0E4A8C;
  font-weight: 400;
}
.cheese--content .cheese--content-flex .cheese--content-copy {
  display: inline-block;
  width: 33.3333333333%;
  padding: 40px 0 40px 20px;
}
@media (max-width: 63.99em) {
  .cheese--content .cheese--content-flex .cheese--content-copy {
    width: 50%;
  }
}
@media screen and (max-width: 650px) {
  .cheese--content .cheese--content-flex .cheese--content-copy {
    width: 100%;
    padding: 20px 0 0 0;
  }
}
.cheese--content .cheese--content-flex .cheese--content-copy h4 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 34px;
  color: #0E4A8C;
  font-weight: 700;
  margin: 0 0 20px 0;
}
.cheese--content .cheese--content-flex .cheese--content-copy p, .cheese--content .cheese--content-flex .cheese--content-copy li {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: #0E4A8C;
  font-weight: 400;
}
.cheese--content .cheese--content-flex .cheese--content-copy a {
  color: #B4A74A;
  text-decoration: underline;
}
.cheese--content .cheese--content-flex .cheese--content-copy ul {
  list-style: disc;
  padding-left: 20px;
  padding-bottom: 10px;
}
.cheese--content .cheese--content-flex .cheese--content-copy ol {
  padding-left: 20px;
}

.cheese--content.butter .cheese--content-flex {
  flex-wrap: nowrap;
}
@media (max-width: 47.99em) {
  .cheese--content.butter .cheese--content-flex {
    flex-wrap: wrap;
  }
}
.cheese--content.butter .cheese--content-flex .cheese--content-img {
  width: 75%;
  margin-right: 40px;
}
@media (max-width: 63.99em) {
  .cheese--content.butter .cheese--content-flex .cheese--content-img {
    width: 50%;
    height: auto;
    margin-right: 0;
  }
}
@media (max-width: 47.99em) {
  .cheese--content.butter .cheese--content-flex .cheese--content-img {
    width: 100%;
    height: 30vw;
  }
}
@media (max-width: 63.99em) {
  .cheese--content.butter .cheese--content-flex .cheese--content-copy {
    width: 50%;
    padding-left: 40px;
  }
}
@media (max-width: 47.99em) {
  .cheese--content.butter .cheese--content-flex .cheese--content-copy {
    width: 100%;
    padding: 20px 0 0 0;
  }
}

.cheese--related {
  background-color: #F2F2F2;
  padding: 60px 0;
}
@media (max-width: 47.99em) {
  .cheese--related {
    padding: 40px 0 40px 0;
  }
}
.cheese--related .cheese--related-flex {
  margin-bottom: 60px;
}
@media (max-width: 73.74em) {
  .cheese--related .cheese--related-flex {
    width: calc(100% - 20px);
    margin: 0 auto 60px auto;
  }
}
@media (max-width: 47.99em) {
  .cheese--related .cheese--related-flex {
    width: calc(100% - 60px);
    margin-bottom: 40px;
  }
}
.cheese--related .cheese--related-flex .cheese--related-item {
  display: inline-flex;
  display: -webkit-inline-flex;
  justify-content: space-between;
  flex-direction: column;
  width: 25%;
  border-radius: 2.2vw;
  background-color: white;
  margin-right: 30px;
  text-decoration: none;
  container-type: inline-size;
  container-name: cheesecard;
  height: auto;
}
@media (max-width: 63.99em) {
  .cheese--related .cheese--related-flex .cheese--related-item {
    width: calc(50% - 10px);
  }
  .cheese--related .cheese--related-flex .cheese--related-item:nth-child(odd) {
    margin-right: 20px;
  }
  .cheese--related .cheese--related-flex .cheese--related-item:nth-child(even) {
    margin-right: 0;
  }
}
.cheese--related .cheese--related-flex .cheese--related-item:last-child {
  margin-right: 0;
}
.cheese--related .cheese--related-flex .cheese--related-item.-recipe {
  background-color: #0E4A8C;
}
.cheese--related .cheese--related-flex .cheese--related-item.-recipe .cheese--related-item-copy h5, .cheese--related .cheese--related-flex .cheese--related-item.-recipe .cheese--related-item-copy p {
  color: white;
}
.cheese--related .cheese--related-flex .cheese--related-item .cheese--related-item-copy {
  padding: 30px 30px;
}
@media (max-width: 63.99em) {
  .cheese--related .cheese--related-flex .cheese--related-item .cheese--related-item-copy {
    padding: 20px;
  }
}
.cheese--related .cheese--related-flex .cheese--related-item .cheese--related-item-copy p.item-title {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: #B4A74A;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
@media (max-width: 63.99em) {
  .cheese--related .cheese--related-flex .cheese--related-item .cheese--related-item-copy p.item-title {
    font-size: 5cqw;
  }
}
.cheese--related .cheese--related-flex .cheese--related-item .cheese--related-item-copy h5 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 30px;
  color: #0E4A8C;
  font-weight: 600;
  margin: 8px 0 8px 0;
  position: relative;
  padding-right: 45px;
  text-decoration: none;
}
@media (max-width: 100.74em) {
  .cheese--related .cheese--related-flex .cheese--related-item .cheese--related-item-copy h5 {
    font-size: 7.5cqw;
    padding-right: 30px;
  }
}
@media (max-width: 73.74em) {
  .cheese--related .cheese--related-flex .cheese--related-item .cheese--related-item-copy h5 {
    font-size: 8cqw;
  }
}
.cheese--related .cheese--related-flex .cheese--related-item .cheese--related-item-copy h5 i {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
.cheese--related .cheese--related-flex .cheese--related-item .cheese--related-item-copy h5 i img {
  display: block;
  width: 25px;
  height: 25px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.cheese--related .cheese--related-flex .cheese--related-item .cheese--related-item-copy p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: #0E4A8C;
  font-weight: 400;
  text-decoration: none;
}
@media (max-width: 63.99em) {
  .cheese--related .cheese--related-flex .cheese--related-item .cheese--related-item-copy p {
    font-size: 5cqw;
  }
}
.cheese--related .cheese--related-flex .cheese--related-item .cheese--related-item-img {
  width: 100%;
  height: 85cqw;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  position: relative;
  overflow: hidden;
}
.cheese--related .cheese--related-flex .cheese--related-item .cheese--related-item-img img, .cheese--related .cheese--related-flex .cheese--related-item .cheese--related-item-img picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cheese--related .cheese--related-flex .cheese--related-item .cheese--related-item-img .butter--stamp {
  display: block;
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 22%;
}
.cheese--related .cheese--related-flex .cheese--related-item .cheese--related-item-img .butter--stamp img, .cheese--related .cheese--related-flex .cheese--related-item .cheese--related-item-img .butter--stamp picture {
  display: block;
  width: 100%;
  height: auto;
  object-fit: fill;
  aspect-ratio: 1;
}
.cheese--related .cheese--related-flex .cheese-related-swiper-2 .cheese--related-item {
  width: 50%;
}
.cheese--related .cheese--related-flex .cheese-related-swiper-2 .cheese--related-item .cheese--related-item-img {
  height: 50cqw;
}
@media (max-width: 100.74em) {
  .cheese--related .cheese--related-flex .cheese-related-swiper-2 .cheese--related-item .cheese--related-item-copy h5 {
    font-size: 30px;
  }
  .cheese--related .cheese--related-flex .cheese-related-swiper-2 .cheese--related-item .cheese--related-item-copy p {
    font-size: 16px;
  }
}
.cheese--related .cheese--related-flex .no-swiper {
  display: flex;
  display: -webkit-flex;
  width: 100%;
}
@media screen and (max-width: 600px) {
  .cheese--related .cheese--related-flex .no-swiper .cheese--related-item {
    width: 100%;
    margin-right: 0;
  }
}
.cheese--related .cheese--viewall {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.cheese--related .cheese--viewall a {
  font-family: "Jost", Arial, sans-serif;
  font-size: 28px;
  color: #0E4A8C;
  font-weight: 600;
  position: relative;
  padding-left: 50px;
  text-decoration: none;
}
@media (max-width: 47.99em) {
  .cheese--related .cheese--viewall a {
    font-size: 6vw;
    padding-left: 40px;
  }
}
.cheese--related .cheese--viewall a i {
  display: inline-block;
  position: absolute;
  left: 0;
  height: 100%;
}
.cheese--related .cheese--viewall a i img {
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 47.99em) {
  .cheese--related .cheese--viewall a i img {
    width: 25px;
    height: 25px;
  }
}

.cheese--filter {
  background: rgb(14, 74, 140);
  background: -moz-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: -webkit-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 180px;
  padding-bottom: 60px;
}
@media (max-width: 47.99em) {
  .cheese--filter {
    padding-top: 120px;
  }
}
.cheese--filter .cheese--filter-banner-copy {
  display: block;
  text-align: right;
  width: 100%;
  margin: 0;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: flex-end;
}
@media (max-width: 47.99em) {
  .cheese--filter .cheese--filter-banner-copy {
    align-items: flex-start;
    text-align: left;
  }
}
.cheese--filter .cheese--filter-banner-copy h1 {
  font-family: "relation-one", Arial, sans-serif;
  font-size: 84px;
  color: #B4A74A;
  margin: 0;
  line-height: 1;
}
@media (max-width: 63.99em) {
  .cheese--filter .cheese--filter-banner-copy h1 {
    font-size: 62px;
  }
}
@media (max-width: 47.99em) {
  .cheese--filter .cheese--filter-banner-copy h1 {
    text-align: left;
  }
}
@media (max-width: 34.365em) {
  .cheese--filter .cheese--filter-banner-copy h1 {
    font-size: 48px;
  }
}
.cheese--filter .cheese--filter-banner-copy p {
  display: block;
  width: 85%;
  text-align: right;
  font-family: "Jost", Arial, sans-serif;
  font-size: 34px;
  color: white;
  font-weight: 300;
  padding-bottom: 0;
}
@media (max-width: 63.99em) {
  .cheese--filter .cheese--filter-banner-copy p {
    width: 100%;
    max-width: 100%;
    font-size: 32px;
  }
}
@media (max-width: 47.99em) {
  .cheese--filter .cheese--filter-banner-copy p {
    text-align: left;
  }
}
@media (max-width: 34.365em) {
  .cheese--filter .cheese--filter-banner-copy p {
    font-size: 24px;
  }
}
.cheese--filter .cheese--filter-banner-copy p sup {
  font-size: 18px;
  top: 0;
}
.cheese--filter .cheese--filter-header {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  margin: 60px 0 40px 0;
}
@media (max-width: 47.99em) {
  .cheese--filter .cheese--filter-header {
    margin-top: 35px;
  }
}
.cheese--filter .cheese--filter-header .cheese--filter-header-left {
  display: inline-flex;
  display: -webkit-inline-flex;
  width: 100%;
}
.cheese--filter .cheese--filter-header .cheese--filter-header-left .open--filter {
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  background-color: white;
  padding: 15px 20px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  border: 1px solid white;
  justify-content: center;
}
@media (max-width: 34.365em) {
  .cheese--filter .cheese--filter-header .cheese--filter-header-left .open--filter {
    width: 49%;
    padding: 15px 15px;
  }
}
.cheese--filter .cheese--filter-header .cheese--filter-header-left .open--filter:hover {
  background-color: #F2F2F2;
}
.cheese--filter .cheese--filter-header .cheese--filter-header-left .open--filter.active {
  background-color: #0E4A8C;
  color: white;
}
.cheese--filter .cheese--filter-header .cheese--filter-header-left .open--filter.active span {
  color: white;
}
.cheese--filter .cheese--filter-header .cheese--filter-header-left .open--filter.active i {
  display: none;
}
.cheese--filter .cheese--filter-header .cheese--filter-header-left .open--filter.active i.close {
  display: inline-block;
}
.cheese--filter .cheese--filter-header .cheese--filter-header-left .open--filter span {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: #0E4A8C;
  font-weight: 500;
}
.cheese--filter .cheese--filter-header .cheese--filter-header-left .open--filter i {
  display: inline-block;
  height: 100%;
  margin-right: 12px;
}
@media (max-width: 23.4275em) {
  .cheese--filter .cheese--filter-header .cheese--filter-header-left .open--filter i {
    max-width: 20px;
    margin-right: 10px;
  }
}
.cheese--filter .cheese--filter-header .cheese--filter-header-left .open--filter i.close {
  display: none;
}
.cheese--filter .cheese--filter-header .cheese--filter-header-left .open--filter i img, .cheese--filter .cheese--filter-header .cheese--filter-header-left .open--filter i picture {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.cheese--filter .cheese--filter-header .cheese--filter-header-left .filter-spacer {
  display: inline-block;
  width: 1px;
  background-color: white;
  margin: 0 15px;
}
.cheese--filter .cheese--filter-header .cheese--filter-header-left .cheese--viewall {
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  padding: 15px 35px;
  background-color: #B4A74A;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  text-decoration: none;
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 500;
  justify-content: center;
}
@media (max-width: 34.365em) {
  .cheese--filter .cheese--filter-header .cheese--filter-header-left .cheese--viewall {
    width: 49%;
    padding: 15px 15px;
  }
}
.cheese--filter .cheese--filter-header .cheese--filter-header-left .cheese--viewall:hover {
  background-color: #958A40;
}
.cheese--filter .cheese--filter-header .cheese--filter-header-right {
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
}
.cheese--filter .cheese--filter-header .cheese--filter-header-right p {
  display: inline-block;
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 500;
  text-align: right;
  padding: 0;
  margin: 0 20px 0 0;
}
.cheese--filter .cheese--filter-header .cheese--filter-header-right .sort--type {
  display: inline-block;
  background-color: white;
  appearance: none;
  -webkit-appearance: none;
  width: 170px;
  border: none;
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: #0E4A8C;
  font-weight: 500;
  text-align: left;
  padding: 15px 15px;
  border-radius: 5px;
  background-image: url("../static/icon-blue-arrow.svg");
  background-repeat: no-repeat;
  background-position: center right 12px;
  background-size: 13px 6px;
  box-shadow: none;
  cursor: pointer;
}
.cheese--filter .cheese--list {
  display: flex;
  display: -webkit-flex;
  align-items: flex-start;
}
@media (max-width: 63.99em) {
  .cheese--filter .cheese--list.active .cheese--list-main {
    grid-template-columns: repeat(2, 1fr);
  }
}
.cheese--filter .cheese--list.active .cheese--list-main .cheese--list-item .cheese--list-item-img {
  height: 17.6cqw;
}
@media (max-width: 63.99em) {
  .cheese--filter .cheese--list.active .cheese--list-main .cheese--list-item .cheese--list-item-img {
    height: 28.6cqw;
  }
}
@media (max-width: 47.99em) {
  .cheese--filter .cheese--list.active .cheese--list-main .cheese--list-item .cheese--list-item-img {
    height: 45cqw !important;
  }
}
.cheese--filter .cheese--list .cheese--list-filter {
  display: none;
  width: 20%;
  min-width: 20%;
  padding-right: 30px;
  opacity: 0;
  min-width: 280px;
}
@media (max-width: 47.99em) {
  .cheese--filter .cheese--list .cheese--list-filter {
    position: fixed;
    overflow-x: hidden;
    overflow-y: scroll;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    width: 100%;
    max-width: 100%;
    background-color: #0E4A8C;
    padding: 20px 20px 50px 20px;
  }
}
.cheese--filter .cheese--list .cheese--list-filter.active {
  display: inline-block;
  opacity: 1;
}
.cheese--filter .cheese--list .cheese--list-filter .close--filter {
  display: none;
  align-items: center;
  background-color: #B4A74A;
  padding: 15px 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  justify-content: center;
}
@media (max-width: 47.99em) {
  .cheese--filter .cheese--list .cheese--list-filter .close--filter {
    display: inline-flex;
    display: -webkit-inline-flex;
  }
}
.cheese--filter .cheese--list .cheese--list-filter .close--filter span {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 500;
}
.cheese--filter .cheese--list .cheese--list-filter .close--filter i {
  display: inline-block;
  height: 100%;
  margin-right: 12px;
}
.cheese--filter .cheese--list .cheese--list-filter .close--filter i img {
  display: block;
  width: 100%;
  height: auto;
}
.cheese--filter .cheese--list .cheese--list-filter .filter-form-group {
  display: block;
  margin-bottom: 40px;
}
.cheese--filter .cheese--list .cheese--list-filter .filter-form-group:last-child {
  margin-bottom: 0;
}
.cheese--filter .cheese--list .cheese--list-filter .filter-form-group.open .filter-form-dropdown i svg .vertical {
  display: none;
}
.cheese--filter .cheese--list .cheese--list-filter .filter-form-group.open .filter-form-dropdown-content {
  height: auto;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
.cheese--filter .cheese--list .cheese--list-filter .filter-form-group .filter-form-dropdown {
  display: block;
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 500;
  text-align: left;
  padding: 20px 20px;
  border: 1px solid white;
  border-radius: 5px;
  line-height: 1;
  position: relative;
  cursor: pointer;
}
.cheese--filter .cheese--list .cheese--list-filter .filter-form-group .filter-form-dropdown i {
  display: inline-block;
  position: absolute;
  right: 12px;
  top: 0;
  height: 100%;
}
.cheese--filter .cheese--list .cheese--list-filter .filter-form-group .filter-form-dropdown i svg {
  display: block;
  width: 20px;
  height: 20px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.cheese--filter .cheese--list .cheese--list-filter .filter-form-group .filter-form-dropdown-content {
  height: 0;
  overflow: hidden;
  opacity: 0;
  margin-top: 20px;
  margin-left: 20px;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
.cheese--filter .cheese--list .cheese--list-filter .filter-form-group .filter-form-dropdown-content .filter-form-dropdown-check {
  display: block;
  width: 100%;
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 400;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: 25px;
  padding-bottom: 10px;
}
.cheese--filter .cheese--list .cheese--list-filter .filter-form-group .filter-form-dropdown-content .filter-form-dropdown-check:last-child {
  padding-bottom: 0;
}
.cheese--filter .cheese--list .cheese--list-filter .filter-form-group .filter-form-dropdown-content .filter-form-dropdown-check input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.cheese--filter .cheese--list .cheese--list-filter .filter-form-group .filter-form-dropdown-content .filter-form-dropdown-check .checkmark {
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 0;
}
.cheese--filter .cheese--list .cheese--list-filter .filter-form-group .filter-form-dropdown-content .filter-form-dropdown-check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  width: 5px;
  top: 2px;
  left: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.cheese--filter .cheese--list .cheese--list-filter .filter-form-group .filter-form-dropdown-content .filter-form-dropdown-check input:checked ~ .checkmark {
  background-color: #B4A74A;
}
.cheese--filter .cheese--list .cheese--list-filter .filter-form-group .filter-form-dropdown-content .filter-form-dropdown-check input:checked ~ .checkmark:after {
  display: block;
}
.cheese--filter .cheese--list .cheese--list-main {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}
@media (max-width: 73.74em) {
  .cheese--filter .cheese--list .cheese--list-main {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}
@media (max-width: 47.99em) {
  .cheese--filter .cheese--list .cheese--list-main {
    grid-template-columns: repeat(2, 1fr);
  }
}
.cheese--filter .cheese--list .cheese--list-main .cheese--list-item {
  display: inline-block;
  text-decoration: none;
}
.cheese--filter .cheese--list .cheese--list-main .cheese--list-item .cheese--list-item-img {
  display: block;
  width: 100%;
  container: card/size;
  height: 22.4cqw;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}
@supports not (container-type: inline-size) {
  .cheese--filter .cheese--list .cheese--list-main .cheese--list-item .cheese--list-item-img {
    height: auto;
    max-height: 170px;
  }
}
@media (max-width: 47.99em) {
  .cheese--filter .cheese--list .cheese--list-main .cheese--list-item .cheese--list-item-img {
    height: 45cqw;
  }
}
.cheese--filter .cheese--list .cheese--list-main .cheese--list-item .cheese--list-item-img img, .cheese--filter .cheese--list .cheese--list-main .cheese--list-item .cheese--list-item-img picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cheese--filter .cheese--list .cheese--list-main .cheese--list-item .cheese--list-item-copy {
  display: block;
  width: 100%;
  margin-top: 20px;
}
.cheese--filter .cheese--list .cheese--list-main .cheese--list-item .cheese--list-item-copy h2 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 28px;
  color: white;
  font-weight: 600;
  margin: 0 0 8px 0;
  text-decoration: none;
  position: relative;
  padding-right: 35px;
  font-size: 1.5cqw;
}
@media (max-width: 76.615em) {
  .cheese--filter .cheese--list .cheese--list-main .cheese--list-item .cheese--list-item-copy h2 {
    font-size: 1.7cqw;
  }
}
@media (max-width: 63.99em) {
  .cheese--filter .cheese--list .cheese--list-main .cheese--list-item .cheese--list-item-copy h2 {
    font-size: 2.2cqw;
  }
}
@media (max-width: 47.99em) {
  .cheese--filter .cheese--list .cheese--list-main .cheese--list-item .cheese--list-item-copy h2 {
    font-size: 4.5cqw;
  }
  @supports not (container-type: inline-size) {
    .cheese--filter .cheese--list .cheese--list-main .cheese--list-item .cheese--list-item-copy h2 {
      font-size: 18px;
    }
  }
}
.cheese--filter .cheese--list .cheese--list-main .cheese--list-item .cheese--list-item-copy h2 i {
  display: inline-block;
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
}
.cheese--filter .cheese--list .cheese--list-main .cheese--list-item .cheese--list-item-copy h2 i img {
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 47.99em) {
  .cheese--filter .cheese--list .cheese--list-main .cheese--list-item .cheese--list-item-copy h2 i img {
    width: 25px;
    height: 25px;
  }
}
.cheese--filter .cheese--list .cheese--list-main .cheese--list-item .cheese--list-item-copy p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 400;
  padding: 0;
  text-decoration: none;
}
@media (max-width: 47.99em) {
  .cheese--filter .cheese--list .cheese--list-main .cheese--list-item .cheese--list-item-copy p {
    font-size: 14px;
  }
}

@media (max-width: 47.99em) {
  body.noscroll {
    overflow: hidden;
    position: fixed;
  }
}

.main--products {
  background-color: #F2F2F2;
  padding: 80px 0;
}
@media (max-width: 47.99em) {
  .main--products {
    padding: 40px 0 0 0;
  }
}
.main--products .main--products-flex {
  display: flex;
  display: -webkit-flex;
}
@media (max-width: 47.99em) {
  .main--products .main--products-flex {
    width: calc(100% - 60px);
    margin: 0 auto;
  }
}
.main--products .main--products-flex .main--products-product {
  display: inline-block;
  text-decoration: none;
  margin-right: 20px;
  width: 33.3333333333%;
}
@media (max-width: 47.99em) {
  .main--products .main--products-flex .main--products-product {
    width: calc(50% - 10px);
    margin-bottom: 30px;
  }
  .main--products .main--products-flex .main--products-product:nth-child(2) {
    margin-right: 0;
  }
}
.main--products .main--products-flex .main--products-product:last-child {
  margin-right: 0;
}
.main--products .main--products-flex .main--products-product .main--products-product-img {
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
}
.main--products .main--products-flex .main--products-product .main--products-product-img img, .main--products .main--products-flex .main--products-product .main--products-product-img picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main--products .main--products-flex .main--products-product .main--products-product-copy {
  display: block;
  padding: 25px 35px 0 35px;
}
@media (max-width: 73.74em) {
  .main--products .main--products-flex .main--products-product .main--products-product-copy {
    padding: 20px 20px 0 20px;
  }
}
@media (max-width: 47.99em) {
  .main--products .main--products-flex .main--products-product .main--products-product-copy {
    padding-left: 0;
    padding-right: 0;
  }
}
.main--products .main--products-flex .main--products-product .main--products-product-copy h3 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 34px;
  color: #0E4A8C;
  font-weight: 600;
  margin: 0 0 15px 0;
  text-decoration: none;
  position: relative;
  padding-right: 50px;
}
@media (max-width: 73.74em) {
  .main--products .main--products-flex .main--products-product .main--products-product-copy h3 {
    font-size: 2.5cqw;
    padding-right: 3.5cqw;
  }
}
@media (max-width: 47.99em) {
  .main--products .main--products-flex .main--products-product .main--products-product-copy h3 {
    font-size: 4cqw;
    padding-right: 3.5cqw;
  }
}
@media screen and (max-width: 520px) {
  .main--products .main--products-flex .main--products-product .main--products-product-copy h3 {
    font-size: 5.5cqw;
    padding-right: 5cqw;
  }
}
.main--products .main--products-flex .main--products-product .main--products-product-copy h3 i {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
@media (max-width: 47.99em) {
  .main--products .main--products-flex .main--products-product .main--products-product-copy h3 i {
    width: 3.5cqw;
  }
}
@media screen and (max-width: 520px) {
  .main--products .main--products-flex .main--products-product .main--products-product-copy h3 i {
    width: 5cqw;
  }
}
.main--products .main--products-flex .main--products-product .main--products-product-copy h3 i img {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.main--products .main--products-flex .main--products-product .main--products-product-copy p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: #0E4A8C;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.contact--form {
  background: rgb(14, 74, 140);
  background: -moz-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: -webkit-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 180px;
  padding-bottom: 60px;
}
@media (max-width: 47.99em) {
  .contact--form {
    padding-top: 100px;
  }
}
.contact--form h1 {
  font-family: "relation-one", Arial, sans-serif;
  font-size: 88px;
  color: #B4A74A;
  margin: 0 0 20px 0;
  text-align: right;
}
@media (max-width: 34.365em) {
  .contact--form h1 {
    text-align: left;
    font-size: 18vw;
  }
}
.contact--form .contact--form-flex {
  display: flex;
  display: -webkit-flex;
}
@media (max-width: 63.99em) {
  .contact--form .contact--form-flex {
    flex-wrap: wrap;
  }
}
.contact--form .contact--form-flex .contact--form-form {
  display: inline-block;
  width: 70%;
  background-color: #0E4A8C;
  padding: 40px;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
}
@media (max-width: 63.99em) {
  .contact--form .contact--form-flex .contact--form-form {
    width: 100%;
    order: 2;
  }
}
@media (max-width: 47.99em) {
  .contact--form .contact--form-flex .contact--form-form {
    padding: 25px;
  }
}
.contact--form .contact--form-flex .contact--form-form p {
  display: block;
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 400;
  padding-bottom: 20px;
  max-width: 50%;
  width: 100%;
}
@media (max-width: 63.99em) {
  .contact--form .contact--form-flex .contact--form-form p {
    max-width: 100%;
  }
}
.contact--form .contact--form-flex .contact--form-form p.thanks {
  font-size: 24px;
  font-weight: 600;
}
.contact--form .contact--form-flex .contact--form-form .contact--form-container {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 620px) {
  .contact--form .contact--form-flex .contact--form-form .contact--form-container {
    flex-wrap: wrap;
  }
}
.contact--form .contact--form-flex .contact--form-form .contact--form-container .contact--form-group {
  display: block;
  margin-bottom: 10px;
}
.contact--form .contact--form-flex .contact--form-form .contact--form-container .contact--form-group:last-child {
  margin-bottom: 0;
}
.contact--form .contact--form-flex .contact--form-form .contact--form-container .contact--form-group input, .contact--form .contact--form-flex .contact--form-form .contact--form-container .contact--form-group textarea {
  display: block;
  background-color: #0E4A8C;
  border: 1px solid white;
  border-radius: 5px;
  width: 100%;
  padding: 10px 10px;
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 400;
}
.contact--form .contact--form-flex .contact--form-form .contact--form-container .contact--form-group input::placeholder, .contact--form .contact--form-flex .contact--form-form .contact--form-container .contact--form-group textarea::placeholder {
  color: white;
  opacity: 1;
}
.contact--form .contact--form-flex .contact--form-form .contact--form-container .contact--form-left {
  display: inline-block;
  width: 50%;
  padding-right: 10px;
}
@media screen and (max-width: 620px) {
  .contact--form .contact--form-flex .contact--form-form .contact--form-container .contact--form-left {
    width: 100%;
    padding-right: 0;
  }
}
.contact--form .contact--form-flex .contact--form-form .contact--form-container .contact--form-right {
  display: inline-block;
  width: 50%;
  padding-left: 10px;
}
@media screen and (max-width: 620px) {
  .contact--form .contact--form-flex .contact--form-form .contact--form-container .contact--form-right {
    padding: 0;
    width: 100%;
    margin-top: 10px;
  }
}
.contact--form .contact--form-flex .contact--form-form .contact--form-container .contact--form-right textarea {
  width: 100%;
  height: 100%;
  resize: none;
  display: block;
  background-color: #0E4A8C;
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px 10px;
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 400;
}
.contact--form .contact--form-flex .contact--form-form .contact--form-container .contact--form-right textarea::placeholder {
  color: white;
  opacity: 1;
}
.contact--form .contact--form-flex .contact--form-form .contact--form-submit {
  display: block;
  float: right;
  position: relative;
}
.contact--form .contact--form-flex .contact--form-form .contact--form-submit button[type=submit] {
  display: block;
  font-family: "Jost", Arial, sans-serif;
  font-size: 26px;
  color: white;
  font-weight: 600;
  background-color: #0E4A8C;
  border: none;
  appearance: none;
  background-image: url("../static/icon-submit.svg");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 28px 28px;
  padding-right: 40px;
}
.contact--form .contact--form-flex .contact--form-form .contact--form-submit button[type=submit]:disabled {
  opacity: 0.2;
}
.contact--form .contact--form-flex .contact--form-form .contact--form-submit .loader {
  display: none;
  border: 5px solid white;
  border-top: 5px solid #B4A74A;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  animation: spin 2s linear infinite;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
  top: 3px;
}
.contact--form .contact--form-flex .contact--form-form .form-message {
  float: right;
  color: white;
  margin-top: 5px;
  font-size: 20px;
}
.contact--form .contact--form-flex .contact--form-form #error {
  color: #ff3838;
  margin-right: 15px;
  font-weight: bold;
}
.contact--form .contact--form-flex .contact--form-info {
  display: inline-block;
  width: 30%;
  text-align: right;
  position: relative;
}
@media (max-width: 63.99em) {
  .contact--form .contact--form-flex .contact--form-info {
    width: 100%;
    text-align: left;
    order: 1;
  }
}
.contact--form .contact--form-flex .contact--form-info .contact--form-info-item {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}
.contact--form .contact--form-flex .contact--form-info .contact--form-info-item:last-child {
  margin-bottom: 0;
}
.contact--form .contact--form-flex .contact--form-info .contact--form-info-item.address p i {
  height: auto;
}
.contact--form .contact--form-flex .contact--form-info .contact--form-info-item.address p i img {
  transform: translateY(0);
  top: 0;
}
.contact--form .contact--form-flex .contact--form-info .contact--form-info-item a, .contact--form .contact--form-flex .contact--form-info .contact--form-info-item p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  padding-right: 50px;
}
@media (max-width: 63.99em) {
  .contact--form .contact--form-flex .contact--form-info .contact--form-info-item a, .contact--form .contact--form-flex .contact--form-info .contact--form-info-item p {
    padding-right: 0;
    padding-left: 50px;
  }
}
.contact--form .contact--form-flex .contact--form-info .contact--form-info-item a i, .contact--form .contact--form-flex .contact--form-info .contact--form-info-item p i {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
@media (max-width: 63.99em) {
  .contact--form .contact--form-flex .contact--form-info .contact--form-info-item a i, .contact--form .contact--form-flex .contact--form-info .contact--form-info-item p i {
    right: auto;
    left: 0;
  }
}
.contact--form .contact--form-flex .contact--form-info .contact--form-info-item a i img, .contact--form .contact--form-flex .contact--form-info .contact--form-info-item p i img {
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.contact--form .contact--form-flex .contact--form-info .contact--form-info-social {
  display: block;
  text-align: right;
  margin-top: 80px;
}
@media (max-width: 63.99em) {
  .contact--form .contact--form-flex .contact--form-info .contact--form-info-social {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
  }
}
@media (max-width: 34.365em) {
  .contact--form .contact--form-flex .contact--form-info .contact--form-info-social {
    position: static;
    text-align: left;
    margin-bottom: 30px;
  }
}
.contact--form .contact--form-flex .contact--form-info .contact--form-info-social p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 600;
  padding-bottom: 0;
}
.contact--form .contact--form-flex .contact--form-info .contact--form-info-social .social--icons {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 12px;
}
@media (max-width: 34.365em) {
  .contact--form .contact--form-flex .contact--form-info .contact--form-info-social .social--icons {
    justify-content: flex-start;
  }
}
.contact--form .contact--form-flex .contact--form-info .contact--form-info-social .social--icons a {
  display: inline-block;
  width: 37px;
  margin-right: 10px;
}
.contact--form .contact--form-flex .contact--form-info .contact--form-info-social .social--icons a:last-child {
  margin-right: 0;
}
.contact--form .contact--form-flex .contact--form-info .contact--form-info-social .social--icons a img {
  display: block;
  width: 100%;
  height: auto;
}
.contact--form.thanks h1 {
  text-align: left;
}
.contact--form.thanks .social--links {
  text-align: right;
  margin-top: 15px;
}
.contact--form.thanks .social--links ul {
  display: flex;
  display: -webkit-flex;
}
.contact--form.thanks .social--links ul li {
  display: inline-block;
  text-align: right;
  margin-bottom: 8px;
}
.contact--form.thanks .social--links ul li:first-child {
  margin-right: 15px;
}
.contact--form.thanks .social--links ul li a {
  display: block;
  font-family: "Jost", Arial, sans-serif;
  font-size: 14px;
  color: #0E4A8C;
  font-weight: 400;
  text-decoration: none;
}

.contact--img {
  display: block;
  width: 100%;
}
.contact--img img, .contact--img picture {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.cheese--banner.full {
  background: none;
}
.cheese--banner.full .cheese--banner-flex .cheese--banner-copy h1 {
  font-family: "relation-one", Arial, sans-serif;
  font-size: 90px;
  color: #B4A74A;
  font-weight: normal;
  line-height: 1;
  margin: 0;
}
@media (max-width: 34.365em) {
  .cheese--banner.full .cheese--banner-flex .cheese--banner-copy h1 {
    font-size: 18vw;
  }
}
.cheese--banner.full .cheese--banner-flex .cheese--banner-copy h2 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 38px;
  color: white;
  font-weight: 300;
  margin: 10px 0 30px 0;
}
@media (max-width: 34.365em) {
  .cheese--banner.full .cheese--banner-flex .cheese--banner-copy h2 {
    font-size: 6vw;
  }
}

.butter--list .butter--list-flex {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}
.butter--list .butter--list-flex .butter--item {
  display: inline-block;
  width: calc(50% - 18px);
  text-decoration: none;
  margin-bottom: 80px;
  container-type: inline-size;
  container-name: buttercard;
}
@media (max-width: 47.99em) {
  .butter--list .butter--list-flex .butter--item {
    margin-bottom: 40px;
    width: calc(50% - 10px);
  }
}
.butter--list .butter--list-flex .butter--item:nth-child(odd) {
  margin-right: 35px;
}
@media (max-width: 47.99em) {
  .butter--list .butter--list-flex .butter--item:nth-child(odd) {
    margin-right: 20px;
  }
}
.butter--list .butter--list-flex .butter--item .butter--item-img {
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  border-top-right-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  margin-bottom: 20px;
}
.butter--list .butter--list-flex .butter--item .butter--item-img img, .butter--list .butter--list-flex .butter--item .butter--item-img picture {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 3/2;
  object-fit: cover;
}
.butter--list .butter--list-flex .butter--item .butter--item-img .butter--stamp {
  display: block;
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 22%;
}
.butter--list .butter--list-flex .butter--item .butter--item-img .butter--stamp img, .butter--list .butter--list-flex .butter--item .butter--item-img .butter--stamp picture {
  display: block;
  width: 100%;
  height: auto;
  object-fit: fill;
  aspect-ratio: 1;
}
.butter--list .butter--list-flex .butter--item .butter--item-copy {
  display: block;
  width: 100%;
}
.butter--list .butter--list-flex .butter--item .butter--item-copy h2 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 30px;
  color: white;
  font-weight: 600;
  margin: 0;
  position: relative;
  text-decoration: none;
  padding-right: 40px;
  margin-bottom: 20px;
}
@media (max-width: 47.99em) {
  .butter--list .butter--list-flex .butter--item .butter--item-copy h2 {
    font-size: 9cqw;
    padding-right: 40px;
  }
  @supports not (container-type: inline-size) {
    .butter--list .butter--list-flex .butter--item .butter--item-copy h2 {
      font-size: 24px;
    }
  }
}
@media (max-width: 34.365em) {
  .butter--list .butter--list-flex .butter--item .butter--item-copy h2 {
    font-size: 14cqw;
    padding-right: 20px;
  }
}
.butter--list .butter--list-flex .butter--item .butter--item-copy h2 i {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 8px;
  height: 100%;
}
.butter--list .butter--list-flex .butter--item .butter--item-copy h2 i img {
  display: block;
  width: 30px;
  height: 30px;
}
@media (max-width: 34.365em) {
  .butter--list .butter--list-flex .butter--item .butter--item-copy h2 i img {
    width: 18px;
    height: 18px;
  }
}
.butter--list .butter--list-flex .butter--item .butter--item-copy p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 400;
  text-decoration: none;
}
@media (max-width: 47.99em) {
  .butter--list .butter--list-flex .butter--item .butter--item-copy p {
    font-size: 8cqw;
  }
}

.section--image {
  display: block;
  width: 100%;
}
.section--image img, .section--image picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.region--banner {
  background: rgb(14, 74, 140);
  background: -moz-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: -webkit-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 200px;
  padding-bottom: 23vw;
}
@media (max-width: 63.99em) {
  .region--banner {
    padding-top: 150px;
  }
}
.region--banner .region--banner-flex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 63.99em) {
  .region--banner .region--banner-flex {
    flex-wrap: wrap;
  }
}
.region--banner .region--banner-flex .region--banner-copy {
  display: inline-block;
  width: 40%;
  padding: 0 40px;
}
@media (max-width: 63.99em) {
  .region--banner .region--banner-flex .region--banner-copy {
    width: 100%;
    padding: 0 20px;
    order: 1;
    margin-bottom: 40px;
  }
}
.region--banner .region--banner-flex .region--banner-copy h1 {
  font-family: "relation-one", Arial, sans-serif;
  font-size: 84px;
  color: #B4A74A;
  font-weight: 300;
  margin: 0 0 5px 0;
  line-height: 1;
}
.region--banner .region--banner-flex .region--banner-copy h1 span {
  font-size: 40px;
}
.region--banner .region--banner-flex .region--banner-copy h2 {
  font-family: "relation-one", Arial, sans-serif;
  font-size: 58px;
  color: #B4A74A;
  margin: 0 0 20px 0;
  line-height: 1.2;
}
.region--banner .region--banner-flex .region--banner-copy p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 400;
}
.region--banner .region--banner-flex .region--banner-img {
  display: inline-block;
  width: 60%;
  overflow: hidden;
  position: relative;
  border-top-right-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  background-color: #0E4A8C;
}
@media (max-width: 63.99em) {
  .region--banner .region--banner-flex .region--banner-img {
    width: 90%;
    order: 2;
  }
}
.region--banner .region--banner-flex .region--banner-img img, .region--banner .region--banner-flex .region--banner-img video {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}
.region--banner .region--banner-headline {
  display: block;
  width: 100%;
  max-width: 1200px;
  text-align: center;
  margin: 100px auto 0 auto;
  padding: 0 25px;
}
@media (max-width: 63.99em) {
  .region--banner .region--banner-headline {
    margin-top: 60px;
  }
}
@media (max-width: 47.99em) {
  .region--banner .region--banner-headline {
    margin-bottom: 30px;
  }
}
.region--banner .region--banner-headline p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 36px;
  color: white;
  font-weight: 300;
  padding: 0;
  margin: 0;
  text-align: center;
}
@media (max-width: 34.365em) {
  .region--banner .region--banner-headline p {
    font-size: 7vw;
  }
}

.region--content {
  margin-top: -15vw;
  margin-bottom: 100px;
}
@media (max-width: 47.99em) {
  .region--content {
    margin-bottom: 50px;
  }
}
.region--content .region--content-images {
  display: flex;
  display: -webkit-flex;
}
.region--content .region--content-images .region--content-images-img {
  display: inline-block;
  width: 50%;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  border-top-right-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
}
@media (max-width: 34.365em) {
  .region--content .region--content-images .region--content-images-img {
    width: 100%;
    margin-right: 0 !important;
  }
}
.region--content .region--content-images .region--content-images-img:first-child {
  margin-right: 35px;
}
@media (max-width: 47.99em) {
  .region--content .region--content-images .region--content-images-img:first-child {
    margin-right: 20px;
  }
}
@media (max-width: 34.365em) {
  .region--content .region--content-images .region--content-images-img:nth-child(2) {
    display: none;
  }
}
.region--content .region--content-images .region--content-images-img img, .region--content .region--content-images .region--content-images-img picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.region--content .region--content-related h3 {
  display: block;
  font-family: "Jost", Arial, sans-serif;
  font-size: 46px;
  color: #0E4A8C;
  font-weight: 300;
  margin: 60px auto;
  text-align: center;
}
@media (max-width: 34.365em) {
  .region--content .region--content-related h3 {
    font-size: 10vw;
    margin: 40px 0;
  }
}
.region--content .region--content-related .product--content-related-cont {
  display: flex;
  display: -webkit-flex;
  position: relative;
}
@media (max-width: 73.74em) {
  .region--content .region--content-related .product--content-related-cont {
    width: calc(100% - 20px);
    margin: 0 auto;
  }
}
@media (max-width: 47.99em) {
  .region--content .region--content-related .product--content-related-cont {
    width: calc(100% - 60px);
  }
}
.region--content .region--content-related .product--content-related-cont .cheese--related-item {
  display: inline-flex;
  display: -webkit-inline-flex;
  flex-direction: column;
  width: 25%;
  border-radius: 2.2vw;
  background-color: #F2F2F2;
  margin-right: 30px;
  text-decoration: none;
  container-type: inline-size;
  container-name: relatedcheese;
  height: auto !important;
}
@media (max-width: 100.74em) {
  .region--content .region--content-related .product--content-related-cont .cheese--related-item {
    margin-right: 20px;
  }
}
.region--content .region--content-related .product--content-related-cont .cheese--related-item:last-child {
  margin-right: 0;
}
.region--content .region--content-related .product--content-related-cont .cheese--related-item .cheese--related-item-img {
  width: 100%;
  height: 85cqw;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  overflow: hidden;
}
.region--content .region--content-related .product--content-related-cont .cheese--related-item .cheese--related-item-img img, .region--content .region--content-related .product--content-related-cont .cheese--related-item .cheese--related-item-img picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.region--content .region--content-related .product--content-related-cont .cheese--related-item .cheese--related-item-copy {
  padding: 20px 30px;
}
@media (max-width: 100.74em) {
  .region--content .region--content-related .product--content-related-cont .cheese--related-item .cheese--related-item-copy {
    padding: 20px;
  }
}
.region--content .region--content-related .product--content-related-cont .cheese--related-item .cheese--related-item-copy h5 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 30px;
  color: #0E4A8C;
  font-weight: 600;
  margin: 0 0 8px 0;
  position: relative;
  padding-right: 45px;
  text-decoration: none;
}
@media (max-width: 100.74em) {
  .region--content .region--content-related .product--content-related-cont .cheese--related-item .cheese--related-item-copy h5 {
    padding-right: 30px;
    font-size: 7.5cqw;
  }
}
@media (max-width: 47.99em) {
  .region--content .region--content-related .product--content-related-cont .cheese--related-item .cheese--related-item-copy h5 {
    font-size: 10cqw;
  }
}
.region--content .region--content-related .product--content-related-cont .cheese--related-item .cheese--related-item-copy h5 i {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
.region--content .region--content-related .product--content-related-cont .cheese--related-item .cheese--related-item-copy h5 i img {
  display: block;
  width: 25px;
  height: 25px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.region--content .region--content-related .product--content-related-cont .cheese--related-item .cheese--related-item-copy p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: #0E4A8C;
  font-weight: 400;
  text-decoration: none;
}
@media (max-width: 63.99em) {
  .region--content .region--content-related .product--content-related-cont .cheese--related-item .cheese--related-item-copy p {
    font-size: 5cqw;
  }
}
@media (max-width: 47.99em) {
  .region--content .region--content-related .product--content-related-cont .cheese--related-item .cheese--related-item-copy p {
    font-size: 7.5cqw;
  }
}
.region--content .region--content-related .product--content-related-cont .product--content-related-filler {
  display: inline-block;
  width: 25%;
  position: relative;
  overflow: hidden;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
}
.region--content .region--content-related .product--content-related-cont .product--content-related-filler img, .region--content .region--content-related .product--content-related-cont .product--content-related-filler picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 73.74em) {
  .region--content .region--content-related .product--content-related-cont.three-col {
    flex-wrap: nowrap;
  }
}
@media (max-width: 47.99em) {
  .region--content .region--content-related .product--content-related-cont.three-col {
    flex-wrap: wrap;
  }
}
.region--content .region--content-related .product--content-related-cont.three-col .cheese--related-item {
  width: 33.3333333333%;
}
.region--content .region--content-related .product--content-related-cont.three-col .cheese--related-item .cheese--related-item-img {
  height: 80cqw;
}
@media (max-width: 73.74em) {
  .region--content .region--content-related .product--content-related-cont.two-col {
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 650px) {
  .region--content .region--content-related .product--content-related-cont.two-col {
    flex-wrap: wrap;
  }
}
.region--content .region--content-related .product--content-related-cont.two-col .cheese--related-item {
  width: 50%;
}
@media screen and (max-width: 650px) {
  .region--content .region--content-related .product--content-related-cont.two-col .cheese--related-item {
    width: calc(50% - 10px);
    margin-right: 0;
    margin-bottom: 20px;
  }
  .region--content .region--content-related .product--content-related-cont.two-col .cheese--related-item:nth-child(odd) {
    margin-right: 20px;
  }
}
.region--content .region--content-related .product--content-related-cont.two-col .cheese--related-item .cheese--related-item-img {
  height: 50cqw;
}
@media (max-width: 100.74em) {
  .region--content .region--content-related .product--content-related-cont.two-col .cheese--related-item .cheese--related-item-copy h5 {
    font-size: 30px;
  }
  .region--content .region--content-related .product--content-related-cont.two-col .cheese--related-item .cheese--related-item-copy p {
    font-size: 16px;
  }
}
@media screen and (max-width: 650px) {
  .region--content .region--content-related .product--content-related-cont.two-col .product--content-related-filler {
    display: none;
  }
}
.region--content .region--content-related .product--content-related-cont.one-col {
  width: 100%;
}
.region--content .region--content-related .product--content-related-cont.one-col div:first-child {
  width: 100%;
}
.region--content .region--content-related .product--content-related-cont.one-col .no-swiper {
  display: flex;
  display: -webkit-flex;
  width: 100%;
}
.region--content .region--content-related .product--content-related-cont.one-col .cheese--related-item {
  width: 50%;
}
@media (max-width: 100.74em) {
  .region--content .region--content-related .product--content-related-cont.one-col .cheese--related-item h5 {
    font-size: 30px;
  }
}
@media (max-width: 73.74em) {
  .region--content .region--content-related .product--content-related-cont.one-col .cheese--related-item {
    width: 75%;
  }
}
@media (max-width: 47.99em) {
  .region--content .region--content-related .product--content-related-cont.one-col .cheese--related-item {
    width: 50%;
  }
}
@media screen and (max-width: 600px) {
  .region--content .region--content-related .product--content-related-cont.one-col .cheese--related-item {
    width: 100%;
    margin-right: 0;
  }
}
.region--content .region--content-related .product--content-related-cont.one-col .cheese--related-item .cheese--related-item-img {
  height: 40cqw;
}
@media (max-width: 73.74em) {
  .region--content .region--content-related .product--content-related-cont.one-col .product--content-related-filler {
    width: 25%;
  }
}
@media (max-width: 47.99em) {
  .region--content .region--content-related .product--content-related-cont.one-col .product--content-related-filler {
    width: 50%;
  }
}
@media screen and (max-width: 600px) {
  .region--content .region--content-related .product--content-related-cont.one-col .product--content-related-filler {
    display: none;
  }
}
.region--content .region--content-related .product--content-related-cont .swiper-button-prev {
  left: -40px;
}
.region--content .region--content-related .product--content-related-cont .swiper-button-next {
  right: -40px;
}
.region--content .region--content-related .region--content-viewall {
  display: block;
  margin: 100px auto 0 auto;
  text-align: center;
}
@media (max-width: 47.99em) {
  .region--content .region--content-related .region--content-viewall {
    margin-top: 40px;
  }
}
.region--content .region--content-related .region--content-viewall a {
  font-family: "Jost", Arial, sans-serif;
  font-size: 28px;
  color: #0E4A8C;
  font-weight: 600;
  position: relative;
  padding-left: 50px;
  text-decoration: none;
}
@media (max-width: 47.99em) {
  .region--content .region--content-related .region--content-viewall a {
    font-size: 6vw;
    padding-left: 40px;
  }
}
.region--content .region--content-related .region--content-viewall a i {
  display: inline-block;
  position: absolute;
  left: 0;
  height: 100%;
}
.region--content .region--content-related .region--content-viewall a i img {
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 47.99em) {
  .region--content .region--content-related .region--content-viewall a i img {
    width: 25px;
    height: 25px;
  }
}

.basic--banner {
  padding-top: 200px;
}
@media (max-width: 47.99em) {
  .basic--banner {
    padding-top: 150px;
  }
}
.basic--banner .basic--banner-copy {
  display: block;
  text-align: right;
  width: 100%;
  margin: 0 0 60px 0;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: flex-end;
}
@media (max-width: 47.99em) {
  .basic--banner .basic--banner-copy {
    align-items: flex-start;
    text-align: left;
  }
}
.basic--banner .basic--banner-copy h1 {
  font-family: "relation-one", Arial, sans-serif;
  font-size: 84px;
  color: #B4A74A;
  margin: 0 0 15px 0;
  line-height: 1;
}
@media (max-width: 47.99em) {
  .basic--banner .basic--banner-copy h1 {
    font-size: 16vw;
  }
}
.basic--banner .basic--banner-copy p {
  display: block;
  width: 100%;
  text-align: right;
  max-width: 935px;
  font-family: "Jost", Arial, sans-serif;
  font-size: 28px;
  color: white;
  font-weight: 300;
  padding-bottom: 0;
}
@media (max-width: 47.99em) {
  .basic--banner .basic--banner-copy p {
    text-align: left;
    font-size: 16px;
  }
}
.basic--banner .basic--banner-copy a {
  color: #B4A74A;
  font-weight: 400;
  text-decoration: underline;
}

.region--list {
  padding-bottom: 60px;
}
.region--list .region--list-cont .region--list-flex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  width: 100%;
  margin-bottom: 80px;
}
@media (max-width: 47.99em) {
  .region--list .region--list-cont .region--list-flex {
    flex-wrap: wrap;
    flex-direction: row !important;
  }
}
.region--list .region--list-cont .region--list-flex:last-child {
  margin-bottom: 0;
}
.region--list .region--list-cont .region--list-flex:nth-child(even) {
  flex-direction: row-reverse;
}
.region--list .region--list-cont .region--list-flex:nth-child(even) .region--item-img {
  border-top-left-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
}
.region--list .region--list-cont .region--list-flex:nth-child(odd) .region--item-img {
  border-top-right-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
}
.region--list .region--list-cont .region--list-flex .region--item-img {
  display: inline-block;
  width: 65%;
  position: relative;
  overflow: hidden;
}
@media (max-width: 76.615em) {
  .region--list .region--list-cont .region--list-flex .region--item-img {
    width: 55%;
  }
}
@media (max-width: 63.99em) {
  .region--list .region--list-cont .region--list-flex .region--item-img {
    width: 50%;
  }
}
@media (max-width: 47.99em) {
  .region--list .region--list-cont .region--list-flex .region--item-img {
    width: 95%;
    border-top-right-radius: 2.2vw;
    border-bottom-right-radius: 2.2vw;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
.region--list .region--list-cont .region--list-flex .region--item-img img, .region--list .region--list-cont .region--list-flex .region--item-img video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.region--list .region--list-cont .region--list-flex .region--item-copy {
  display: inline-block;
  width: 35%;
  padding: 0 40px;
}
@media (max-width: 76.615em) {
  .region--list .region--list-cont .region--list-flex .region--item-copy {
    width: 45%;
  }
}
@media (max-width: 63.99em) {
  .region--list .region--list-cont .region--list-flex .region--item-copy {
    width: 50%;
  }
}
@media (max-width: 47.99em) {
  .region--list .region--list-cont .region--list-flex .region--item-copy {
    width: 100%;
    margin-top: 30px;
    padding: 0 25px;
  }
}
.region--list .region--list-cont .region--list-flex .region--item-copy a {
  text-decoration: none;
}
.region--list .region--list-cont .region--list-flex .region--item-copy a h2 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 34px;
  color: white;
  font-weight: 600;
  margin: 0 0 10px 0;
  position: relative;
  text-decoration: none;
  padding-right: 35px;
}
.region--list .region--list-cont .region--list-flex .region--item-copy a h2 i {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
.region--list .region--list-cont .region--list-flex .region--item-copy a h2 i img {
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.region--list .region--list-cont .region--list-flex .region--item-copy h5 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 20px;
  color: #B4A74A;
  font-weight: 400;
  margin: 12px 0;
}
.region--list .region--list-cont .region--list-flex .region--item-copy p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 400;
}
.region--list .region--list-cont .region--list-flex .region--item-copy ul {
  display: block;
}
.region--list .region--list-cont .region--list-flex .region--item-copy ul li {
  margin-bottom: 10px;
}
.region--list .region--list-cont .region--list-flex .region--item-copy ul li:last-child {
  margin-bottom: 0;
}
.region--list .region--list-cont .region--list-flex .region--item-copy ul li a {
  text-decoration: underline;
  display: block;
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: #80C0E7;
  font-weight: 500;
}

.cheese--banner.brand {
  padding-bottom: 12vw;
}
.cheese--banner.brand .cheese--banner-flex .cheese--banner-copy {
  position: relative;
}
@media screen and (max-width: 920px) {
  .cheese--banner.brand .cheese--banner-flex .cheese--banner-copy h1, .cheese--banner.brand .cheese--banner-flex .cheese--banner-copy h2 {
    padding-right: 200px;
    display: block;
  }
}
@media (max-width: 47.99em) {
  .cheese--banner.brand .cheese--banner-flex .cheese--banner-copy h1, .cheese--banner.brand .cheese--banner-flex .cheese--banner-copy h2 {
    padding-right: 160px;
  }
}
.cheese--banner.brand .cheese--banner-flex .cheese--banner-copy .brand-logo-mob {
  display: none;
}
@media screen and (max-width: 920px) {
  .cheese--banner.brand .cheese--banner-flex .cheese--banner-copy .brand-logo-mob {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 20px;
    width: 100%;
    max-width: 180px;
    background-color: white;
    border-bottom-right-radius: 2.2vw;
    border-bottom-left-radius: 2.2vw;
    border-top-right-radius: 2.2vw;
    border-top-left-radius: 2.2vw;
    padding: 10px 20px;
  }
  .cheese--banner.brand .cheese--banner-flex .cheese--banner-copy .brand-logo-mob img, .cheese--banner.brand .cheese--banner-flex .cheese--banner-copy .brand-logo-mob picture {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
@media (max-width: 34.365em) {
  .cheese--banner.brand .cheese--banner-flex .cheese--banner-copy .brand-logo-mob {
    max-width: 125px;
  }
}
.cheese--banner.brand .cheese--banner-flex .cheese--banner-img {
  position: relative;
}
@media screen and (max-width: 920px) {
  .cheese--banner.brand .cheese--banner-flex .cheese--banner-img {
    display: none;
  }
}
.cheese--banner.brand .cheese--banner-flex .cheese--banner-img .brand-logo {
  display: block;
  max-width: 440px;
  width: calc(100% - 40px);
  height: 220px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: white;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  text-align: center;
}
@media (max-width: 47.99em) {
  .cheese--banner.brand .cheese--banner-flex .cheese--banner-img .brand-logo {
    display: block;
    position: static;
    transform: translateY(0);
    top: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px 0;
    width: 100%;
    height: 100%;
  }
}
.cheese--banner.brand .cheese--banner-flex .cheese--banner-img .brand-logo img, .cheese--banner.brand .cheese--banner-flex .cheese--banner-img .brand-logo picture {
  display: block;
  width: auto;
  height: 90%;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 47.99em) {
  .cheese--banner.brand .cheese--banner-flex .cheese--banner-img .brand-logo img, .cheese--banner.brand .cheese--banner-flex .cheese--banner-img .brand-logo picture {
    display: block;
    transform: translateY(0);
    top: auto;
    width: auto;
    height: 100%;
    object-fit: cover;
  }
}
.cheese--banner.brand .brand--banner-copy {
  display: block;
  text-align: center;
  margin: 80px auto 0 auto;
  padding: 0 40px;
}
@media (max-width: 47.99em) {
  .cheese--banner.brand .brand--banner-copy {
    margin: 40px auto 30px auto;
  }
}
.cheese--banner.brand .brand--banner-copy h3 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 36px;
  color: white;
  font-weight: 300;
  margin: 0;
  text-align: center;
}
@media (max-width: 34.365em) {
  .cheese--banner.brand .brand--banner-copy h3 {
    font-size: 7vw;
  }
}

.brand--related {
  margin-top: -8vw;
  padding-bottom: 80px;
}
@media (max-width: 47.99em) {
  .brand--related {
    padding: 0 0 40px 0;
  }
}
.brand--related .brand--related-flex {
  display: flex;
  display: -webkit-flex;
}
@media (max-width: 47.99em) {
  .brand--related .brand--related-flex {
    width: calc(100% - 60px);
    margin: 0 auto;
  }
}
.brand--related .brand--related-flex .cheese--related-item {
  display: inline-flex;
  display: -webkit-inline-flex;
  flex-direction: column;
  width: 25%;
  border-radius: 2.2vw;
  background-color: white;
  margin-right: 30px;
  text-decoration: none;
  container-type: inline-size;
  container-name: brandcard;
}
.brand--related .brand--related-flex .cheese--related-item:last-child {
  margin-right: 0;
}
.brand--related .brand--related-flex .cheese--related-item .cheese--related-item-img {
  width: 100%;
  height: 100cqw;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  overflow: hidden;
}
.brand--related .brand--related-flex .cheese--related-item .cheese--related-item-img img, .brand--related .brand--related-flex .cheese--related-item .cheese--related-item-img picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.brand--related .brand--related-flex .cheese--related-item .cheese--related-item-copy {
  padding: 20px 30px;
}
@media (max-width: 63.99em) {
  .brand--related .brand--related-flex .cheese--related-item .cheese--related-item-copy {
    padding: 20px 0;
  }
}
.brand--related .brand--related-flex .cheese--related-item .cheese--related-item-copy h5 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 30px;
  color: #0E4A8C;
  font-weight: 600;
  margin: 0 0 8px 0;
  position: relative;
  padding-right: 30px;
  text-decoration: none;
}
@media (max-width: 100.74em) {
  .brand--related .brand--related-flex .cheese--related-item .cheese--related-item-copy h5 {
    font-size: 7.5cqw;
    padding-right: 30px;
  }
}
@media (max-width: 47.99em) {
  .brand--related .brand--related-flex .cheese--related-item .cheese--related-item-copy h5 {
    font-size: 10cqw;
    padding-right: 25px;
  }
}
.brand--related .brand--related-flex .cheese--related-item .cheese--related-item-copy h5 i {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
.brand--related .brand--related-flex .cheese--related-item .cheese--related-item-copy h5 i img {
  display: block;
  width: 25px;
  height: 25px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 47.99em) {
  .brand--related .brand--related-flex .cheese--related-item .cheese--related-item-copy h5 i img {
    width: 20px;
    height: 20px;
  }
}
.brand--related .brand--related-flex .cheese--related-item .cheese--related-item-copy p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: #0E4A8C;
  font-weight: 400;
  text-decoration: none;
}
@media (max-width: 100.74em) {
  .brand--related .brand--related-flex .cheese--related-item .cheese--related-item-copy p {
    font-size: 6cqw;
  }
}
@media (max-width: 63.99em) {
  .brand--related .brand--related-flex .cheese--related-item .cheese--related-item-copy p {
    font-size: 5cqw;
  }
}
@media (max-width: 47.99em) {
  .brand--related .brand--related-flex .cheese--related-item .cheese--related-item-copy p {
    font-size: 7.5cqw;
  }
}
@media (max-width: 63.99em) {
  .brand--related .brand--related-flex.three-col {
    flex-wrap: nowrap;
  }
}
@media (max-width: 47.99em) {
  .brand--related .brand--related-flex.three-col {
    flex-wrap: wrap;
  }
}
.brand--related .brand--related-flex.three-col .cheese--related-item {
  width: 33.3333333333%;
}
@media (max-width: 63.99em) {
  .brand--related .brand--related-flex.three-col .cheese--related-item {
    margin-right: 20px;
  }
  .brand--related .brand--related-flex.three-col .cheese--related-item:last-child {
    margin-right: 0;
  }
}
@media (max-width: 47.99em) {
  .brand--related .brand--related-flex.three-col .cheese--related-item {
    width: calc(50% - 10px);
  }
  .brand--related .brand--related-flex.three-col .cheese--related-item:nth-child(2) {
    margin-right: 0;
  }
}
.brand--related .brand--related-flex.three-col .cheese--related-item .cheese--related-item-img {
  height: 80cqw;
}
@media (max-width: 63.99em) {
  .brand--related .brand--related-flex.two-col {
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 650px) {
  .brand--related .brand--related-flex.two-col {
    flex-wrap: wrap;
  }
}
.brand--related .brand--related-flex.two-col .cheese--related-item {
  width: 50%;
}
@media screen and (max-width: 650px) {
  .brand--related .brand--related-flex.two-col .cheese--related-item {
    width: 100%;
    margin-right: 0;
  }
}
.brand--related .brand--related-flex.two-col .cheese--related-item .cheese--related-item-img {
  height: 50cqw;
}
@media (max-width: 100.74em) {
  .brand--related .brand--related-flex.two-col .cheese--related-item .cheese--related-item-copy h5 {
    font-size: 30px;
  }
  .brand--related .brand--related-flex.two-col .cheese--related-item .cheese--related-item-copy p {
    font-size: 16px;
  }
}
.brand--related .brand--related-flex.one-col {
  width: 100% !important;
}
.brand--related .brand--related-flex.one-col div:first-child {
  width: 100%;
}
.brand--related .brand--related-flex.one-col .no-swiper {
  display: flex;
  display: -webkit-flex;
  width: 100%;
}
.brand--related .brand--related-flex.one-col .cheese--related-item {
  width: 50%;
}
@media (max-width: 100.74em) {
  .brand--related .brand--related-flex.one-col .cheese--related-item h5 {
    font-size: 30px;
  }
}
.brand--related .brand--related-flex.one-col .cheese--related-item p {
  font-size: 16px !important;
}
@media (max-width: 73.74em) {
  .brand--related .brand--related-flex.one-col .cheese--related-item {
    width: 75%;
  }
}
@media (max-width: 47.99em) {
  .brand--related .brand--related-flex.one-col .cheese--related-item {
    width: 50%;
  }
}
@media screen and (max-width: 600px) {
  .brand--related .brand--related-flex.one-col .cheese--related-item {
    width: 100%;
    margin-right: 0;
  }
}
.brand--related .brand--related-flex.one-col .cheese--related-item .cheese--related-item-img {
  height: 40cqw;
}
@media (max-width: 73.74em) {
  .brand--related .brand--related-flex.one-col .product--content-related-filler {
    width: 25%;
  }
}
@media (max-width: 47.99em) {
  .brand--related .brand--related-flex.one-col .product--content-related-filler {
    width: 50%;
  }
}
@media screen and (max-width: 600px) {
  .brand--related .brand--related-flex.one-col .product--content-related-filler {
    display: none;
  }
}

.brand--section-img {
  display: block;
  width: 100%;
}
.brand--section-img img, .brand--section-img picture {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media (max-width: 47.99em) {
  .region--list.brand .region--list-cont .region--list-flex {
    flex-wrap: wrap;
    flex-direction: row !important;
    margin-bottom: 40px;
  }
}
@media (max-width: 47.99em) {
  .region--list.brand .region--list-cont .region--list-flex:nth-child(even) .region--item-img {
    border-top-right-radius: 2.2vw;
    border-bottom-right-radius: 2.2vw;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.region--list.brand .region--list-cont .region--list-flex:nth-child(even) .region--item-img a .brand-logo {
  right: 60px;
  left: auto;
}
@media (max-width: 63.99em) {
  .region--list.brand .region--list-cont .region--list-flex:nth-child(even) .region--item-img a .brand-logo {
    right: 20px;
  }
}
@media (max-width: 63.99em) {
  .region--list.brand .region--list-cont .region--list-flex .region--item-img {
    width: 50%;
  }
}
@media (max-width: 47.99em) {
  .region--list.brand .region--list-cont .region--list-flex .region--item-img {
    margin-bottom: 0;
    height: 25vw;
  }
}
@media (max-width: 47.99em) {
  .region--list.brand .region--list-cont .region--list-flex .region--item-img img, .region--list.brand .region--list-cont .region--list-flex .region--item-img picture {
    display: none;
  }
}
.region--list.brand .region--list-cont .region--list-flex .region--item-img a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.region--list.brand .region--list-cont .region--list-flex .region--item-img a .brand-logo {
  display: block;
  width: 440px;
  height: 220px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 60px;
  background-color: white;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  text-align: center;
}
@media (max-width: 63.99em) {
  .region--list.brand .region--list-cont .region--list-flex .region--item-img a .brand-logo {
    width: calc(100% - 40px);
    left: 20px;
    height: 45%;
  }
}
@media (max-width: 47.99em) {
  .region--list.brand .region--list-cont .region--list-flex .region--item-img a .brand-logo {
    display: block;
    position: static;
    transform: translateY(0);
    top: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px 0;
    width: 100%;
    height: 100%;
  }
}
.region--list.brand .region--list-cont .region--list-flex .region--item-img a .brand-logo img, .region--list.brand .region--list-cont .region--list-flex .region--item-img a .brand-logo picture {
  display: block;
  width: auto;
  height: 90%;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 47.99em) {
  .region--list.brand .region--list-cont .region--list-flex .region--item-img a .brand-logo img, .region--list.brand .region--list-cont .region--list-flex .region--item-img a .brand-logo picture {
    display: block;
    transform: translateY(0);
    top: auto;
    width: auto;
    height: 100%;
    object-fit: cover;
  }
}
@media (max-width: 63.99em) {
  .region--list.brand .region--list-cont .region--list-flex .region--item-copy {
    width: 50%;
  }
}
@media (max-width: 47.99em) {
  .region--list.brand .region--list-cont .region--list-flex .region--item-copy {
    width: 100%;
  }
}

.brand--content {
  background-color: #F2F2F2;
  padding: 100px 0;
}
@media (max-width: 47.99em) {
  .brand--content {
    padding: 60px 0;
  }
}
.brand--content h3 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 44px;
  color: #0E4A8C;
  font-weight: 300;
  text-align: center;
  display: block;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
@media (max-width: 47.99em) {
  .brand--content h3 {
    font-size: 6vw;
  }
}
.brand--content .brand--content-blocks {
  display: flex;
  display: -webkit-flex;
  margin-top: 100px;
  padding-top: 9vw;
}
@media (max-width: 47.99em) {
  .brand--content .brand--content-blocks {
    flex-wrap: wrap;
    margin-top: 60px;
    padding-top: 0;
  }
}
.brand--content .brand--content-blocks .brand--block {
  display: inline-block;
  width: 50%;
  container-type: inline-size;
  container-name: brandBlock;
}
@media (max-width: 47.99em) {
  .brand--content .brand--content-blocks .brand--block {
    width: 100%;
    margin: 0 0 30px 0 !important;
  }
}
.brand--content .brand--content-blocks .brand--block:first-child {
  margin-right: 40px;
  margin-top: -9vw;
}
@media (max-width: 63.99em) {
  .brand--content .brand--content-blocks .brand--block:first-child {
    margin-right: 20px;
  }
}
.brand--content .brand--content-blocks .brand--block .brand--block-img {
  display: block;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  position: relative;
  overflow: hidden;
  height: 55cqw;
}
.brand--content .brand--content-blocks .brand--block .brand--block-img img, .brand--content .brand--content-blocks .brand--block .brand--block-img picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.brand--content .brand--content-blocks .brand--block .brand--block-copy {
  display: block;
  background-color: white;
  padding: 40px;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: #0E4A8C;
  font-weight: 400;
}
@media (max-width: 63.99em) {
  .brand--content .brand--content-blocks .brand--block .brand--block-copy {
    padding: 25px;
  }
}
.brand--content .brand--content-blocks .brand--block .brand--block-copy p, .brand--content .brand--content-blocks .brand--block .brand--block-copy li {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: #0E4A8C;
  font-weight: 400;
}
.brand--content .brand--content-blocks .brand--block .brand--block-copy a {
  color: #B4A74A;
  text-decoration: underline;
  font-weight: 600;
}
.brand--content .brand--viewall {
  display: block;
  margin: 100px auto 0 auto;
  text-align: center;
}
@media (max-width: 47.99em) {
  .brand--content .brand--viewall {
    margin-top: 40px;
  }
}
.brand--content .brand--viewall a {
  font-family: "Jost", Arial, sans-serif;
  font-size: 28px;
  color: #0E4A8C;
  font-weight: 600;
  position: relative;
  padding-left: 50px;
  text-decoration: none;
}
@media (max-width: 47.99em) {
  .brand--content .brand--viewall a {
    font-size: 6vw;
    padding-left: 40px;
  }
}
.brand--content .brand--viewall a i {
  display: inline-block;
  position: absolute;
  left: 0;
  height: 100%;
}
.brand--content .brand--viewall a i img {
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 47.99em) {
  .brand--content .brand--viewall a i img {
    width: 25px;
    height: 25px;
  }
}

.cheese--banner.about--banner {
  background: rgb(14, 74, 140);
  background: -moz-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: -webkit-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.cheese--banner.about--banner .cheese--banner-flex .cheese--banner-copy h1 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 66px;
  color: white;
  font-weight: 300;
}
@media (max-width: 73.74em) {
  .cheese--banner.about--banner .cheese--banner-flex .cheese--banner-copy h1 {
    font-size: 7vw;
  }
}
@media (max-width: 47.99em) {
  .cheese--banner.about--banner .cheese--banner-flex .cheese--banner-copy h1 {
    font-size: 9vw;
  }
}
.cheese--banner.about--banner .cheese--banner-flex .cheese--banner-copy h2 {
  font-family: "relation-one", Arial, sans-serif;
  font-size: 50px;
  color: #B4A74A;
  margin-bottom: 10px;
}
@media (max-width: 73.74em) {
  .cheese--banner.about--banner .cheese--banner-flex .cheese--banner-copy h2 {
    font-size: 5.5vw;
  }
}
@media (max-width: 47.99em) {
  .cheese--banner.about--banner .cheese--banner-flex .cheese--banner-copy h2 {
    font-size: 6.5vw;
  }
}

.about--content {
  background-color: white;
  padding: 80px 0;
}
@media (max-width: 47.99em) {
  .about--content {
    padding: 40px 0;
  }
}
.about--content .about--content-flex {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
}
@media (max-width: 47.99em) {
  .about--content .about--content-flex {
    flex-wrap: wrap;
  }
}
.about--content .about--content-flex .about--content-img {
  display: inline-block;
  width: 50%;
  border-top-left-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  border-top-right-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  position: relative;
  overflow: hidden;
  margin-right: 40px;
  height: auto;
}
@media (max-width: 63.99em) {
  .about--content .about--content-flex .about--content-img {
    margin-right: 0;
  }
}
@media (max-width: 47.99em) {
  .about--content .about--content-flex .about--content-img {
    width: 100%;
    height: 50vw;
  }
}
.about--content .about--content-flex .about--content-img img, .about--content .about--content-flex .about--content-img picture {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.about--content .about--content-flex .about--content-copy {
  display: inline-block;
  width: 50%;
  padding: 40px 0 40px 20px;
}
@media (max-width: 47.99em) {
  .about--content .about--content-flex .about--content-copy {
    width: 100%;
    padding: 20px 0 0 0;
  }
}
@media screen and (max-width: 650px) {
  .about--content .about--content-flex .about--content-copy {
    width: 100%;
    padding: 20px 0 0 0;
  }
}
.about--content .about--content-flex .about--content-copy h2 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 50px;
  color: #0E4A8C;
  font-weight: 300;
  margin: 0 0 20px 0;
}
.about--content .about--content-flex .about--content-copy p, .about--content .about--content-flex .about--content-copy li {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: #0E4A8C;
  font-weight: 400;
}
.about--content .about--content-flex .about--content-copy ul {
  list-style: disc;
  padding-left: 20px;
  padding-bottom: 10px;
}
.about--content .about--content-flex .about--content-copy ul li {
  padding-bottom: 10px;
}

.about--values {
  padding: 100px 0;
}
@media (max-width: 47.99em) {
  .about--values {
    padding: 20px 0 10px 0;
  }
}
.about--values .about--values-header {
  display: block;
  width: 100%;
  max-width: 450px;
  margin-bottom: 100px;
}
@media (max-width: 47.99em) {
  .about--values .about--values-header {
    margin-bottom: 30px;
  }
}
.about--values .about--values-header h2 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 60px;
  color: #0E4A8C;
  font-weight: 300;
  margin: 0 0 20px 0;
  line-height: 1.2;
}
.about--values .about--values-header p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: #0E4A8C;
  font-weight: 400;
}
.about--values .about--values-flex {
  display: flex;
  display: -webkit-flex;
}
@media screen and (max-width: 1000px) {
  .about--values .about--values-flex {
    flex-wrap: wrap;
  }
}
.about--values .about--values-flex .about--values-value {
  display: inline-block;
  width: 33.3333333333%;
  position: relative;
  margin-right: 20px;
  container-type: inline-size;
  container-name: value;
}
@media screen and (max-width: 1000px) {
  .about--values .about--values-flex .about--values-value {
    width: 100%;
    display: flex;
    display: -webkit-flex;
    margin: 0 0 30px 0 !important;
  }
}
@media screen and (max-width: 660px) {
  .about--values .about--values-flex .about--values-value {
    flex-wrap: wrap;
  }
}
.about--values .about--values-flex .about--values-value:nth-child(1) {
  margin-top: 160px;
}
.about--values .about--values-flex .about--values-value:last-child {
  margin-right: 0;
  margin-top: 320px;
}
.about--values .about--values-flex .about--values-value .value--icon {
  display: block;
  position: absolute;
  top: -30cqw;
  z-index: 10;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
  background-color: #0E4A8C;
  border-radius: 100%;
  height: 60cqw;
  width: 60cqw;
  max-width: 65%;
}
@media screen and (max-width: 1000px) {
  .about--values .about--values-flex .about--values-value .value--icon {
    display: none;
  }
}
.about--values .about--values-flex .about--values-value .value--icon .valign {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 5cqw;
}
.about--values .about--values-flex .about--values-value .value--icon .valign img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 18cqw;
  margin: 0 auto;
  text-align: center;
  max-width: 36%;
}
.about--values .about--values-flex .about--values-value .value--icon .valign h3 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 32px;
  color: white;
  font-weight: 600;
  text-align: center;
  margin: 15px 0 0 0;
  line-height: 1.2;
  text-transform: uppercase;
  font-size: 6cqw;
}
.about--values .about--values-flex .about--values-value .about--values-value-img {
  display: block;
  width: 100%;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 1000px) {
  .about--values .about--values-flex .about--values-value .about--values-value-img {
    display: inline-block;
    border-top-left-radius: 2.2vw;
    border-bottom-left-radius: 2.2vw;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
    width: 33%;
    background-color: pink;
  }
}
@media screen and (max-width: 660px) {
  .about--values .about--values-flex .about--values-value .about--values-value-img {
    width: 100%;
    height: auto;
    border-bottom-left-radius: 0;
    border-top-right-radius: 2.2vw;
  }
}
.about--values .about--values-flex .about--values-value .about--values-value-img img, .about--values .about--values-flex .about--values-value .about--values-value-img picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.about--values .about--values-flex .about--values-value .about--values-value-copy {
  display: block;
  background-color: white;
  padding: 40px 40px;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  border: 1px solid #D0D0D0;
  border-top: 0;
}
@media screen and (max-width: 1000px) {
  .about--values .about--values-flex .about--values-value .about--values-value-copy {
    border: 1px solid #D0D0D0;
    border-top-right-radius: 2.2vw;
    border-bottom-right-radius: 2.2vw;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 67%;
  }
}
@media screen and (max-width: 660px) {
  .about--values .about--values-flex .about--values-value .about--values-value-copy {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-left-radius: 2.2vw;
    padding: 20px 25px;
  }
}
.about--values .about--values-flex .about--values-value .about--values-value-copy .about--values-value-copy-mob {
  display: none;
}
@media screen and (max-width: 1000px) {
  .about--values .about--values-flex .about--values-value .about--values-value-copy .about--values-value-copy-mob {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    margin-bottom: 20px;
  }
}
.about--values .about--values-flex .about--values-value .about--values-value-copy .about--values-value-copy-mob img, .about--values .about--values-flex .about--values-value .about--values-value-copy .about--values-value-copy-mob picture {
  display: inline-block;
  width: 100%;
  height: auto;
  max-width: 50px;
  text-align: left;
  margin-right: 15px;
}
.about--values .about--values-flex .about--values-value .about--values-value-copy .about--values-value-copy-mob h3 {
  display: inline-block;
  font-family: "Jost", Arial, sans-serif;
  font-size: 32px;
  color: #0E4A8C;
  font-weight: 600;
  text-align: left;
  margin: 0;
  line-height: 1.2;
  text-transform: uppercase;
}
@media (max-width: 34.365em) {
  .about--values .about--values-flex .about--values-value .about--values-value-copy .about--values-value-copy-mob h3 {
    font-size: 7cqw;
  }
}
.about--values .about--values-flex .about--values-value .about--values-value-copy p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: #0E4A8C;
  font-weight: 400;
}

.about--image {
  display: block;
  width: 100%;
}
.about--image img, .about--image picture {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.news--article {
  background: rgb(14, 74, 140);
  background: -moz-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: -webkit-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 250px;
  padding-bottom: 60px;
}
@media screen and (max-width: 920px) {
  .news--article {
    padding-top: 120px;
  }
}
.news--article .news--article-flex {
  display: flex;
  display: -webkit-flex;
  align-items: flex-start;
}
@media (max-width: 47.99em) {
  .news--article .news--article-flex {
    flex-wrap: wrap;
  }
}
.news--article .news--article-flex .news--article-copy {
  display: inline-block;
  width: 55%;
  padding-right: 70px;
  padding-bottom: 50px;
}
@media (max-width: 73.74em) {
  .news--article .news--article-flex .news--article-copy {
    padding-right: 30px;
    width: 60%;
  }
}
@media (max-width: 47.99em) {
  .news--article .news--article-flex .news--article-copy {
    width: 100%;
    padding-right: 0;
    padding-bottom: 30px;
    order: 2;
  }
}
.news--article .news--article-flex .news--article-copy h1 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 44px;
  color: white;
  font-weight: 300;
  margin: 0 0 40px 0;
}
@media (max-width: 34.365em) {
  .news--article .news--article-flex .news--article-copy h1 {
    font-size: 8vw;
  }
}
.news--article .news--article-flex .news--article-copy .date {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 300;
  font-style: italic;
  padding-bottom: 40px;
}
.news--article .news--article-flex .news--article-copy h2 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 36px;
  color: white;
  font-weight: 600;
  margin: 0 0 20px 0;
}
.news--article .news--article-flex .news--article-copy h3 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 32px;
  color: white;
  font-weight: 600;
  margin: 0 0 20px 0;
}
.news--article .news--article-flex .news--article-copy h4 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 30px;
  color: white;
  font-weight: 600;
  margin: 0 0 20px 0;
}
.news--article .news--article-flex .news--article-copy h5 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 26px;
  color: white;
  font-weight: 600;
  margin: 0 0 20px 0;
}
.news--article .news--article-flex .news--article-copy h6 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 20px;
  color: white;
  font-weight: 600;
  margin: 0 0 20px 0;
}
.news--article .news--article-flex .news--article-copy p, .news--article .news--article-flex .news--article-copy li {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 400;
}
.news--article .news--article-flex .news--article-copy a {
  text-decoration: underline;
  color: #B4A74A;
}
.news--article .news--article-flex .news--article-copy li {
  padding-bottom: 10px;
}
.news--article .news--article-flex .news--article-copy ol {
  padding-left: 20px;
  padding-bottom: 10px;
}
.news--article .news--article-flex .news--article-copy ul {
  padding-left: 20px;
  list-style: disc;
  padding-bottom: 10px;
}
.news--article .news--article-flex .news--article-copy blockquote {
  display: block;
  border: 1px solid white;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  padding: 30px;
  margin-bottom: 40px;
}
.news--article .news--article-flex .news--article-images {
  display: inline-block;
  width: 45%;
  position: relative;
}
@media (max-width: 73.74em) {
  .news--article .news--article-flex .news--article-images {
    width: 40%;
  }
}
@media (max-width: 47.99em) {
  .news--article .news--article-flex .news--article-images {
    width: 100%;
    margin: 0 auto 30px auto;
    order: 1;
  }
}
.news--article .news--article-flex .news--article-images .swiper {
  width: 100%;
}
.news--article .news--article-flex .news--article-images .no-swiper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  width: 100%;
}
@media (max-width: 47.99em) {
  .news--article .news--article-flex .news--article-images .no-swiper {
    display: flex;
    display: -webkit-flex;
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
}
.news--article .news--article-flex .news--article-images .news-img {
  display: inline-block;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  position: relative;
  overflow: hidden;
}
@media (max-width: 47.99em) {
  .news--article .news--article-flex .news--article-images .news-img {
    height: auto;
  }
}
.news--article .news--article-flex .news--article-images .news-img:nth-child(3n+1) {
  width: 100%;
  grid-column: span 2;
}
.news--article .news--article-flex .news--article-images .news-img img, .news--article .news--article-flex .news--article-images .news-img picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.news--article .news--back {
  display: inline-block;
  background-color: #B4A74A;
  color: white;
  text-decoration: none;
  text-align: center;
  padding: 18px 30px 18px 45px;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  position: relative;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  line-height: 1;
}
.news--article .news--back:hover {
  background-color: #958A40;
}
.news--article .news--back:before {
  display: inline-block;
  content: "";
  background-image: url("../static/icon-arrow-basic.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px 16px;
  width: 30px;
  height: 16px;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.cheese--related.-news .cheese--related-flex .cheese--related-item {
  justify-content: flex-start;
  position: relative;
}
.cheese--related.-news .cheese--related-flex .cheese--related-item .cheese--related-item-img {
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: 70cqw;
}
.cheese--related.-news .cheese--related-flex .cheese--related-item .cheese--related-item-copy h5 {
  padding-right: 0;
}
.cheese--related.-news .news--viewall {
  display: block;
  margin: 60px auto 0 auto;
  text-align: center;
}
@media (max-width: 47.99em) {
  .cheese--related.-news .news--viewall {
    margin-top: 40px;
  }
}
.cheese--related.-news .news--viewall a {
  font-family: "Jost", Arial, sans-serif;
  font-size: 28px;
  color: #0E4A8C;
  font-weight: 600;
  position: relative;
  padding-left: 50px;
  text-decoration: none;
}
@media (max-width: 47.99em) {
  .cheese--related.-news .news--viewall a {
    font-size: 6vw;
    padding-left: 40px;
  }
}
.cheese--related.-news .news--viewall a i {
  display: inline-block;
  position: absolute;
  left: 0;
  height: 100%;
}
.cheese--related.-news .news--viewall a i img {
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 47.99em) {
  .cheese--related.-news .news--viewall a i img {
    width: 25px;
    height: 25px;
  }
}

.news--page {
  background: rgb(14, 74, 140);
  background: -moz-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: -webkit-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 180px;
  padding-bottom: 15vw;
}
@media screen and (max-width: 920px) {
  .news--page {
    padding-top: 120px;
  }
}
.news--page h1 {
  text-align: right;
  font-family: "relation-one", Arial, sans-serif;
  font-size: 80px;
  color: #B4A74A;
  margin: 0;
}
@media (max-width: 47.99em) {
  .news--page h1 {
    text-align: left;
  }
}
.news--page .news--page-main {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  margin-top: 30px;
}
@media (max-width: 47.99em) {
  .news--page .news--page-main {
    flex-wrap: wrap;
    margin-top: 20px;
  }
}
.news--page .news--page-main .news--page-main-copy {
  display: inline-block;
  width: 50%;
  padding-right: 80px;
  padding-top: 30px;
}
@media (max-width: 47.99em) {
  .news--page .news--page-main .news--page-main-copy {
    width: 100%;
    margin-bottom: 30px;
    padding-right: 0;
    padding-top: 0;
  }
}
.news--page .news--page-main .news--page-main-copy h2 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 44px;
  color: white;
  font-weight: 300;
  margin: 0 0 40px 0;
}
@media (max-width: 47.99em) {
  .news--page .news--page-main .news--page-main-copy h2 {
    font-size: 7.5vw;
    margin-bottom: 20px;
  }
}
.news--page .news--page-main .news--page-main-copy .date {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 300;
  font-style: italic;
  padding-bottom: 40px;
}
@media (max-width: 47.99em) {
  .news--page .news--page-main .news--page-main-copy .date {
    padding-bottom: 20px;
  }
}
.news--page .news--page-main .news--page-main-copy p, .news--page .news--page-main .news--page-main-copy li {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 400;
}
.news--page .news--page-main .news--page-main-copy a {
  text-decoration: underline;
  color: #B4A74A;
}
.news--page .news--page-main .news--page-main-copy li {
  padding-bottom: 10px;
}
.news--page .news--page-main .news--page-main-copy ol {
  padding-left: 20px;
  padding-bottom: 10px;
}
.news--page .news--page-main .news--page-main-copy ul {
  padding-left: 20px;
  list-style: disc;
  padding-bottom: 10px;
}
.news--page .news--page-main .news--page-main-copy .read--more {
  display: block;
  width: 100%;
  margin-top: 10px;
}
.news--page .news--page-main .news--page-main-copy .read--more a {
  display: inline-block;
  background-color: #B4A74A;
  color: white;
  text-decoration: none;
  text-align: center;
  padding: 15px 45px 15px 30px;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  position: relative;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  line-height: 1;
}
.news--page .news--page-main .news--page-main-copy .read--more a:hover {
  background-color: #958A40;
}
.news--page .news--page-main .news--page-main-copy .read--more a:before {
  display: inline-block;
  content: "";
  background-image: url("../static/icon-arrow-basic.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px 16px;
  width: 30px;
  height: 16px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
}
.news--page .news--page-main .news--page-main-image {
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  width: 50%;
}
@media (max-width: 47.99em) {
  .news--page .news--page-main .news--page-main-image {
    width: 100%;
  }
}
.news--page .news--page-main .news--page-main-image img, .news--page .news--page-main .news--page-main-image picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news--list {
  margin-top: -8vw;
}
.news--list .news--list-main {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}
.news--list .news--list-main .news--list-item {
  display: inline-block;
  width: calc(25% - 23px);
  border-radius: 2.2vw;
  margin-right: 30px;
  text-decoration: none;
  container-type: inline-size;
  container-name: cheesecard;
  margin-bottom: 50px;
  position: relative;
}
@media (max-width: 73.74em) {
  .news--list .news--list-main .news--list-item {
    margin-right: 15px !important;
    width: calc(33.3333333333% - 10px);
  }
  .news--list .news--list-main .news--list-item:nth-child(3n+3) {
    margin-right: 0 !important;
  }
}
@media (max-width: 47.99em) {
  .news--list .news--list-main .news--list-item {
    width: calc(50% - 8px);
    margin-bottom: 20px;
  }
  .news--list .news--list-main .news--list-item:nth-child(odd) {
    margin-right: 15px !important;
  }
  .news--list .news--list-main .news--list-item:nth-child(even) {
    margin-right: 0 !important;
  }
}
.news--list .news--list-main .news--list-item:nth-child(4n+4) {
  margin-right: 0;
}
.news--list .news--list-main .news--list-item .news--list-img {
  width: 100%;
  height: 70cqw;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  position: relative;
  overflow: hidden;
}
.news--list .news--list-main .news--list-item .news--list-img img, .news--list .news--list-main .news--list-item .news--list-img picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.news--list .news--list-main .news--list-item .news--list-copy {
  padding: 20px;
}
@media (max-width: 34.365em) {
  .news--list .news--list-main .news--list-item .news--list-copy {
    padding: 20px 0px;
  }
}
.news--list .news--list-main .news--list-item .news--list-copy p.date {
  font-family: "Jost", Arial, sans-serif;
  font-size: 15px;
  color: #0E4A8C;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
@media (max-width: 100.74em) {
  .news--list .news--list-main .news--list-item .news--list-copy p.date {
    font-size: 6.5cqw;
  }
}
@media (max-width: 63.99em) {
  .news--list .news--list-main .news--list-item .news--list-copy p.date {
    font-size: 5cqw;
  }
}
.news--list .news--list-main .news--list-item .news--list-copy h3 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 28px;
  color: #0E4A8C;
  font-weight: 600;
  margin: 8px 0 8px 0;
  text-decoration: none;
}
@media (max-width: 100.74em) {
  .news--list .news--list-main .news--list-item .news--list-copy h3 {
    font-size: 7.5cqw;
  }
}
@media (max-width: 47.99em) {
  .news--list .news--list-main .news--list-item .news--list-copy h3 {
    font-size: 8cqw;
  }
  @supports not (container-type: inline-size) {
    .news--list .news--list-main .news--list-item .news--list-copy h3 {
      font-size: 22px;
    }
  }
}
.news--list .news--list-main .news--list-item .news--list-copy p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: #0E4A8C;
  font-weight: 400;
  text-decoration: none;
  padding-bottom: 0;
  padding-right: 20px;
}
@media (max-width: 63.99em) {
  .news--list .news--list-main .news--list-item .news--list-copy p {
    font-size: 6cqw;
  }
}
@media (max-width: 47.99em) {
  .news--list .news--list-main .news--list-item .news--list-copy p {
    font-size: 4.5cqw;
  }
}
@media (max-width: 34.365em) {
  .news--list .news--list-main .news--list-item .news--list-copy p {
    font-size: 5.5cqw;
  }
}
.news--list .news--list-main .news--list-item .news--list-copy i {
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 20px;
  height: 25px;
}
.news--list .news--list-main .news--list-item .news--list-copy i img {
  display: block;
  width: 25px;
  height: 25px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 34.365em) {
  .news--list .news--list-main .news--list-item .news--list-copy i img {
    width: 20px;
    height: 20px;
  }
}
.news--list .load--more {
  display: block;
  margin: 60px auto;
  text-align: center;
}
@media (max-width: 47.99em) {
  .news--list .load--more {
    margin-top: 20px;
  }
}
.news--list .load--more a {
  font-family: "Jost", Arial, sans-serif;
  font-size: 28px;
  color: #0E4A8C;
  font-weight: 600;
  position: relative;
  padding-left: 50px;
  text-decoration: none;
}
@media (max-width: 47.99em) {
  .news--list .load--more a {
    font-size: 6vw;
  }
}
.news--list .load--more a i {
  display: inline-block;
  position: absolute;
  left: 0;
  height: 100%;
}
.news--list .load--more a i img {
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.recipe--article {
  background: rgb(14, 74, 140);
  background: -moz-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: -webkit-linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background: linear-gradient(150deg, rgb(14, 74, 140) 0%, rgb(0, 51, 102) 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 250px;
  padding-bottom: 60px;
}
@media screen and (max-width: 920px) {
  .recipe--article {
    padding-top: 120px;
  }
}
.recipe--article h1 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 48px;
  color: white;
  font-weight: 300;
  margin: 0 0 20px 0;
}
@media (max-width: 47.99em) {
  .recipe--article h1 {
    font-size: 34px;
  }
}
.recipe--article .recipe--article-flex {
  display: flex;
  display: -webkit-flex;
  align-items: flex-start;
}
@media (max-width: 47.99em) {
  .recipe--article .recipe--article-flex {
    flex-wrap: wrap;
  }
}
.recipe--article .recipe--article-flex .recipe--article-main {
  display: inline-block;
  width: 75%;
  padding-right: 40px;
}
@media screen and (max-width: 950px) {
  .recipe--article .recipe--article-flex .recipe--article-main {
    width: 70%;
    padding-right: 25px;
  }
}
@media (max-width: 47.99em) {
  .recipe--article .recipe--article-flex .recipe--article-main {
    width: 100%;
    padding-right: 0;
  }
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-img {
  display: block;
  width: 100%;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  position: relative;
  overflow: hidden;
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-img img, .recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-img picture {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro {
  display: flex;
  display: -webkit-flex;
  margin: 30px 0;
}
@media screen and (max-width: 600px) {
  .recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro {
    flex-wrap: wrap;
  }
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro.no-video .recipe--article-main-ingredients {
  width: 100%;
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro .recipe--article-main-ingredients {
  display: inline-block;
  width: 50%;
  background-color: #0E4A8C;
  padding: 30px;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 400;
  margin-right: 15px;
}
@media (max-width: 63.99em) {
  .recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro .recipe--article-main-ingredients {
    padding: 20px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 600px) {
  .recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro .recipe--article-main-ingredients {
    width: 100%;
    margin-right: 0;
    margin-top: 20px;
    order: 2;
  }
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro .recipe--article-main-ingredients h3 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 28px;
  color: white;
  font-weight: 600;
  margin: 0 0 20px 0;
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro .recipe--article-main-ingredients p, .recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro .recipe--article-main-ingredients li {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 400;
  padding-bottom: 10px;
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro .recipe--article-main-ingredients.long-ingredients {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}
@media (max-width: 76.615em) {
  .recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro .recipe--article-main-ingredients.long-ingredients {
    padding-bottom: 10px;
  }
}
@media (max-width: 47.99em) {
  .recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro .recipe--article-main-ingredients.long-ingredients {
    padding-bottom: 0;
  }
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro .recipe--article-main-ingredients.long-ingredients h3 {
  display: block;
  width: 100%;
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro .recipe--article-main-ingredients.long-ingredients blockquote {
  display: inline-block;
  padding-right: 30px;
  width: 25%;
  flex-grow: 2;
}
@media (max-width: 76.615em) {
  .recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro .recipe--article-main-ingredients.long-ingredients blockquote {
    width: 33.3333333333%;
    margin-bottom: 20px;
  }
}
@media (max-width: 63.99em) {
  .recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro .recipe--article-main-ingredients.long-ingredients blockquote {
    width: 50%;
  }
}
@media (max-width: 34.365em) {
  .recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro .recipe--article-main-ingredients.long-ingredients blockquote {
    width: 100%;
  }
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro .recipe--article-main-video {
  display: inline-block;
  width: 50%;
  margin-left: 15px;
  position: relative;
  overflow: hidden;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
}
@media (max-width: 63.99em) {
  .recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro .recipe--article-main-video {
    margin-left: 10px;
  }
}
@media screen and (max-width: 600px) {
  .recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro .recipe--article-main-video {
    width: 100%;
    margin-left: 0;
    height: 50vw;
    order: 1;
  }
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-intro .recipe--article-main-video iframe {
  display: block;
  width: 100%;
  height: 100%;
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-copy p, .recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-copy li {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 400;
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-copy li {
  padding-bottom: 10px;
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-copy a {
  color: #B4A74A;
  text-decoration: underline;
  font-weight: 600;
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-copy ul {
  list-style: disc;
  padding-left: 20px;
  padding-bottom: 10px;
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-copy ol {
  padding-left: 20px;
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-copy h2 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 36px;
  color: white;
  font-weight: 600;
  margin: 0 0 20px 0;
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-copy h3 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 32px;
  color: white;
  font-weight: 600;
  margin: 0 0 20px 0;
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-copy h4 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 30px;
  color: white;
  font-weight: 600;
  margin: 0 0 20px 0;
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-copy h5 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 26px;
  color: white;
  font-weight: 600;
  margin: 0 0 20px 0;
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-copy h6 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 20px;
  color: white;
  font-weight: 600;
  margin: 0 0 20px 0;
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-copy blockquote {
  display: block;
  border: 1px solid white;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  padding: 30px;
  margin-bottom: 40px;
}
.recipe--article .recipe--article-flex .recipe--article-main .recipe--article-main-copy figure img {
  margin: 5px 0 25px 0;
  display: block;
  width: 100%;
  height: 100%;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
}
.recipe--article .recipe--article-flex .recipe--article-related {
  display: inline-block;
  width: 25%;
}
@media screen and (max-width: 950px) {
  .recipe--article .recipe--article-flex .recipe--article-related {
    width: 30%;
  }
}
@media (max-width: 47.99em) {
  .recipe--article .recipe--article-flex .recipe--article-related {
    width: 100%;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    margin-top: 40px;
  }
}
.recipe--article .recipe--article-flex .recipe--article-related .recipe--article-related-item {
  display: block;
  width: 100%;
  margin-bottom: 50px;
  container-type: inline-size;
  container-name: recipeRelated;
  text-decoration: none;
}
@media (max-width: 47.99em) {
  .recipe--article .recipe--article-flex .recipe--article-related .recipe--article-related-item {
    display: inline-block;
    width: calc(50% - 10px);
  }
  .recipe--article .recipe--article-flex .recipe--article-related .recipe--article-related-item:first-child {
    margin-right: 20px;
  }
  .recipe--article .recipe--article-flex .recipe--article-related .recipe--article-related-item:last-child {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .recipe--article .recipe--article-flex .recipe--article-related .recipe--article-related-item {
    width: 100%;
    margin-right: 0 !important;
  }
}
.recipe--article .recipe--article-flex .recipe--article-related .recipe--article-related-item .recipe--article-related-img {
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  margin-bottom: 15px;
  height: 60cqw;
}
.recipe--article .recipe--article-flex .recipe--article-related .recipe--article-related-item .recipe--article-related-img img, .recipe--article .recipe--article-flex .recipe--article-related .recipe--article-related-item .recipe--article-related-img picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.recipe--article .recipe--article-flex .recipe--article-related .recipe--article-related-item .recipe--article-related-copy {
  display: block;
  width: 100%;
}
.recipe--article .recipe--article-flex .recipe--article-related .recipe--article-related-item .recipe--article-related-copy h5 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 24px;
  color: white;
  font-weight: 600;
  margin: 0 0 20px 0;
  position: relative;
  padding-right: 35px;
}
.recipe--article .recipe--article-flex .recipe--article-related .recipe--article-related-item .recipe--article-related-copy h5 i {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
.recipe--article .recipe--article-flex .recipe--article-related .recipe--article-related-item .recipe--article-related-copy h5 i img {
  display: block;
  width: 25px;
  height: 25px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.recipe--article .recipe--article-flex .recipe--article-related .recipe--article-related-item .recipe--article-related-copy p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 400;
  padding-bottom: 0;
}
.recipe--article .recipe--viewall {
  display: block;
  margin: 60px auto 0 auto;
  text-align: center;
}
@media (max-width: 47.99em) {
  .recipe--article .recipe--viewall {
    margin-top: 20px;
  }
}
.recipe--article .recipe--viewall a {
  font-family: "Jost", Arial, sans-serif;
  font-size: 28px;
  color: white;
  font-weight: 600;
  position: relative;
  padding-left: 50px;
  text-decoration: none;
}
@media (max-width: 47.99em) {
  .recipe--article .recipe--viewall a {
    font-size: 6vw;
    padding-left: 40px;
  }
}
.recipe--article .recipe--viewall a i {
  display: inline-block;
  position: absolute;
  left: 0;
  height: 100%;
}
.recipe--article .recipe--viewall a i img {
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 47.99em) {
  .recipe--article .recipe--viewall a i img {
    width: 25px;
    height: 25px;
  }
}

.recipe--products {
  background-color: #F2F2F2;
  padding: 60px 0;
}
@media (max-width: 47.99em) {
  .recipe--products {
    padding: 40px 0 0 0;
  }
}
.recipe--products h3 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 42px;
  color: #0E4A8C;
  font-weight: 300;
  margin: 0 0 30px 0;
}
@media (max-width: 47.99em) {
  .recipe--products h3 {
    font-size: 6.5vw;
  }
}
.recipe--products .recipe--products-flex {
  display: flex;
  display: -webkit-flex;
}
@media (max-width: 73.74em) {
  .recipe--products .recipe--products-flex {
    flex-wrap: wrap;
  }
}
.recipe--products .recipe--products-flex .recipe--products-item {
  display: inline-flex;
  display: -webkit-inline-flex;
  flex-direction: column;
  width: 25%;
  border-radius: 2.2vw;
  background-color: #F2F2F2;
  margin-right: 30px;
  text-decoration: none;
  container-type: inline-size;
  container-name: relatedcheese;
}
@media (max-width: 63.99em) {
  .recipe--products .recipe--products-flex .recipe--products-item {
    width: calc(50% - 10px) !important;
    margin-bottom: 20px;
  }
  .recipe--products .recipe--products-flex .recipe--products-item:nth-child(even) {
    margin-right: 0 !important;
  }
  .recipe--products .recipe--products-flex .recipe--products-item:nth-child(odd) {
    margin-right: 20px;
  }
}
.recipe--products .recipe--products-flex .recipe--products-item:last-child {
  margin-right: 0;
}
.recipe--products .recipe--products-flex .recipe--products-item .recipe--products-item-img {
  width: 100%;
  height: 85cqw;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  overflow: hidden;
}
.recipe--products .recipe--products-flex .recipe--products-item .recipe--products-item-img img, .recipe--products .recipe--products-flex .recipe--products-item .recipe--products-item-img picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.recipe--products .recipe--products-flex .recipe--products-item .recipe--products-item-copy {
  padding: 20px 0;
}
@media (max-width: 63.99em) {
  .recipe--products .recipe--products-flex .recipe--products-item .recipe--products-item-copy {
    padding: 20px 0;
  }
}
.recipe--products .recipe--products-flex .recipe--products-item .recipe--products-item-copy h5 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 30px;
  color: #0E4A8C;
  font-weight: 600;
  margin: 0 0 8px 0;
  position: relative;
  padding-right: 45px;
  text-decoration: none;
}
@media (max-width: 100.74em) {
  .recipe--products .recipe--products-flex .recipe--products-item .recipe--products-item-copy h5 {
    font-size: 7.5cqw;
    padding-right: 30px;
  }
}
@media (max-width: 47.99em) {
  .recipe--products .recipe--products-flex .recipe--products-item .recipe--products-item-copy h5 {
    font-size: 10cqw;
  }
  @supports not (container-type: inline-size) {
    .recipe--products .recipe--products-flex .recipe--products-item .recipe--products-item-copy h5 {
      font-size: 22px;
    }
  }
}
.recipe--products .recipe--products-flex .recipe--products-item .recipe--products-item-copy h5 i {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
.recipe--products .recipe--products-flex .recipe--products-item .recipe--products-item-copy h5 i img {
  display: block;
  width: 25px;
  height: 25px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 47.99em) {
  .recipe--products .recipe--products-flex .recipe--products-item .recipe--products-item-copy h5 i img {
    width: 20px;
    height: 20px;
  }
}
.recipe--products .recipe--products-flex .recipe--products-item .recipe--products-item-copy p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: #0E4A8C;
  font-weight: 400;
  text-decoration: none;
}
@media (max-width: 63.99em) {
  .recipe--products .recipe--products-flex .recipe--products-item .recipe--products-item-copy p {
    font-size: 5cqw;
  }
}
@media (max-width: 47.99em) {
  .recipe--products .recipe--products-flex .recipe--products-item .recipe--products-item-copy p {
    font-size: 7.5cqw;
  }
}

.recipe--list {
  padding-bottom: 60px;
}
.recipe--list .recipe--filter-header {
  display: none;
  justify-content: space-between;
  margin: 60px 0 40px 0;
}
@media (max-width: 47.99em) {
  .recipe--list .recipe--filter-header {
    display: flex;
    display: -webkit-flex;
  }
}
.recipe--list .recipe--filter-header .recipe--filter-header-left {
  display: inline-flex;
  display: -webkit-inline-flex;
  width: 100%;
}
.recipe--list .recipe--filter-header .recipe--filter-header-left .open--filter {
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  background-color: white;
  padding: 15px 20px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  border: 1px solid white;
  justify-content: center;
}
@media (max-width: 34.365em) {
  .recipe--list .recipe--filter-header .recipe--filter-header-left .open--filter {
    width: 49%;
  }
}
.recipe--list .recipe--filter-header .recipe--filter-header-left .open--filter:hover {
  background-color: #F2F2F2;
}
.recipe--list .recipe--filter-header .recipe--filter-header-left .open--filter.active {
  background-color: #0E4A8C;
  color: white;
}
.recipe--list .recipe--filter-header .recipe--filter-header-left .open--filter.active span {
  color: white;
}
.recipe--list .recipe--filter-header .recipe--filter-header-left .open--filter.active i {
  display: none;
}
.recipe--list .recipe--filter-header .recipe--filter-header-left .open--filter.active i.close {
  display: inline-block;
}
.recipe--list .recipe--filter-header .recipe--filter-header-left .open--filter span {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: #0E4A8C;
  font-weight: 500;
}
.recipe--list .recipe--filter-header .recipe--filter-header-left .open--filter i {
  display: inline-block;
  height: 100%;
  margin-right: 12px;
}
.recipe--list .recipe--filter-header .recipe--filter-header-left .open--filter i.close {
  display: none;
}
.recipe--list .recipe--filter-header .recipe--filter-header-left .open--filter i img {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.recipe--list .recipe--filter-header .recipe--filter-header-left .filter-spacer {
  display: inline-block;
  width: 1px;
  background-color: white;
  margin: 0 15px;
}
.recipe--list .recipe--filter-header .recipe--filter-header-left .recipe--viewall {
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  padding: 15px 35px;
  background-color: #B4A74A;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  text-decoration: none;
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 500;
  justify-content: center;
}
@media (max-width: 34.365em) {
  .recipe--list .recipe--filter-header .recipe--filter-header-left .recipe--viewall {
    width: 49%;
  }
}
.recipe--list .recipe--filter-header .recipe--filter-header-left .recipe--viewall:hover {
  background-color: #958A40;
}
.recipe--list .recipe--list-flex {
  display: flex;
  display: -webkit-flex;
  align-items: flex-start;
}
.recipe--list .recipe--list-flex .recipe--list-filter {
  display: inline-block;
  width: 20%;
  min-width: 20%;
  padding-right: 30px;
  opacity: 1;
  min-width: 280px;
}
@media (max-width: 47.99em) {
  .recipe--list .recipe--list-flex .recipe--list-filter {
    display: none;
    position: fixed;
    overflow-x: hidden;
    overflow-y: scroll;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    width: 100%;
    max-width: 100%;
    background-color: #0E4A8C;
    padding: 20px 20px 50px 20px;
  }
  .recipe--list .recipe--list-flex .recipe--list-filter.active {
    display: inline-block;
  }
}
.recipe--list .recipe--list-flex .recipe--list-filter .close--filter {
  display: none;
  align-items: center;
  background-color: #B4A74A;
  padding: 15px 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  justify-content: center;
}
@media (max-width: 47.99em) {
  .recipe--list .recipe--list-flex .recipe--list-filter .close--filter {
    display: inline-flex;
    display: -webkit-inline-flex;
  }
}
.recipe--list .recipe--list-flex .recipe--list-filter .close--filter span {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 500;
}
.recipe--list .recipe--list-flex .recipe--list-filter .close--filter i {
  display: inline-block;
  height: 100%;
  margin-right: 12px;
}
.recipe--list .recipe--list-flex .recipe--list-filter .close--filter i img {
  display: block;
  width: 100%;
  height: auto;
}
.recipe--list .recipe--list-flex .recipe--list-filter .filter-form-group {
  display: block;
  margin-bottom: 40px;
}
.recipe--list .recipe--list-flex .recipe--list-filter .filter-form-group:last-child {
  margin-bottom: 0;
}
.recipe--list .recipe--list-flex .recipe--list-filter .filter-form-group.open .filter-form-dropdown i svg .vertical {
  display: none;
}
.recipe--list .recipe--list-flex .recipe--list-filter .filter-form-group.open .filter-form-dropdown-content {
  height: auto;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
.recipe--list .recipe--list-flex .recipe--list-filter .filter-form-group .filter-form-dropdown {
  display: block;
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 500;
  text-align: left;
  padding: 20px 20px;
  border: 1px solid white;
  border-radius: 5px;
  line-height: 1;
  position: relative;
  cursor: pointer;
}
.recipe--list .recipe--list-flex .recipe--list-filter .filter-form-group .filter-form-dropdown i {
  display: inline-block;
  position: absolute;
  right: 12px;
  top: 0;
  height: 100%;
}
.recipe--list .recipe--list-flex .recipe--list-filter .filter-form-group .filter-form-dropdown i svg {
  display: block;
  width: 20px;
  height: 20px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.recipe--list .recipe--list-flex .recipe--list-filter .filter-form-group .filter-form-dropdown-content {
  height: 0;
  overflow: hidden;
  opacity: 0;
  margin-top: 20px;
  margin-left: 20px;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
.recipe--list .recipe--list-flex .recipe--list-filter .filter-form-group .filter-form-dropdown-content .filter-form-dropdown-check {
  display: block;
  width: 100%;
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 400;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: 25px;
  padding-bottom: 10px;
}
.recipe--list .recipe--list-flex .recipe--list-filter .filter-form-group .filter-form-dropdown-content .filter-form-dropdown-check:last-child {
  padding-bottom: 0;
}
.recipe--list .recipe--list-flex .recipe--list-filter .filter-form-group .filter-form-dropdown-content .filter-form-dropdown-check input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.recipe--list .recipe--list-flex .recipe--list-filter .filter-form-group .filter-form-dropdown-content .filter-form-dropdown-check .checkmark {
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 0;
}
.recipe--list .recipe--list-flex .recipe--list-filter .filter-form-group .filter-form-dropdown-content .filter-form-dropdown-check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  width: 5px;
  top: 2px;
  left: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.recipe--list .recipe--list-flex .recipe--list-filter .filter-form-group .filter-form-dropdown-content .filter-form-dropdown-check input:checked ~ .checkmark {
  background-color: #B4A74A;
}
.recipe--list .recipe--list-flex .recipe--list-filter .filter-form-group .filter-form-dropdown-content .filter-form-dropdown-check input:checked ~ .checkmark:after {
  display: block;
}
.recipe--list .recipe--list-flex .recipe--list-main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}
@media (max-width: 73.74em) {
  .recipe--list .recipe--list-flex .recipe--list-main {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}
@media (max-width: 63.99em) {
  .recipe--list .recipe--list-flex .recipe--list-main {
    grid-template-columns: repeat(2, 1fr);
  }
}
.recipe--list .recipe--list-flex .recipe--list-main .recipe--list-item {
  display: inline-block;
  text-decoration: none;
}
.recipe--list .recipe--list-flex .recipe--list-main .recipe--list-item .recipe--list-item-img {
  display: block;
  width: 100%;
  container: card/size;
  height: 15cqw;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 47.99em) {
  .recipe--list .recipe--list-flex .recipe--list-main .recipe--list-item .recipe--list-item-img {
    height: 25cqw;
  }
}
.recipe--list .recipe--list-flex .recipe--list-main .recipe--list-item .recipe--list-item-img img, .recipe--list .recipe--list-flex .recipe--list-main .recipe--list-item .recipe--list-item-img picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.recipe--list .recipe--list-flex .recipe--list-main .recipe--list-item .recipe--list-item-copy {
  display: block;
  width: 100%;
  margin-top: 20px;
}
.recipe--list .recipe--list-flex .recipe--list-main .recipe--list-item .recipe--list-item-copy h2 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 28px;
  color: white;
  font-weight: 600;
  margin: 0 0 8px 0;
  text-decoration: none;
  position: relative;
  padding-right: 35px;
  font-size: 1.5cqw;
}
@media (max-width: 76.615em) {
  .recipe--list .recipe--list-flex .recipe--list-main .recipe--list-item .recipe--list-item-copy h2 {
    font-size: 1.7cqw;
  }
}
@media (max-width: 63.99em) {
  .recipe--list .recipe--list-flex .recipe--list-main .recipe--list-item .recipe--list-item-copy h2 {
    font-size: 2.2cqw;
  }
}
@media (max-width: 47.99em) {
  .recipe--list .recipe--list-flex .recipe--list-main .recipe--list-item .recipe--list-item-copy h2 {
    font-size: 4.5cqw;
  }
  @supports not (container-type: inline-size) {
    .recipe--list .recipe--list-flex .recipe--list-main .recipe--list-item .recipe--list-item-copy h2 {
      font-size: 20px;
    }
  }
}
.recipe--list .recipe--list-flex .recipe--list-main .recipe--list-item .recipe--list-item-copy h2 i {
  display: inline-block;
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
}
.recipe--list .recipe--list-flex .recipe--list-main .recipe--list-item .recipe--list-item-copy h2 i img {
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 47.99em) {
  .recipe--list .recipe--list-flex .recipe--list-main .recipe--list-item .recipe--list-item-copy h2 i img {
    width: 25px;
    height: 25px;
  }
}
.recipe--list .recipe--list-flex .recipe--list-main .recipe--list-item .recipe--list-item-copy p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 400;
  padding: 0;
  text-decoration: none;
}
@media (max-width: 47.99em) {
  .recipe--list .recipe--list-flex .recipe--list-main .recipe--list-item .recipe--list-item-copy p {
    font-size: 14px;
  }
}
.recipe--list .load--more {
  display: block;
  margin: 60px auto;
  text-align: center;
}
@media (max-width: 47.99em) {
  .recipe--list .load--more {
    margin-top: 20px;
  }
}
.recipe--list .load--more a {
  font-family: "Jost", Arial, sans-serif;
  font-size: 28px;
  color: white;
  font-weight: 600;
  position: relative;
  padding-left: 50px;
  text-decoration: none;
}
@media (max-width: 47.99em) {
  .recipe--list .load--more a {
    font-size: 6vw;
  }
}
.recipe--list .load--more a i {
  display: inline-block;
  position: absolute;
  left: 0;
  height: 100%;
}
.recipe--list .load--more a i img {
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.latest--news-section {
  background-color: #F2F2F2;
  padding: 100px 0;
}
@media (max-width: 47.99em) {
  .latest--news-section {
    padding: 40px 0;
  }
}
.latest--news-section h2 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 72px;
  color: #0E4A8C;
  font-weight: 300;
  margin: 0;
}
@media (max-width: 76.615em) {
  .latest--news-section h2 {
    font-size: 5.5vw;
  }
}
@media (max-width: 47.99em) {
  .latest--news-section h2 {
    font-size: 8vw;
  }
}
.latest--news-section .latest--news-flex {
  display: flex;
  display: -webkit-flex;
  margin-top: -6vw;
}
@media screen and (max-width: 920px) {
  .latest--news-section .latest--news-flex {
    width: calc(100% - 60px);
    margin: 30px auto 0 auto;
  }
}
.latest--news-section .latest--news-flex .latest--news-item {
  display: inline-block;
  width: calc(33.3333333333% - 14px);
  text-decoration: none;
  container-type: inline-size;
  container-name: latestNews;
  margin-right: 20px;
}
@media screen and (max-width: 920px) {
  .latest--news-section .latest--news-flex .latest--news-item {
    width: calc(50% - 20px);
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.latest--news-section .latest--news-flex .latest--news-item:nth-child(1) {
  margin-top: calc(6vw + 40px);
}
@media screen and (max-width: 920px) {
  .latest--news-section .latest--news-flex .latest--news-item:nth-child(1) {
    margin-top: 0;
  }
}
.latest--news-section .latest--news-flex .latest--news-item.-recipe {
  margin-right: 0;
  margin-top: 6vw;
}
@media screen and (max-width: 920px) {
  .latest--news-section .latest--news-flex .latest--news-item.-recipe {
    margin-top: 0;
  }
}
.latest--news-section .latest--news-flex .latest--news-item.-recipe .latest--news-item-copy {
  background-color: #0E4A8C;
}
.latest--news-section .latest--news-flex .latest--news-item.-recipe .latest--news-item-copy h3 {
  color: white;
}
.latest--news-section .latest--news-flex .latest--news-item.-recipe .latest--news-item-copy .latest--news-item-read p {
  color: white;
}
.latest--news-section .latest--news-flex .latest--news-item .latest--news-item-copy {
  background-color: white;
  padding: 40px;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  position: relative;
}
@media (max-width: 76.615em) {
  .latest--news-section .latest--news-flex .latest--news-item .latest--news-item-copy {
    padding: 30px;
  }
}
@media (max-width: 63.99em) {
  .latest--news-section .latest--news-flex .latest--news-item .latest--news-item-copy {
    padding: 20px;
  }
}
@media screen and (max-width: 920px) {
  .latest--news-section .latest--news-flex .latest--news-item .latest--news-item-copy {
    height: calc(100% - 70cqw);
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    padding-bottom: 40px;
  }
}
.latest--news-section .latest--news-flex .latest--news-item .latest--news-item-copy p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: #B4A74A;
  font-weight: 400;
  padding: 0;
}
.latest--news-section .latest--news-flex .latest--news-item .latest--news-item-copy h3 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 28px;
  color: #0E4A8C;
  font-weight: 600;
  margin: 20px 0 0 0;
}
.latest--news-section .latest--news-flex .latest--news-item .latest--news-item-copy .latest--news-item-read {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
@media screen and (max-width: 920px) {
  .latest--news-section .latest--news-flex .latest--news-item .latest--news-item-copy .latest--news-item-read {
    position: absolute;
    bottom: 20px;
    width: calc(100% - 40px);
    margin: 0 auto;
  }
}
.latest--news-section .latest--news-flex .latest--news-item .latest--news-item-copy .latest--news-item-read p {
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  color: #0E4A8C;
  font-weight: 400;
  padding: 0 30px 0 0;
  position: relative;
}
.latest--news-section .latest--news-flex .latest--news-item .latest--news-item-copy .latest--news-item-read p i {
  display: inline-block;
  width: 20px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}
.latest--news-section .latest--news-flex .latest--news-item .latest--news-item-copy .latest--news-item-read p i img {
  display: block;
  width: 20px;
  height: 20px;
}
.latest--news-section .latest--news-flex .latest--news-item .latest--news-item-img {
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 70cqw;
}
.latest--news-section .latest--news-flex .latest--news-item .latest--news-item-img img, .latest--news-section .latest--news-flex .latest--news-item .latest--news-item-img picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.basic--banner.-policy {
  padding-bottom: 10px;
}
.basic--banner.-policy .basic--banner-copy {
  align-items: flex-start;
  text-align: left;
  margin-bottom: 0;
}

.policy--body {
  padding: 50px 0;
}
.policy--body h2 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 26px;
  color: #0E4A8C;
  font-weight: 600;
  margin: 0 0 20px 0;
}
.policy--body h3 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 22px;
  color: #0E4A8C;
  font-weight: 600;
  margin: 0 0 20px 0;
}
.policy--body h4 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 30px;
  color: #0E4A8C;
  font-weight: 600;
  margin: 0 0 20px 0;
}
.policy--body h5 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 26px;
  color: #0E4A8C;
  font-weight: 600;
  margin: 0 0 20px 0;
}
.policy--body h6 {
  font-family: "Jost", Arial, sans-serif;
  font-size: 20px;
  color: #0E4A8C;
  font-weight: 600;
  margin: 0 0 20px 0;
}
.policy--body p, .policy--body li {
  font-family: "Jost", Arial, sans-serif;
  font-size: 18px;
  color: #0E4A8C;
  font-weight: 400;
}
@media (max-width: 47.99em) {
  .policy--body p, .policy--body li {
    font-size: 16px;
  }
}
.policy--body a {
  text-decoration: underline;
  color: #B4A74A;
}
.policy--body li {
  padding-bottom: 10px;
}
.policy--body ol {
  padding-left: 20px;
  padding-bottom: 10px;
}
.policy--body ul {
  padding-left: 20px;
  list-style: disc;
  padding-bottom: 10px;
}
.policy--body blockquote {
  display: block;
  border: 1px solid #0E4A8C;
  border-top-right-radius: 2.2vw;
  border-top-left-radius: 2.2vw;
  border-bottom-right-radius: 2.2vw;
  border-bottom-left-radius: 2.2vw;
  padding: 30px;
  margin-bottom: 40px;
}