.home--regions {
    @include blue-grad;
    padding: 100px 0 0 0;
    margin-bottom: 100px;

    @include mq($until: laptop-m) {
        padding-top: 50px;
    }

    @include mq($until: tablet) {
        padding-top: 30px;
        margin-bottom: 30px;
    }

    h2 {
        @include font(80px, white);
        @include font-weight(light);
        margin: 0 0 70px 0;
        line-height: 1;

        @media screen and (max-width: 1580px) {
            font-size: 4.5cqw;
        }

        @include mq($until: laptop-m) {
            margin-bottom: 30px;
        }

        @include mq($until: tablet) {
            font-size: 44px;
        }
        @include mq($until: mobile-l) {
            font-size: 9.5cqw;
        }

    }

    .home--regions-flex {
        @include flex;
        justify-content: space-between;

        @include mq($until: tablet) {
            flex-wrap: wrap;
        }
        
        .home--regions-content {
            display: inline-block;
            width: 46%;

            @include mq($until: laptop-m) {
                width: 50%;
            }

            .home--region-bg {
                display: none;

                @include mq($until: tablet) {
                    display: none;
                    position: fixed;
                    top: 0;
                    left: 0;
                    z-index: 99;
                    width: 100vw;
                    height: 100vh;
                    background-color: rgba(0,0,0,0.75);

                    &.active {
                        display: block;
                    }

                }

            }

            .home--regions-region {
                display: none;
                flex-direction: column;
                height: 100%;

                @include mq($until: tablet) {
                    display: none;
                    position: fixed;
                    background-color: $blue;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: 90%;
                    height: 95%;
                    z-index: 100;
                    overflow-x: hidden;
                    overflow-y: scroll;
                    padding: 20px 20px 0 20px;
                    @include radius-top;
                    @include radius-bottom;
                }

                &.active {
                    display: block;
                }

                .scroll-container {
                    @include flex;
                    flex-direction: column;
                    height: 100%;

                    @include mq($until: tablet) {
                        margin: 0 auto;
                        min-height: 100%;
                        height: auto;
                        overflow: auto;
                    }

                    h3 {
                        @include font(34px, white);
                        @include font-weight(semibold);
                        margin: 0 0 5px 0;
                        position: relative;
                        // padding: 0 0 0 50px;
                        padding: 0;
    
                        @include mq($until: tablet) {
                            padding-left: 50px;
                        }
    
                        span {
                            // display: inline-block;
                            position: absolute;
                            left: 0;
                            height: 100%;
                            display: none;
    
                            @include mq($until: tablet) {
                                display: none;
                            }
    
                            img {
                                display: block;
                                width: 100%;
                                height: auto;
                                @include valign;
                            }
                        
                        }
    
                        .map-close {
                            display: none;
    
                            @include mq($until: tablet) {
                                display: inline-block;
                                transform: rotate(45deg)
                            }
    
                        }
    
                    }
    
                    h5 {
                        @include font(18px, $gold);
                        @include font-weight(regular);
                        margin: 0 0 20px 0;
                    }
    
                    .home--regions-region-flex {
                        @include flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        margin-bottom: 40px;
    
                        @media screen and (max-width:1000px) {
                            margin-bottom: 30px;
                        }
    
                        @include mq($until: tablet) {
                            flex-wrap: wrap;
                            display: block;
                        }
    
                        .home--regions-region-copy {
                            display: inline-block;
                            width: 60%;
    
                            @media screen and (max-width:1000px) {
                                width: 100%;
                            }
    
                            p {
                                @include font(18px, white);
                                @include font-weight(regular);
    
                                &:last-child, &:only-child {
                                    padding-bottom: 0;
                                }
    
                            }
    
                        }
    
                        .home--regions-region-links {
                            display: inline-block;
    
                            @media screen and (max-width:1000px) {
                                display: none;
                            }
    
                            @include mq($until: tablet) {
                                display: block;
                                width: 100%;
                                margin-top: 30px;
                            }
    
                            p {
                                @include font(16px, white);
                                @include font-weight(regular);
                            }
    
                            ul {
                                li {
                                    display: block;
                                    padding-bottom: 10px;
    
                                    &:last-child {
                                        padding-bottom: 0;
                                    }
    
                                    a {
                                        display: block;
                                        @include font(18px, $light-blue);
                                        @include font-weight(medium);
                                        text-decoration: underline;
                                    }
    
                                }
                            }
    
                        }
    
                    }
    
                    .home--regions-region-video {
                        display: block;
                        width: 100%;
                        @include radius-top;
                        position: relative;
                        overflow: hidden;
                        margin-top: auto;
                        align-self: flex-end;
    
                        img, video {
                            display: block;
                            width: 100%;
                            height: auto;
                            object-fit: cover;
                        }
    
                    }

                }

            }

        }

        .home--regions-map {
            display: inline-block;
            padding-bottom: 80px;

            @include mq($until: tablet) {
                width: 100%;
                padding-bottom: 20px;
            }

        }

    }

}