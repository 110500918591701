.cheese--banner {
    &.full {
        background: none;

        .cheese--banner-flex {
            .cheese--banner-copy {

                h1 {
                    font-family: $relation;
                    font-size: 90px;
                    color: $gold;
                    font-weight: normal;
                    line-height: 1;
                    margin: 0;

                    @include mq($until: mobile-l) {
                        font-size: 18vw;
                    }

                }

                h2 {
                    @include font(38px, white);
                    @include font-weight(light);
                    margin: 10px 0 30px 0;

                    @include mq($until: mobile-l) {
                        font-size: 6vw;
                    }                    

                }

            }
        }

    }
}

.butter--list {

    .butter--list-flex {
        @include flex;
        flex-wrap: wrap;

        .butter--item {
            display: inline-block;
            width: calc(50% - 18px);
            text-decoration: none;
            margin-bottom: 80px;
            container-type: inline-size;
            container-name: buttercard;

            @include mq($until: tablet) {
                margin-bottom: 40px;
                width: calc(50% - 10px);
            }

            &:nth-child(odd) {
                margin-right: 35px;

                @include mq($until: tablet) {
                    margin-right: 20px;
                }

            }

            .butter--item-img {
                display: block;
                width: 100%;
                position: relative;
                overflow: hidden;
                @include radius-left;
                @include radius-right;
                margin-bottom: 20px;

                img, picture {
                    display: block;
                    width: 100%;
                    height: auto;
                    aspect-ratio: 3/2;
                    object-fit: cover;
                }

                .butter--stamp {
                    display: block;
                    position: absolute;
                    bottom: 20px;
                    right: 20px;
                    width: 22%;

                    img, picture {
                        display: block;
                        width: 100%;
                        height: auto;
                        object-fit: fill;
                        aspect-ratio: 1;
                    }

                }

            }

            .butter--item-copy {
                display: block;
                width: 100%;

                h2 {
                    @include font(30px, white);
                    @include font-weight(semibold);
                    margin: 0;
                    position: relative;
                    text-decoration: none;
                    padding-right: 40px;
                    margin-bottom: 20px;

                    @include mq($until: tablet) {
                        font-size: 9cqw;
                        padding-right: 40px;

                        @supports not (container-type: inline-size) {
                            font-size: 24px;
                        }

                    }

                    @include mq($until: mobile-l) {
                        font-size: 14cqw;
                        padding-right: 20px;
                    }

                    i {
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        top: 8px;
                        height: 100%;
            
                        img {
                            display: block;
                            width: 30px;
                            height: 30px;

                            @include mq($until: mobile-l) {
                                width: 18px;
                                height: 18px;
                            }

                        }
                    
                    }

                }

                p {
                    @include font(16px, white);
                    @include font-weight(regular);
                    text-decoration: none;

                    @include mq($until: tablet) {
                        font-size: 8cqw;
                    }

                }

            }

        }

    }

}

.section--image {
    display: block;
    width: 100%;

    img, picture {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}