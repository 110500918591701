.cheese--banner.brand {
    padding-bottom: 12vw;

    .cheese--banner-flex {

        .cheese--banner-copy {
            position: relative;

            h1, h2 {
                @media screen and (max-width:920px) {
                    padding-right: 200px;
                    display: block;
                }

                @include mq($until: tablet) {
                    padding-right: 160px;
                }
            }

            .brand-logo-mob {
                display: none;

                @media screen and (max-width:920px) {
                    display: inline-block;
                    position: absolute;
                    top:0;
                    right: 20px;
                    width: 100%;
                    max-width: 180px;
                    background-color: white;
                    @include radius-bottom;
                    @include radius-top;
                    padding: 10px 20px;

                    img, picture {
                        display: block;
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                    }

                }

                @include mq($until: mobile-l) {
                    max-width: 125px;
                }

            }
        }

        .cheese--banner-img {
            position: relative;

            @media screen and (max-width:920px) {
                display: none;
            }

            .brand-logo {
                display: block;
                max-width: 440px;
                width: calc(100% - 40px);
                height: 220px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                right: 0;
                margin: 0 auto;
                background-color: white;
                @include radius-top;
                @include radius-bottom;
                text-align: center;

                // @include mq($until: laptop-s) {
                //     width: calc(100% - 40px);
                //     left: 20px;
                //     height: 45%;
                // }

                @include mq($until: tablet) {
                    display: block;
                    position: static;
                    transform: translateY(0);
                    top: 0;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    padding: 10px 0;
                    width: 100%;
                    height: 100%;
                }

                img, picture {
                    display: block;
                    width: auto;
                    height: 90%;
                    margin: 0 auto;
                    @include valign;

                    @include mq($until: tablet) {
                        display: block;
                        transform: translateY(0);
                        top: auto;
                        width: auto;
                        height: 100%;
                        object-fit: cover;
                    }

                }

            }

        }

    }

    .brand--banner-copy {
        display: block;
        text-align: center;
        margin: 80px auto 0 auto;
        padding: 0 40px;

        @include mq($until: tablet) {
            margin: 40px auto 30px auto;
        }

        h3 {
            @include font(36px, white);
            @include font-weight(light);
            margin: 0;
            text-align: center;

            @include mq($until: mobile-l) {
                font-size: 7vw;
            }

        }

    }
    
}

.brand--related {
    margin-top: -8vw;
    padding-bottom: 80px;

    @include mq($until:tablet) {
        padding: 0 0 40px 0;
    }

    .brand--related-flex {
        @include flex;

        @include mq($until: tablet) {
            width: calc(100% - 60px);
            margin: 0 auto;
        }

        .cheese--related-item {
            display: inline-flex;
            display: -webkit-inline-flex;
            flex-direction: column;
            width: 25%;
            border-radius: 2.2vw;
            background-color: white;
            margin-right: 30px;
            text-decoration: none;
            container-type: inline-size;
            container-name: brandcard;

            &:last-child {
                margin-right: 0;
            }

            .cheese--related-item-img {
                width: 100%;
                // height: 100%;
                // max-height: 300px;
                height: 100cqw;
                @include radius-top;
                @include radius-bottom;
                overflow: hidden;

                img, picture {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

            }

            .cheese--related-item-copy {
                padding: 20px 30px;

                @include mq($until: laptop-s) {
                    padding: 20px 0;
                }

                h5 {
                    @include font(30px, $blue);
                    @include font-weight(semibold);
                    margin: 0 0 8px 0;
                    position: relative;
                    padding-right: 30px;
                    text-decoration: none;

                    @include mq($until: desktop) {
                        font-size: 7.5cqw;
                        padding-right: 30px;
                    }

                    @include mq($until: tablet) {
                        font-size: 10cqw;
                        padding-right: 25px;
                    }
                    
                    i {
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        top: 0;
                        height: 100%;
    
                        img {
                            display: block;
                            width: 25px;
                            height: 25px;
                            @include valign;

                            @include mq($until: tablet) {
                                width: 20px;
                                height: 20px;
                            }

                        }
                    
                    }

                }

                p {
                    @include font(16px, $blue);
                    @include font-weight(regular);
                    text-decoration: none;

                    @include mq($until: desktop) {
                        font-size: 6cqw;
                    }

                    @include mq($until: laptop-s) {
                        font-size: 5cqw;
                    }

                    @include mq($until: tablet) {
                        font-size: 7.5cqw;
                    }

                }

            }

        }

        &.three-col {

            @include mq($until: laptop-s) {
                flex-wrap: nowrap;
            }

            @include mq($until: tablet) {
                flex-wrap: wrap;
            }

            .cheese--related-item {
                width: calc(100% / 3);
                
                @include mq($until: laptop-s) {
                    margin-right: 20px;

                    &:last-child {
                        margin-right: 0;
                    }

                }

                @include mq($until: tablet) {
                    width: calc(50% - 10px);

                    &:nth-child(2) {
                        margin-right: 0;
                    }

                }

                .cheese--related-item-img {
                    height: 80cqw;
                }

            }

        }

        &.two-col {

            @include mq($until: laptop-s) {
                flex-wrap: nowrap;
            }

            @media screen and (max-width:650px) {
                flex-wrap: wrap;
            }

            .cheese--related-item {
                width: 50%;

                @media screen and (max-width:650px) {
                    width: 100%;
                    margin-right: 0;
                }

                .cheese--related-item-img {
                    height: 50cqw;
                }

                .cheese--related-item-copy {

                    @include mq($until: desktop) {
                        h5 {
                            font-size: 30px;
                        }
                        p {
                            font-size: 16px;
                        }
                    }

                }

            }

        }

        &.one-col {
            width: 100% !important;

            div {
                &:first-child {
                    width: 100%;
                }
            }

            .no-swiper {
                @include flex;
                width: 100%;
            }

            .cheese--related-item {
                width: 50%;

                h5 {
                    @include mq($until: desktop) {
                        font-size: 30px;
                    }
                }

                p {
                    font-size: 16px !important;
                }

                @include mq($until: laptop-m) {
                    width: 75%;
                }

                @include mq($until: tablet) {
                    width: 50%;
                }

                @media screen and (max-width:600px) {
                    width: 100%;
                    margin-right: 0;
                }

                .cheese--related-item-img {
                    height: 40cqw;
                }

            }

            .product--content-related-filler {

                @include mq($until: laptop-m) {
                    width: 25%;
                }

                @include mq($until: tablet) {
                    width: 50%;
                }

                @media screen and (max-width:600px) {
                    display: none;
                }

            }

        }

    }

}

.brand--section-img {
    display: block;
    width: 100%;

    img, picture {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

}

.region--list.brand {

    .region--list-cont {

        .region--list-flex {

            @include mq($until: tablet) {
                flex-wrap: wrap;
                flex-direction: row !important;
                margin-bottom: 40px;
            }

            &:nth-child(even) {
                .region--item-img {
                    @include mq($until: tablet) {
                        @include radius-right;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                    a {
                        .brand-logo {
                            right: 60px;
                            left: auto;

                            @include mq($until: laptop-s) {
                                right: 20px;
                            }

                        }
                    }
                }
            }

            .region--item-img {

                @include mq($until: laptop-s) {
                    width: 50%;
                }

                @include mq($until: tablet) {
                    margin-bottom: 0;
                    height: 25vw;
                }

                @include mq($until: tablet) {
                    img, picture {
                        display: none;
                    }
                }

                a {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 100%;

                    .brand-logo {
                        display: block;
                        width: 440px;
                        height: 220px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 60px;
                        background-color: white;
                        @include radius-top;
                        @include radius-bottom;
                        text-align: center;

                        @include mq($until: laptop-s) {
                            width: calc(100% - 40px);
                            left: 20px;
                            height: 45%;
                        }

                        @include mq($until: tablet) {
                            display: block;
                            position: static;
                            transform: translateY(0);
                            top: 0;
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                            padding: 10px 0;
                            width: 100%;
                            height: 100%;
                        }

                        img, picture {
                            display: block;
                            width: auto;
                            height: 90%;
                            margin: 0 auto;
                            @include valign;

                            @include mq($until: tablet) {
                                display: block;
                                transform: translateY(0);
                                top: auto;
                                width: auto;
                                height: 100%;
                                object-fit: cover;
                            }

                        }

                    }

                }
                
            }

            .region--item-copy {
                @include mq($until: laptop-s) {
                    width: 50%;
                }
                @include mq($until: tablet) {
                    width: 100%;
                }
            }

        }

    }

}

.brand--content {
    background-color: $bg-grey;
    padding: 100px 0;

    @include mq($until: tablet) {
        padding: 60px 0;
    }

    h3 {
        @include font(44px, $blue);
        @include font-weight(light);
        text-align: center;
        display: block;
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;

        @include mq($until: tablet) {
            font-size: 6vw;
        }

    }

    .brand--content-blocks {
        @include flex;
        margin-top: 100px;
        padding-top: 9vw;

        @include mq($until: tablet) {
            flex-wrap: wrap;
            margin-top: 60px;
            padding-top: 0;
        }

        .brand--block {
            display: inline-block;
            width: 50%;
            container-type: inline-size;
            container-name: brandBlock;

            @include mq($until: tablet) {
                width: 100%;
                margin: 0 0 30px 0 !important;
            }
    
            &:first-child {
                margin-right: 40px;
                margin-top: -9vw;

                @include mq($until: laptop-s) {
                    margin-right: 20px;
                }

            }

            .brand--block-img {
                display: block;
                @include radius-top;
                position: relative;
                overflow: hidden;
                height: 55cqw;

                img, picture {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

            }

            .brand--block-copy {
                display: block;
                background-color: white;
                padding: 40px;
                @include radius-bottom;
                @include font(18px, $blue);
                @include font-weight(regular);

                @include mq($until: laptop-s) {
                    padding: 25px;
                }

                p, li {
                    @include font(18px, $blue);
                    @include font-weight(regular);
                }

                a {
                    color: $gold;
                    text-decoration: underline;
                    @include font-weight(semibold);
                }

            }

        }

    }

    .brand--viewall {
        display: block;
        margin: 100px auto 0 auto;
        text-align: center;

        @include mq($until:tablet) {
            margin-top: 40px;
        }

        a {
            @include font(28px, $blue);
            @include font-weight(semibold);
            position: relative;
            padding-left: 50px;
            text-decoration: none;

            @include mq($until:tablet)  {
                font-size: 6vw;
                padding-left: 40px;
            }

            i {
                display: inline-block;
                position: absolute;
                left: 0;
                height: 100%;
    
                img {
                    display: block;
                    width: 30px;
                    height: 30px;
                    @include valign;

                    @include mq($until:tablet) {
                        width: 25px;
                        height: 25px;
                    }

                }
            
            }

        }
    }

}