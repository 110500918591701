.cheese--related {
    background-color: $bg-grey;
    padding: 60px 0;

    @include mq($until:tablet) {
        padding: 40px 0 40px 0;
    }

    .cheese--related-flex {
        margin-bottom: 60px;

        @include mq($until: laptop-m) {
            width: calc(100% - 20px);
            margin: 0 auto 60px auto;
        }

        @include mq($until: tablet) {
            width: calc(100% - 60px);
            margin-bottom: 40px;
        }

        .cheese--related-item {
            display: inline-flex;
            display: -webkit-inline-flex;
            justify-content: space-between;
            flex-direction: column;
            width: 25%;
            border-radius: 2.2vw;
            background-color: white;
            margin-right: 30px;
            text-decoration: none;
            container-type: inline-size;
            container-name: cheesecard;
            height: auto;

            @include mq($until: laptop-s) {
                width: calc(50% - 10px);

                &:nth-child(odd) {
                    margin-right: 20px;
                }
                &:nth-child(even) {
                    margin-right: 0;
                }

            }

            &:last-child {
                margin-right: 0;
            }

            &.-recipe {
                background-color: $blue;

                .cheese--related-item-copy {
                    h5, p {
                        color: white;
                    }
                }

            }

            .cheese--related-item-copy {
                padding: 30px 30px;

                @include mq($until: laptop-s) {
                    padding: 20px;
                }

                p.item-title {
                    @include font(18px, $gold);
                    @include font-weight(regular);
                    margin: 0;
                    padding: 0;
                    text-decoration: none;

                    @include mq($until: laptop-s) {
                        font-size: 5cqw;
                    }

                }

                h5 {
                    @include font(30px, $blue);
                    @include font-weight(semibold);
                    margin: 8px 0 8px 0;
                    position: relative;
                    padding-right: 45px;
                    text-decoration: none;

                    @include mq($until: desktop) {
                        font-size: 7.5cqw;
                        padding-right: 30px;
                    }

                    @include mq($until: laptop-m) {
                        font-size: 8cqw;
                    }
                    
                    i {
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        top: 0;
                        height: 100%;
    
                        img {
                            display: block;
                            width: 25px;
                            height: 25px;
                            @include valign;
                        }
                    
                    }

                }

                p {
                    @include font(16px, $blue);
                    @include font-weight(regular);
                    text-decoration: none;

                    @include mq($until: laptop-s) {
                        font-size: 5cqw;
                    }

                }

            }

            .cheese--related-item-img {
                width: 100%;
                // height: 100%;
                height: 85cqw;
                @include radius-bottom;
                position: relative;
                overflow: hidden;

                img, picture {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .butter--stamp {
                    display: block;
                    position: absolute;
                    bottom: 20px;
                    right: 20px;
                    width: 22%;
    
                    img, picture {
                        display: block;
                        width: 100%;
                        height: auto;
                        object-fit: fill;
                        aspect-ratio: 1;
                    }
    
                }

            }

        }

        .cheese-related-swiper-2 {
            .cheese--related-item {
                width: 50%;

                .cheese--related-item-img {
                    height: 50cqw;
                }

                .cheese--related-item-copy {

                    @include mq($until: desktop) {
                        h5 {
                            font-size: 30px;
                        }
                        p {
                            font-size: 16px;
                        }
                    }

                }

            }
        }

        .no-swiper {
            @include flex;
            width: 100%;

            .cheese--related-item {
                @media screen and (max-width:600px) {
                    width: 100%;
                    margin-right: 0;
                }
            }

        }

    }

    .cheese--viewall {
        display: block;
        margin: 0 auto;
        text-align: center;

        a {
            @include font(28px, $blue);
            @include font-weight(semibold);
            position: relative;
            padding-left: 50px;
            text-decoration: none;

            @include mq($until:tablet)  {
                font-size: 6vw;
                padding-left: 40px;
            }

            i {
                display: inline-block;
                position: absolute;
                left: 0;
                height: 100%;
    
                img {
                    display: block;
                    width: 30px;
                    height: 30px;
                    @include valign;

                    @include mq($until:tablet) {
                        width: 25px;
                        height: 25px;
                    }

                }
            
            }

        }

    }

}