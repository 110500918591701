.news--article {
    @include blue-grad;
    padding-top: 250px;
    padding-bottom: 60px;

    @media screen and (max-width:920px) {
        padding-top: 120px;
    }

    .news--article-flex {
        @include flex;
        align-items: flex-start;

        @include mq($until: tablet) {
            flex-wrap: wrap;
        }

        .news--article-copy {
            display: inline-block;
            width: 55%;
            padding-right: 70px;
            padding-bottom: 50px;

            @include mq($until: laptop-m) {
                padding-right: 30px;
                width: 60%;
            }

            @include mq($until: tablet) {
                width: 100%;
                padding-right: 0;
                padding-bottom: 30px;
                order: 2;
            }

            h1 {
                @include font(44px, white);
                @include font-weight(light);
                margin: 0 0 40px 0;

                @include mq($until: mobile-l) {
                    font-size: 8vw;
                }

            }

            .date {
                @include font(16px, white);
                @include font-weight(light);
                font-style: italic;
                padding-bottom: 40px;
            }

            h2 {
                @include font(36px, white);
                @include font-weight(semibold);
                margin: 0 0 20px 0;
            }

            h3 {
                @include font(32px, white);
                @include font-weight(semibold);
                margin: 0 0 20px 0;
            }

            h4 {
                @include font(30px, white);
                @include font-weight(semibold);
                margin: 0 0 20px 0;
            }

            h5 {
                @include font(26px, white);
                @include font-weight(semibold);
                margin: 0 0 20px 0;
            }

            h6 {
                @include font(20px, white);
                @include font-weight(semibold);
                margin: 0 0 20px 0;
            }

            p, li {
                @include font(18px, white);
                @include font-weight(regular);
            }

            a {
                text-decoration: underline;
                color: $gold;
            }

            li {
                padding-bottom: 10px;
            }

            ol {
                padding-left: 20px;
                padding-bottom: 10px;
            }

            ul {
                padding-left: 20px;
                list-style: disc;
                padding-bottom: 10px;
            }

            blockquote {
                display: block;
                border: 1px solid white;
                @include radius-top;
                @include radius-bottom;
                padding: 30px;
                margin-bottom: 40px;
            }

        }

        .news--article-images {
            display: inline-block;
            width: 45%;
            position: relative;

            @include mq($until: laptop-m) {
                width: 40%;
            }

            @include mq($until: tablet) {
                width: 100%;
                margin: 0 auto 30px auto;
                order: 1;
            }

            .swiper {
                width: 100%;
            }

            .no-swiper {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 30px;
                grid-row-gap: 30px;
                width: 100%;

                @include mq($until:tablet) {
                    @include flex;
                    grid-column-gap: 0;
                    grid-row-gap: 0;
                }

            }

            .news-img {
                display: inline-block;
                @include radius-bottom;
                @include radius-top;
                position: relative;
                overflow: hidden;
                
                @include mq($until: tablet) {
                    height: auto;
                }

                &:nth-child(3n+1) {
                    width: 100%;
                    grid-column: span 2;
                }

                img, picture {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

            }

        }

    }

    .news--back {
        display: inline-block;
        background-color: $gold;
        color: white;
        text-decoration: none;
        text-align: center;
        padding: 18px 30px 18px 45px;
        @include radius-bottom;
        @include radius-top;
        position: relative;
        @include animate;
        line-height: 1;

        &:hover {
            background-color: $dark-gold;
        }
        
        &:before {
            display: inline-block;
            content: '';
            background-image: url('../static/icon-arrow-basic.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 10px 16px;
            width: 30px;
            height: 16px;
            position: absolute;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
        }

    }

}

.cheese--related.-news {
    .cheese--related-flex {
        .cheese--related-item {
            justify-content: flex-start;
            position: relative;

            .cheese--related-item-img {
                @include radius-top;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                height: 70cqw;
            }

            .cheese--related-item-copy {

                h5 {
                    padding-right: 0;
                }

            }

        }
    }

    .news--viewall {
        display: block;
        margin: 60px auto 0 auto;
        text-align: center;

        @include mq($until:tablet) {
            margin-top: 40px;
        }

        a {
            @include font(28px, $blue);
            @include font-weight(semibold);
            position: relative;
            padding-left: 50px;
            text-decoration: none;

            @include mq($until:tablet)  {
                font-size: 6vw;
                padding-left: 40px;
            }

            i {
                display: inline-block;
                position: absolute;
                left: 0;
                height: 100%;
    
                img {
                    display: block;
                    width: 30px;
                    height: 30px;
                    @include valign;

                    @include mq($until:tablet) {
                        width: 25px;
                        height: 25px;
                    }

                }
            
            }

        }
    }

}