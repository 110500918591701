.home--regions-map {
    width: 46%;

    svg#regionMap {
        display: block;
        width: 100%;
        height: auto;

        .region {
            cursor: pointer;
            @include animate;

            &:hover {
                .region-bg {
                    fill: $gold;
                }
            }

            &.active {
                .region-bg {
                    fill: $gold;
                }
                .region-button {
                    .button-bg {
                        fill: #D3C459;
                    }
                    .vertical {
                        opacity: 0;
                    }

                }
            }

        }

        .blue-bg {
            fill: #4F85B2;
        }
    
        .region-bg {
            fill: #80c0e7;
        }
    
        .region-border {
            fill: #96d8ff;
        }
    
        .main-border {
            fill: #fff;
        }
    
        .active-button {
            fill: #d3c459;
        }
    
        .button-bg {
            fill: #0e4a8b;
        }

    }
}