.main--products {
    background-color: $bg-grey;
    padding: 80px 0;

    @include mq($until: tablet) {
        padding: 40px 0 0 0;
    }

    .main--products-flex {
        @include flex;

        @include mq($until: tablet) {
            width: calc(100% - 60px);
            margin: 0 auto;
        }

        .main--products-product {
            display: inline-block;
            text-decoration: none;
            margin-right: 20px;
            width: calc(100% / 3);

            @include mq($until: tablet) {
                width: calc(50% - 10px);
                margin-bottom: 30px;

                &:nth-child(2) {
                    margin-right: 0;
                }

            }

            &:last-child {
                margin-right: 0;
            }

            .main--products-product-img {
                display: block;
                width: 100%;
                position: relative;
                overflow: hidden;
                @include radius-top;
                @include radius-bottom;

                img, picture {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

            }

            .main--products-product-copy {
                display: block;
                padding: 25px 35px 0 35px;

                @include mq($until: laptop-m) {
                    padding: 20px 20px 0 20px;
                }

                @include mq($until: tablet) {
                    padding-left: 0;
                    padding-right: 0;
                }

                h3 {
                    @include font(34px, $blue);
                    @include font-weight(semibold);
                    margin: 0 0 15px 0;
                    text-decoration: none;
                    position: relative;
                    padding-right: 50px;

                    @include mq($until: laptop-m) {
                        font-size: 2.5cqw;
                        padding-right: 3.5cqw;
                    }

                    @include mq($until: tablet) {
                        font-size: 4cqw;
                        padding-right: 3.5cqw;
                    }

                    @media screen and (max-width:520px) {
                        font-size: 5.5cqw;
                        padding-right: 5cqw;
                    }

                    i {
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        top: 0;
                        height: 100%;

                        @include mq($until: tablet) {
                            width: 3.5cqw;
                        }

                        @media screen and (max-width:520px) {
                            width: 5cqw;
                        }
            
                        img {
                            display: block;
                            width: 100%;
                            height: auto;
                            @include valign;
                        }
                    
                    }

                }

                p {
                    @include font(16px, $blue);
                    @include font-weight(regular);
                    margin: 0;
                    padding: 0;
                    text-decoration: none;
                }

            }

        }

    }

}