.region--banner {
    @include blue-grad;
    padding-top: 200px;
    padding-bottom: 23vw;

    @include mq($until: laptop-s) {
        padding-top: 150px
    }

    .region--banner-flex {
        @include flex;
        align-items: center;
        justify-content: space-between;

        @include mq($until: laptop-s) {
            flex-wrap: wrap;
        }

        .region--banner-copy {
            display: inline-block;
            width: 40%;
            padding: 0 40px;

            @include mq($until: laptop-s) {
                width: 100%;
                padding: 0 20px;
                order: 1;
                margin-bottom: 40px;
            }

            h1 {
                font-family: $relation;
                font-size: 84px;
                color: $gold;
                @include font-weight(light);
                margin: 0 0 5px 0;
                line-height: 1;

                span {
                    font-size: 40px;
                }

            }

            h2 {
                font-family: $relation;
                font-size: 58px;
                color: $gold;
                margin: 0 0 20px 0;
                line-height: 1.2;
            }

            p {
                @include font(18px, white);
                @include font-weight(regular);
            }

        }

        .region--banner-img {
            display: inline-block;
            width: 60%;
            overflow: hidden;
            position: relative;
            @include radius-right;
            background-color: $blue;

            @include mq($until: laptop-s) {
                width: 90%;
                order: 2;
            }

            img, video {
                display: block;
                width: 100%;
                height: auto;
                object-fit: cover;
            }

        }

    }

    .region--banner-headline {
        display: block;       
        width: 100%;
        max-width: 1200px;
        text-align: center;
        margin: 100px auto 0 auto;
        padding: 0 25px;

        @include mq($until: laptop-s) {
            margin-top: 60px;
        }

        @include mq($until: tablet) {
            margin-bottom: 30px
        }

        p {
            @include font(36px, white);
            @include font-weight(light);
            padding: 0;
            margin: 0;
            text-align: center;

            @include mq($until: mobile-l) {
                font-size: 7vw;
            }

        }

    }

}

.region--content {
    margin-top: -15vw;
    margin-bottom: 100px;

    @include mq($until:tablet) {
        margin-bottom: 50px;
    }

    .region--content-images {
        @include flex;

        .region--content-images-img {
            display: inline-block;
            width: 50%;
            position: relative;
            overflow: hidden;
            @include radius-left;
            @include radius-right;

            @include mq($until: mobile-l) {
                width: 100%;
                margin-right: 0 !important;
            }

            &:first-child {
                margin-right: 35px;

                @include mq($until: tablet) {
                    margin-right: 20px;
                }

            }

            &:nth-child(2) {
                @include mq($until: mobile-l) {
                    display: none;
                }
            }

            img, picture {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

        }

    }

    .region--content-related {

        h3 {
            display: block;
            @include font(46px, $blue);
            @include font-weight(light);
            margin: 60px auto;
            text-align: center;

            @include mq($until: mobile-l) {
                font-size: 10vw;
                margin: 40px 0;
            }

        }

        .product--content-related-cont {
            @include flex;
            position: relative;

            @include mq($until: laptop-m) {
                width: calc(100% - 20px);
                margin: 0 auto;
            }
    
            @include mq($until: tablet) {
                width: calc(100% - 60px);
            }

            .cheese--related-item {
                display: inline-flex;
                display: -webkit-inline-flex;
                flex-direction: column;
                width: 25%;
                border-radius: 2.2vw;
                background-color: $bg-grey;
                margin-right: 30px;
                text-decoration: none;
                container-type: inline-size;
                container-name: relatedcheese;
                height: auto !important;

                @include mq($until: desktop) {
                    margin-right: 20px;
                }
    
                &:last-child {
                    margin-right: 0;
                }

                .cheese--related-item-img {
                    width: 100%;
                    height: 85cqw;
                    @include radius-top;
                    overflow: hidden;
    
                    img, picture {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
    
                }
    
                .cheese--related-item-copy {
                    padding: 20px 30px;

                    @include mq($until: desktop) {
                        padding: 20px;
                    }
    
                    h5 {
                        @include font(30px, $blue);
                        @include font-weight(semibold);
                        margin: 0 0 8px 0;
                        position: relative;
                        padding-right: 45px;
                        text-decoration: none;

                        @include mq($until: desktop) {
                            padding-right: 30px;
                            font-size: 7.5cqw;
                        }

                        @include mq($until: tablet) {
                            font-size: 10cqw;
                        }
                        
                        i {
                            display: inline-block;
                            position: absolute;
                            right: 0;
                            top: 0;
                            height: 100%;
        
                            img {
                                display: block;
                                width: 25px;
                                height: 25px;
                                @include valign;
                            }
                        
                        }
    
                    }
    
                    p {
                        @include font(16px, $blue);
                        @include font-weight(regular);
                        text-decoration: none;
    
                        @include mq($until: laptop-s) {
                            font-size: 5cqw;
                        }
    
                        @include mq($until: tablet) {
                            font-size: 7.5cqw;
                        }

                    }
    
                }
    
            }

            .product--content-related-filler {
                display: inline-block;
                width: 25%;
                position: relative;
                overflow: hidden;
                @include radius-top;
                @include radius-bottom;

                img, picture {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

            }

            &.three-col {

                @include mq($until: laptop-m) {
                    flex-wrap: nowrap;
                }
    
                @include mq($until: tablet) {
                    flex-wrap: wrap;
                }
    
                .cheese--related-item {
                    width: calc(100% / 3);
    
                    .cheese--related-item-img {
                        height: 80cqw;
                    }
    
                }
    
            }
    
            &.two-col {
    
                @include mq($until: laptop-m) {
                    flex-wrap: nowrap;
                }
    
                @media screen and (max-width:650px) {
                    flex-wrap: wrap;
                }
    
                .cheese--related-item {
                    width: 50%;
    
                    @media screen and (max-width:650px) {
                        width: calc(50% - 10px);
                        margin-right: 0;
                        margin-bottom: 20px;

                        &:nth-child(odd) {
                            margin-right: 20px;
                        }

                    }
    
                    .cheese--related-item-img {
                        height: 50cqw;
                    }
    
                    .cheese--related-item-copy {
    
                        @include mq($until: desktop) {
                            h5 {
                                font-size: 30px;
                            }
                            p {
                                font-size: 16px;
                            }
                        }
    
                    }
    
                }

                .product--content-related-filler {

                    @media screen and (max-width:650px) {
                        // width: 100%;
                        display: none;
                    }

                }
    
            }

            &.one-col {
                width: 100%;

                div {
                    &:first-child {
                        width: 100%;
                    }
                }

                .no-swiper {
                    @include flex;
                    width: 100%;
                }

                .cheese--related-item {
                    width: 50%;

                    h5 {
                        @include mq($until: desktop) {
                            font-size: 30px;
                        }
                    }

                    @include mq($until: laptop-m) {
                        width: 75%;
                    }

                    @include mq($until: tablet) {
                        width: 50%;
                    }

                    @media screen and (max-width:600px) {
                        width: 100%;
                        margin-right: 0;
                    }

                    .cheese--related-item-img {
                        height: 40cqw;
                    }

                }

                .product--content-related-filler {

                    @include mq($until: laptop-m) {
                        width: 25%;
                    }

                    @include mq($until: tablet) {
                        width: 50%;
                    }

                    @media screen and (max-width:600px) {
                        display: none;
                    }

                }

            }

            .swiper-button-prev {
                left: -40px;
            }

            .swiper-button-next {
                right: -40px;
            }

        }

        .region--content-viewall {
            display: block;
            margin: 100px auto 0 auto;
            text-align: center;

            @include mq($until: tablet) {
                margin-top: 40px;
            }
     
            a {
                @include font(28px, $blue);
                @include font-weight(semibold);
                position: relative;
                padding-left: 50px;
                text-decoration: none;

                @include mq($until:tablet) {
                    font-size: 6vw;
                    padding-left: 40px;
                }
    
                i {
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    height: 100%;
        
                    img {
                        display: block;
                        width: 30px;
                        height: 30px;
                        @include valign;

                        @include mq($until:tablet) {
                            width: 25px;
                            height: 25px;
                        }

                    }
                
                }
    
            }
    
        }

    }

}