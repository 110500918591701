.news--page {
    @include blue-grad;
    padding-top: 180px;
    padding-bottom: 15vw;

    @media screen and (max-width:920px) {
        padding-top: 120px;
    }

    h1 {
        text-align: right;
        font-family: $relation;
        font-size: 80px;
        color: $gold;
        margin: 0;

        @include mq($until: tablet) {
            text-align: left
        }

    }

    .news--page-main {
        @include flex;
        align-items: center;
        margin-top: 30px;

        @include mq($until: tablet) {
            flex-wrap: wrap;
            margin-top: 20px;
        }

        .news--page-main-copy {
            display: inline-block;
            width: 50%;
            padding-right: 80px;
            padding-top: 30px;

            @include mq($until: tablet) {
                width: 100%;
                margin-bottom: 30px;
                padding-right: 0;
                padding-top: 0
            }

            h2 {
                @include font(44px, white);
                @include font-weight(light);
                margin: 0 0 40px 0;

                @include mq($until: tablet) {
                    font-size: 7.5vw;
                    margin-bottom: 20px;
                }

            }

            .date {
                @include font(16px, white);
                @include font-weight(light);
                font-style: italic;
                padding-bottom: 40px;

                @include mq($until: tablet) {
                    padding-bottom: 20px;
                }

            }

            p, li {
                @include font(18px, white);
                @include font-weight(regular);
            }

            a {
                text-decoration: underline;
                color: $gold;
            }

            li {
                padding-bottom: 10px;
            }

            ol {
                padding-left: 20px;
                padding-bottom: 10px;
            }

            ul {
                padding-left: 20px;
                list-style: disc;
                padding-bottom: 10px;
            }

            .read--more {
                display: block;
                width: 100%;
                margin-top: 10px;

                a {
                    display: inline-block;
                    background-color: $gold;
                    color: white;
                    text-decoration: none;
                    text-align: center;
                    padding: 15px 45px 15px 30px;
                    @include radius-bottom;
                    @include radius-top;
                    position: relative;
                    @include animate;
                    line-height: 1;
    
                    &:hover {
                        background-color: $dark-gold;
                    }
                    
                    &:before {
                        display: inline-block;
                        content: '';
                        background-image: url('../static/icon-arrow-basic.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 10px 16px;
                        width: 30px;
                        height: 16px;
                        position: absolute;
                        right: 8px;
                        top: 50%;
                        transform: translateY(-50%) rotate(180deg);
                    }
                }

            }

        }

        .news--page-main-image {
            display: inline-block;
            position: relative;
            overflow: hidden;
            @include radius-top;
            @include radius-bottom;
            width: 50%;

            @include mq($until: tablet) {
                width: 100%;
            }
            
            img, picture {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

        }

    }

}

.news--list {
    margin-top: -8vw;

    .news--list-main {
        @include flex;
        flex-wrap: wrap;

        .news--list-item {
            display: inline-block;
            width: calc(25% - 23px);
            border-radius: 2.2vw;
            margin-right: 30px;
            text-decoration: none;
            container-type: inline-size;
            container-name: cheesecard;
            margin-bottom: 50px;
            position: relative;

            @include mq($until: laptop-m) {
                margin-right: 15px !important;
                width: calc(100% / 3 - 10px);

                &:nth-child(3n+3) {
                    margin-right: 0 !important;
                }

            }

            @include mq($until: tablet) {
                width: calc(50% - 8px);
                margin-bottom: 20px;

                &:nth-child(odd) {
                    margin-right: 15px !important;
                }
                &:nth-child(even) {
                    margin-right: 0 !important;
                }

            }

            &:nth-child(4n+4){
                margin-right: 0;
            }

            .news--list-img {
                width: 100%;
                // height: 100%;
                height: 70cqw;
                @include radius-top;
                @include radius-bottom;
                position: relative;
                overflow: hidden;

                img, picture {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

            }

            .news--list-copy {
                padding: 20px;

                @include mq($until: mobile-l) {
                    padding: 20px 0px;
                }

                p.date {
                    @include font(15px, $blue);
                    @include font-weight(regular);
                    margin: 0;
                    padding: 0;
                    text-decoration: none;

                    @include mq($until: desktop) {
                        font-size: 6.5cqw;
                    }

                    @include mq($until: laptop-s) {
                        font-size: 5cqw;
                    }

                }

                h3 {
                    @include font(28px, $blue);
                    @include font-weight(semibold);
                    margin: 8px 0 8px 0;
                    text-decoration: none;
                    
                    @include mq($until: desktop) {
                        font-size: 7.5cqw;
                    }

                    @include mq($until: tablet) {
                        font-size: 8cqw;

                        @supports not (container-type: inline-size) {
                            font-size: 22px;
                        }

                    }

                }

                p {
                    @include font(16px, $blue);
                    @include font-weight(regular);
                    text-decoration: none;
                    padding-bottom: 0;
                    padding-right: 20px;

                    @include mq($until: laptop-s) {
                        font-size: 6cqw;
                    }

                    @include mq($until: tablet) {
                        font-size: 4.5cqw;
                    }

                    @include mq($until: mobile-l) {
                        font-size: 5.5cqw;
                    }

                }

                i {
                    display: inline-block;
                    position: absolute;
                    right: 0;
                    bottom: 20px;
                    height: 25px;

                    img {
                        display: block;
                        width: 25px;
                        height: 25px;
                        @include valign;

                        @include mq($until: mobile-l) {
                            width: 20px;
                            height: 20px;
                        }

                    }
                
                }

            }
        }

    }

    .load--more {
        display: block;
        margin: 60px auto;
        text-align: center;

        @include mq($until:tablet) {
            margin-top: 20px;
        }

        a {
            @include font(28px, $blue);
            @include font-weight(semibold);
            position: relative;
            padding-left: 50px;
            text-decoration: none;

            @include mq($until:tablet)  {
                font-size: 6vw;
            }

            i {
                display: inline-block;
                position: absolute;
                left: 0;
                height: 100%;
    
                img {
                    display: block;
                    width: 30px;
                    height: 30px;
                    @include valign;
                }
            
            }

        }

    }

}