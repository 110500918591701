.cheese--banner.about--banner {
    @include blue-grad;

    .cheese--banner-flex {
        .cheese--banner-copy {
            
            h1 {
                @include font(66px, white);
                @include font-weight(light);

                @include mq($until: laptop-m) {
                    font-size: 7vw;
                }

                @include mq($until: tablet) {
                    font-size: 9vw;
                }

            }

            h2 {
                font-family: $relation;
                font-size: 50px;
                color: $gold;
                margin-bottom: 10px;

                @include mq($until: laptop-m) {
                    font-size: 5.5vw;
                } 

                @include mq($until: tablet) {
                    font-size: 6.5vw;
                }

            }

        }
    }

}

.about--content {
    background-color: white;
    padding: 80px 0;

    @include mq($until: tablet) {
        padding: 40px 0;
    }

    .about--content-flex {
        @include flex;
        justify-content: space-between;

        @include mq($until: tablet) {
            flex-wrap: wrap;
        }

        .about--content-img {
            display: inline-block;
            width: 50%;
            @include radius-left;
            @include radius-right;
            position: relative;
            overflow: hidden;
            margin-right: 40px;
            height: auto;

            @include mq($until: laptop-s) {
                margin-right: 0;
            }

            @include mq($until: tablet) {
                width: 100%;
                height: 50vw;
            }

            img, picture {
                display: block;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

        }

        .about--content-copy {
            display: inline-block;
            width: 50%;
            padding: 40px 0 40px 20px;

            @include mq($until: tablet) {
                width: 100%;
                padding: 20px 0 0 0;
            }

            @media screen and (max-width: 650px) {
                width: 100%;
                padding: 20px 0 0 0;
            }

            h2 {
                @include font(50px, $blue);
                @include font-weight(light);
                margin: 0 0 20px 0;
            }

            p, li {
                @include font(18px, $blue);
                @include font-weight(regular);
            }

            ul {
                list-style: disc;
                padding-left: 20px;
                padding-bottom: 10px;

                li {
                    padding-bottom: 10px;
                }

            }

        }

    }

}

.about--values {
    padding: 100px 0;

    @include mq($until: tablet) {
        padding: 20px 0 10px 0;
    }

    .about--values-header {
        display: block;
        width: 100%;
        max-width: 450px;
        margin-bottom: 100px;

        @include mq($until: tablet) {
            margin-bottom: 30px;
        }

        h2 {
            @include font(60px, $blue);
            @include font-weight(light);
            margin: 0 0 20px 0;
            line-height: 1.2;
        }

        p {
            @include font(18px, $blue);
            @include font-weight(regular);
        }

    }

    .about--values-flex {
        @include flex;

        @media screen and (max-width: 1000px) {
            flex-wrap: wrap;
        }

        .about--values-value {
            display: inline-block;
            width: calc(100% / 3);
            position: relative;
            margin-right: 20px;
            container-type: inline-size;
            container-name: value;

            @media screen and (max-width: 1000px) {
                width: 100%;
                @include flex;
                margin: 0 0 30px 0 !important;
            }

            @media screen and (max-width: 660px ) {
                flex-wrap: wrap;
            }

            &:nth-child(1) {
                margin-top: 160px;
            }

            &:last-child {
                margin-right: 0;
                margin-top: 320px;
            }

            .value--icon {
                display: block;
                position: absolute;
                top: -30cqw;
                z-index: 10;
                left: 0;
                right: 0;
                text-align: center;
                margin: 0 auto;
                background-color: $blue;
                border-radius: 100%;
                height: 60cqw;
                width: 60cqw;
                max-width: 65%;

                @media screen and (max-width: 1000px) {
                    display: none;
                }
                
                .valign {
                    @include valign;
                    padding: 0 5cqw;

                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                        max-width: 18cqw;
                        margin: 0 auto;
                        text-align: center;
                        max-width: 36%;
                    }
    
                    h3 {
                        @include font(32px, white);
                        @include font-weight(semibold);
                        text-align: center;
                        margin: 15px 0 0 0;
                        line-height: 1.2;
                        text-transform: uppercase;
                        font-size: 6cqw;
                    }

                }

            }

            .about--values-value-img {
                display: block;
                width: 100%;
                @include radius-top;
                position: relative;
                overflow: hidden;

                @media screen and (max-width: 1000px) {
                    display: inline-block;
                    @include radius-left;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    // width: 60cqw;
                    height: 100%;
                    width: 33%;
                    background-color: pink;
                }

                @media screen and (max-width: 660px ) {
                    width: 100%;
                    height: auto;
                    border-bottom-left-radius: 0;
                    border-top-right-radius: 2.2vw  ;
                }

                img, picture {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

            }

            .about--values-value-copy {
                display: block;
                background-color: white;
                padding: 40px 40px;
                @include radius-bottom;
                border: 1px solid #D0D0D0;
                border-top: 0;
                
                @media screen and (max-width: 1000px) {
                    border: 1px solid #D0D0D0;
                    @include radius-right;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    width: 67%;
                }

                @media screen and (max-width: 660px ){
                    width: 100%;
                    border-top-right-radius: 0;
                    border-bottom-left-radius: 2.2vw;
                    padding: 20px 25px;
                }

                .about--values-value-copy-mob {
                    display: none;

                    @media screen and (max-width: 1000px) {
                        @include flex;
                        align-items: center;
                        margin-bottom: 20px;
                    }

                    img, picture {
                        display: inline-block;
                        width: 100%;
                        height: auto;
                        max-width: 50px;
                        text-align: left;
                        margin-right: 15px;
                    }

                    h3 {
                        display: inline-block;
                        @include font(32px, $blue);
                        @include font-weight(semibold);
                        text-align: left;
                        margin: 0;
                        line-height: 1.2;
                        text-transform: uppercase;

                        @include mq($until: mobile-l) {
                            font-size: 7cqw;
                        }

                    }

                }

                p {
                    @include font(18px, $blue);
                    @include font-weight(regular);
                }

            }

        }

    }

}

.about--image {
    display: block;
    width: 100%;

    img, picture {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

}