.container {
  width: 100%;
  max-width: 100vw;
  margin: 0 auto;
  overflow: visible;
  position: relative;
  padding-left: 40px;
  padding-right: 40px;

  @include mq($until: tablet) {
    padding-left: 20px;
    padding-right: 20px;
  }

}


.site-wide {
  width: 100%;
  max-width: 1612px;
  margin: 0 auto;
  overflow: visible;
  position: relative;
  padding-right: 25px;
  padding-left: 25px;

  @include mq(mobile-l) {
    padding-right: 50px;
    padding-left: 50px;
  }

  @include mq(desktop) {
    padding-right: 154px;
    padding-left: 154px;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  &:not(.grid-container--no-gap) {
    grid-row-gap: 50px;

    @include mq(mobile-m) {
      grid-gap: 50px;
    }
  }

  &.grid-container--half {
    .grid-column {
      grid-column: span 6;

      @include mq(laptop-s) {
        &:nth-of-type(odd) {
          grid-column: 1 / span 3;
        }

        &:nth-of-type(even) {
          grid-column: 4 / span 3;
        }
      }
    }
  }

  &.grid-container--indent {
    .grid-column {
      grid-column: span 6;

      @include mq(laptop-s) {
        &:nth-of-type(odd) {
          grid-column: 2 / span 2;
        }

        &:nth-of-type(even) {
          grid-column: 4 / span 2;
        }
      }
    }
  }

  &.grid-container--4 {
    .grid-column {
      grid-column: span 6;

      @include mq(laptop-s) {
        grid-column: 2 / span 4;
      }
    }
  }

  &.grid-container--3 {
    .grid-column {
      grid-column: span 6;

      @include mq(tablet) {
        grid-column: span 3;
      }

      @include mq(laptop-s) {
        grid-column: span 2;
      }
    }
  }

  &.grid-container--4-2 {
    .grid-column {
      grid-column: span 6;

      @include mq(laptop-s) {
        &:nth-of-type(odd) {
          grid-column: span 4;
        }

        &:nth-of-type(even) {
          grid-column: span 2;
        }
      }
    }

    &.grid-container--reverse {
      .grid-column {
        @include mq(laptop-s) {
          &:nth-of-type(odd) {
            grid-column: span 2;
          }

          &:nth-of-type(even) {
            grid-column: span 4;
          }
        }
      }
    }
  }

  &.grid-container--5-1 {
    .grid-column {
      grid-column: span 6;

      @include mq(laptop-s) {
        &:nth-of-type(odd) {
          grid-column: span 5;
        }

        &:nth-of-type(even) {
          grid-column: span 1;
        }
      }
    }
  }
}
