.basic--banner {
    padding-top: 200px;

    @include mq($until: tablet) {
        padding-top: 150px;
    }

    .basic--banner-copy {
        display: block;
        text-align: right;
        width: 100%;
        margin: 0 0 60px 0;
        @include flex;
        flex-direction: column;
        align-items: flex-end;

        @include mq($until: tablet) {
            align-items: flex-start;       
            text-align: left;
        }

        h1 {
            font-family: $relation;
            font-size: 84px;
            color: $gold;
            margin: 0 0 15px 0;
            line-height: 1;

            @include mq($until: tablet) {
                font-size: 16vw;   
            }
        }

        p {
            display: block;
            width: 100%;
            text-align: right;
            max-width: 935px;
            @include font(28px, white);
            @include font-weight(light);
            padding-bottom: 0;

            @include mq($until: tablet) {
                text-align: left;
                font-size: 16px;
            }

        }

        a {
            color: $gold;
            @include font-weight(regular);
            text-decoration: underline;
        }

    }

}