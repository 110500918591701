html {
  &.stop-scroll {
    overflow: hidden;
  }
}

body {
  font-family: $font-primary;
  @include font-weight(regular);
  line-height: 1.4;
  color: $blue;
  position: relative;
  overflow: hidden;
}

/*
* Hide only visually, but have it available for screenreaders: h5bp.com/v
*/
.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/*
* Extends the .sr-only class to allow the element to be focusable
* when navigated to via the keyboard: h5bp.com/p
*/
.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.blue--grad {
  @include blue-grad;
}

.swiper-button-prev, .swiper-button-next {
  &:after {
    background-image: url('../static/swiper-arrow.svg');
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px 31px;
    width: 18px;
    height: 31px;
  }
}
.swiper-button-prev {
  &:after {
    transform: rotate(180deg);
  }
}