@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.contact--form {
    @include blue-grad;
    padding-top: 180px;
    padding-bottom: 60px;

    @include mq($until: tablet) {
        padding-top: 100px;
    }

    h1 {
        font-family: $relation;
        font-size: 88px;
        color: $gold;
        margin: 0 0 20px 0;
        text-align: right;

        @include mq($until: mobile-l) {
            text-align: left;
            font-size: 18vw;
        }

    }

    .contact--form-flex {
        @include flex;

        @include mq($until: laptop-s) {
            flex-wrap: wrap;
        }

        .contact--form-form {
            display: inline-block;
            width: 70%;
            background-color: $blue;
            padding: 40px;
            @include radius-top;
            @include radius-bottom;

            @include mq($until: laptop-s) {
                width: 100%;
                order: 2;
            }

            @include mq($until: tablet) {
                padding: 25px;
            }

            p {
                display: block;
                @include font(18px, white);
                @include font-weight(regular);
                padding-bottom: 20px;
                max-width: 50%;
                width: 100%;

                @include mq($until: laptop-s) {
                    max-width: 100%;
                }

                &.thanks {
                    font-size: 24px;
                    @include font-weight(semibold);
                }

            }

            .contact--form-container {
                @include flex;
                justify-content: space-between;
                margin-bottom: 20px;

                @media screen and (max-width:620px) {
                    flex-wrap: wrap;
                }

                .contact--form-group {
                    display: block;
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    input, textarea {
                        display: block;
                        background-color: $blue;
                        border: 1px solid white;
                        border-radius: 5px;
                        width: 100%;
                        padding: 10px 10px;
                        @include font(16px, white);
                        @include font-weight(regular);
    
                        &::placeholder {
                            color: white;
                            opacity: 1;
                        }
    
                    }

                }

                .contact--form-left {
                    display: inline-block;
                    width: 50%;
                    padding-right: 10px;

                    @media screen and (max-width:620px) {
                        width: 100%;
                        padding-right: 0;
                    }

                }

                .contact--form-right {
                    display: inline-block;
                    width: 50%;
                    padding-left: 10px;

                    @media screen and (max-width:620px) {
                        padding: 0;
                        width: 100%;
                        margin-top: 10px;;
                    }

                    textarea {
                        width: 100%;
                        height: 100%;
                        resize: none;
                        display: block;
                        background-color: $blue;
                        border: 1px solid white;
                        border-radius: 5px;
                        padding: 10px 10px;
                        @include font(16px, white);
                        @include font-weight(regular);
    
                        &::placeholder {
                            color: white;
                            opacity: 1;
                        }
                    }

                }

            }

            .contact--form-submit {
                display: block;
                float: right;
                position: relative;

                button[type="submit"] {
                    display: block;
                    @include font(26px, white);
                    @include font-weight(semibold);
                    background-color: $blue;
                    border: none;
                    appearance: none;
                    background-image: url('../static/icon-submit.svg');
                    background-repeat: no-repeat;
                    background-position: center right;
                    background-size: 28px 28px;
                    padding-right: 40px;
    
                    &:disabled {
                        opacity: 0.2;
                    }
    
                }

                .loader {
                    display: none;
                    border: 5px solid white;
                    border-top: 5px solid $gold;
                    border-radius: 50%;
                    width: 35px;
                    height: 35px;
                    animation: spin 2s linear infinite;
                    position: absolute;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: 0 auto;
                    top: 3px;
                }

            }

            .form-message {
                float: right;
                color: white;
                margin-top: 5px;
                font-size: 20px;
            }

            #error {
                color: #ff3838;
                margin-right: 15px;
                font-weight: bold;
            }

        }

        .contact--form-info {
            display: inline-block;
            width: 30%;
            text-align: right;
            position: relative;

            @include mq($until: laptop-s) {
                width: 100%;
                text-align: left;
                order: 1;
            }

            .contact--form-info-item {
                display: block;
                width: 100%;
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }

                &.address {
                    p {
                        i {
                            height: auto;
                            img {
                                transform: translateY(0);
                                top: 0;
                            }
                        }
                    }
                }

                a, p {
                    @include font(18px, white);
                    @include font-weight(semibold);
                    text-decoration: none;
                    position: relative;
                    padding-right: 50px;

                    @include mq($until: laptop-s) {
                        padding-right: 0;
                        padding-left: 50px;
                    }

                    i {
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        top: 0;
                        height: 100%;

                        @include mq($until: laptop-s) {
                            right: auto;
                            left: 0;
                        }
    
                        img {
                            display: block;
                            width: 30px;
                            height: 30px;
                            @include valign;
                        }
                    
                    }

                }

            }

            .contact--form-info-social {
                display: block;
                text-align: right;
                margin-top: 80px;

                @include mq($until: laptop-s) {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin-top: 0;
                }

                @include mq($until: mobile-l) {
                    position: static;
                    text-align: left;
                    margin-bottom: 30px;
                }

                p {
                    @include font(18px, white);
                    @include font-weight(semibold);
                    padding-bottom: 0;
                }

                .social--icons {
                    @include flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-top: 12px;

                    @include mq($until: mobile-l) {
                        justify-content: flex-start;
                    }

                    a {
                        display: inline-block;
                        width: 37px;
                        margin-right: 10px;

                        &:last-child {
                            margin-right: 0;
                        }

                        img {
                            display: block;
                            width: 100%;
                            height: auto;
                        }

                    }

                }

            }

        }

    }

    &.thanks {

        h1 {
            text-align: left;
        }

        .social--links {
            text-align: right;
            margin-top: 15px;

            ul {
                @include flex;

                li {
                    display: inline-block;
                    text-align: right;
                    margin-bottom: 8px;

                    &:first-child {
                        margin-right: 15px;
                    }

                    a {
                        display: block;
                        @include font(14px, $blue);
                        @include font-weight(regular);
                        text-decoration: none;
                    }

                }
            }

        }

    }

}

.contact--img {
    display: block;
    width: 100%;

    img, picture {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

}