@font-face {
  font-family: 'Jost';
  src: url('https://www.sodiaal.co.uk/assets/fonts/Jost-VariableFont_wght.ttf') format("truetype-variations");
  font-weight: 1 1000;
}

$font-primary: 'Jost', Arial, sans-serif;

$relation: 'relation-one', Arial, sans-serif;

$font-weight: (
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
);

p {
  @include font(18px, white);
  @include font-weight(regular);
  padding-bottom: 20px;

  &:last-child {
    padding-bottom: 0;
  }

}
