.basic--banner.-policy {
    padding-bottom: 10px;
    .basic--banner-copy {
        align-items: flex-start;
        text-align: left;
        margin-bottom: 0;
    }
}

.policy--body {
    padding: 50px 0;

    h2 {
        @include font(26px, $blue);
        @include font-weight(semibold);
        margin: 0 0 20px 0;
    }

    h3 {
        @include font(22px, $blue);
        @include font-weight(semibold);
        margin: 0 0 20px 0;
    }

    h4 {
        @include font(30px, $blue);
        @include font-weight(semibold);
        margin: 0 0 20px 0;
    }

    h5 {
        @include font(26px, $blue);
        @include font-weight(semibold);
        margin: 0 0 20px 0;
    }

    h6 {
        @include font(20px, $blue);
        @include font-weight(semibold);
        margin: 0 0 20px 0;
    }

    p, li {
        @include font(18px, $blue);
        @include font-weight(regular);

        @include mq($until: tablet) {
            font-size: 16px;
        }

    }

    a {
        text-decoration: underline;
        color: $gold;
    }

    li {
        padding-bottom: 10px;
    }

    ol {
        padding-left: 20px;
        padding-bottom: 10px;
    }

    ul {
        padding-left: 20px;
        list-style: disc;
        padding-bottom: 10px;
    }

    blockquote {
        display: block;
        border: 1px solid $blue;
        @include radius-top;
        @include radius-bottom;
        padding: 30px;
        margin-bottom: 40px;
    }

}