nav.nav {
    display: inline-flex;
    display: -webkit-inline-flex;
    align-items: center;

    @media screen and (max-width:1380px) {
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: flex-end;
    }

    @include mq($until: laptop-s) {
        position: fixed;
        left: 0;
        top: 0;
        background-color: $blue;
        width: 100%;
        max-width: 360px;
        display: none;
        height: 100%;
        padding: 20px;
        z-index: 100;
    }
    
    @include mq($until: mobile-l) {
        max-width: 100%;
    }

    .mob--nav-header {
        @include flex;
        align-items: center;
        display: none;
        width: 100%;
        position: relative;
        border-bottom: 1px solid white;
        padding-bottom: 20px;
        margin-bottom: 20px;
        
        @include mq($until: laptop-s) {
            @include flex;
            justify-content: flex-end;
        }

        .close--nav {
            display: inline-block;
            cursor: pointer;
            width: 18px;
            height: 18px;
            position: relative;
            z-index: 5;

            img {
                display: block;
                width: 100%;
                height: auto;
            }

        }

        .mob--nav-header-copy {
            @include font(16px, white);
            @include font-weight(semibold);
            text-align: center;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
        }

    }

    .main--nav {
        display: inline-block;
        margin-right: 20px;

        @media screen and (max-width:1380px) {
            width: 100%;
            margin-right: 0;
        }

        @include mq($until: laptop-s) {
            display: block;
            width: 100%;
        }

        ul {
            @include flex;
            align-items: center;

            @media screen and (max-width:1380px) {
                align-items: flex-end;
                justify-content: flex-end;
            }

            @include mq($until: laptop-s) {
                display: block;
            }

            li {
                display: inline-block;
                text-align: center;
                margin: 0 12px;
                line-height: 1.2;

                @include mq($until: laptop-s) {
                    display: block;
                    text-align: left;
                    margin: 0 0 20px 0;
                }

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                a {
                    text-decoration: none;
                    @include font(16px, white);
                    @include font-weight(medium);

                    @include mq($until: laptop-s) {
                        display: block;
                        width: 100%;
                        position: relative;
                        font-size: 16px;
                    }

                    &.active {
                        color: $gold;
                    }

                    i {
                        display: none;

                        @include mq($until: laptop-s) {
                            width: 20px;
                            height: 20px;
                            display: inline-block;
                            background-image: url('../static/icon-arrow.svg');
                            background-repeat: no-repeat;
                            background-position: center right;
                            background-size: 20px 20px;
                            transform: translateY(-50%) rotate(180deg);
                            position: absolute;
                            right: 0;
                            top: 50%;
                        }

                    }

                }

            }

        }

    }

    .secondary--nav {
        display: inline-block;
        min-width: 100px;

        @media screen and (max-width:1380px) {
            width: 100%;
            margin-top: 10px;
        }

        @include mq($until: laptop-s) {
            display: block;
            width: 100%;
            min-width: auto;
        }

        ul {
            text-align: right;

            li {
                display: inline-block;
                margin: 0 10px;

                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }

                @include mq($until: laptop-s) {
                    display: block;
                    text-align: left;
                    margin: 0 0 20px 0;
                }


                a {
                    @include font(16px, $light-blue);
                    @include font-weight(regular);
                    text-decoration: none;

                    &.active {
                        color: $gold;
                    }

                }

            }

        }

    }

    &.active {
        display: block;
    }

}

.nav--overlay {
    display: none;
    opacity: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    z-index: 90;

    &.active {
        display: block;
        opacity: 1;
    }

}