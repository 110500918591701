@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600&display=swap');

// reset - clean slate
@import 'global/reset';

// globals - shouldn't need to add any more globals
@import 'global/global-mixins-functions';
@import 'global/global-breakpoints';
@import 'global/global-grid';
@import 'global/global-colours';
@import 'global/global-typography';
@import 'global/global-layers';
@import 'global/global-helpers';

@import 'global/global-footer';
@import 'global/global-header';
@import 'global/global-nav';
@import 'global/404';

@import 'elements/elements-buttons';
@import 'elements/elements-home-map';

@import 'components/components-home-banner';
@import 'components/components-home-block-section';
@import 'components/components-our-brands';
@import 'components/components-home-regions';
@import 'components/_components-cheese-banner';
@import 'components/_components-cheese-content';
@import 'components/_components-cheese-related';
@import 'components/_components-cheese-page';
@import 'components/_components-main-products';
@import 'components/_components-contact';
@import 'components/_components-butter-list';
@import 'components/_components-region';
@import 'components/_components-basic-banner';
@import 'components/_components-region-list';
@import 'components/_components-brand';
@import 'components/_components-about';
@import 'components/_components-news-article';
@import 'components/_components-news-page';
@import 'components/_components-recipe';
@import 'components/_components-recipe-list';
@import 'components/_components-latest-news';
@import 'components/_components-policy';