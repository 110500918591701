// mixins
@mixin font-weight($weight) {
    font-weight: map-get($font-weight, $weight);
}

@mixin letter-spacing($value) {
    letter-spacing: netc-character-spacing($value);
}

@mixin object-fit($of: cover, $op: top) {
    width: 100%;
    height: 100%;
    object-fit: $of;
    object-position: $op;
    font-family: 'object-fit: #{$of}; object-position: #{$op}';
}

@mixin helpers-reset-button {
    border: 0;
    background: transparent;
    font-weight: normal;
    padding: 0;
    outline: 0;
    cursor: pointer;
}

// functions
@function netc-map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}

@function netc-character-spacing($value) {
    $emValue: $value / 1000;
    @return $emValue + 0em;
}

@mixin valign {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

@mixin flex {
    display: flex;
    display: -webkit-flex;
}

@mixin blue-grad {
    background: rgb(14,74,140);
    background: -moz-linear-gradient(150deg, rgba(14,74,140,1) 0%, rgba(0,51,102,1) 100%);
    background: -webkit-linear-gradient(150deg, rgba(14,74,140,1) 0%, rgba(0,51,102,1) 100%);
    background: linear-gradient(150deg, rgba(14,74,140,1) 0%, rgba(0,51,102,1) 100%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@mixin font($size: false, $colour: false, $weight: false) {
    font-family: $font-primary;
    @if $size { font-size: $size; }
    @if $colour { color: $colour; }
    @if $weight { font-weight: $weight; }
}

@mixin radius-left {
    border-top-left-radius: 2.2vw;
    border-bottom-left-radius: 2.2vw;
}

@mixin radius-right {
    border-top-right-radius: 2.2vw;
    border-bottom-right-radius: 2.2vw;
}

@mixin radius-top {
    border-top-right-radius: 2.2vw;
    border-top-left-radius: 2.2vw;
}

@mixin radius-bottom {
    border-bottom-right-radius: 2.2vw;
    border-bottom-left-radius: 2.2vw;
}

@mixin animate {
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
}