.our--brands {
    background-color: white;
    margin-bottom: 60px;

    .our--brands-grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        @include mq($until: laptop-s) {
            grid-template-columns: repeat(4, 1fr);
        }
        @include mq($until: mobile-l) {
            grid-template-columns: repeat(3, 1fr);
        }

        .our--brands-brand {
            display: inline-block;
            position: relative;
            background-color: white;
            border: 1px solid #EBEBEB;

            &:hover {
                .our--brands-brand-logo {
                    opacity: 0;
                }
                .our--brands-brand-img {
                    img, picture {
                        transform: scale(1.2);
                        transition-duration: 3.5s;
                        transition-timing-function: ease-out;
                    }
                }
            }

            .our--brands-brand-logo {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: white;
                z-index: 2;
                @include animate;

                picture {
                    display: block;
                    width: 70%;
                    height: auto;
                    position: absolute;
                    @include valign;
                    margin: 0 auto;
                    text-align: center;
                }

            }

            .our--brands-brand-img {
                display: block;
                position: relative;
                overflow: hidden;
                z-index: 1;
                width: 100%;
                height: 100%;

                img, picture {
                    display: block;
                    width: 100%;
                    height: 100%;
                    @include animate;
                    object-fit: cover;
                }

            }

            &.-intro {
                padding: 40px 40px 40px 40px;

                @include mq($until: laptop-l) {
                    grid-column: 1 / span 2;
                }
                @include mq($until: laptop-s) {
                    grid-column: 1 / span 3;
                    padding: 20px;
                }

                h2 {
                    @include font(34px, $blue);
                    @include font-weight(semibold);
                    margin: 0 0 20px 0;
                }

                h4 {
                    @include font(18px, $gold);
                    @include font-weight(regular);
                    margin: 0 0 20px 0;
                }

                p {
                    @include font(18px, $blue);
                    @include font-weight(regular);
                    padding: 0;
                }

            }

        }

    }

}