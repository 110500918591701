.basic--banner.not-found {
    padding-top: 250px;
    padding-bottom: 100px;

    @include mq($until: tablet) {
        padding-top: 150px;
        padding-bottom: 50px;
    }

    .basic--banner-copy {
        margin-bottom: 0;
        text-align: left;
        align-items: flex-start;

        h1, p {
            text-align: left;
        }

    }

}