.recipe--article {
    @include blue-grad;
    padding-top: 250px;
    padding-bottom: 60px;

    @media screen and (max-width:920px) {
        padding-top: 120px;
    }

    h1 {
        @include font(48px, white);
        @include font-weight(light);
        margin: 0 0 20px 0;

        @include mq($until: tablet) {
            font-size: 34px;
        }

    }

    .recipe--article-flex {
        @include flex;
        align-items: flex-start;
        
        @include mq($until: tablet) {
            flex-wrap: wrap;
        }

        .recipe--article-main {
            display: inline-block;
            width: 75%;
            padding-right: 40px;

            @media screen and (max-width:950px) {
                width: 70%;
                padding-right: 25px;
            }

            @include mq($until: tablet) {
                width: 100%;
                padding-right: 0;
            }

            .recipe--article-main-img {
                display: block;
                width: 100%;
                @include radius-bottom;
                @include radius-top;
                position: relative;
                overflow: hidden;

                img, picture {
                    display: block;
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                }

            }

            .recipe--article-main-intro {
                @include flex;
                margin: 30px 0;

                @media screen and (max-width:600px) {
                    flex-wrap: wrap;
                }

                &.no-video {
                    .recipe--article-main-ingredients {
                        width: 100%;
                    }    
                }

                .recipe--article-main-ingredients {
                    display: inline-block;
                    width: 50%;
                    background-color: $blue;
                    padding: 30px;
                    @include radius-bottom;
                    @include radius-top;
                    @include font(18px, white);
                    @include font-weight(regular);
                    margin-right: 15px;

                    @include mq($until: laptop-s) {
                        padding: 20px;
                        margin-right: 10px;
                    }

                    @media screen and (max-width:600px) {
                        width: 100%;
                        margin-right: 0;
                        margin-top: 20px;
                        order: 2;
                    }

                    h3 {
                        @include font(28px, white);
                        @include font-weight(semibold);
                        margin: 0 0 20px 0;
                    }

                    p, li {
                        @include font(18px, white);
                        @include font-weight(regular);
                        padding-bottom: 10px;
                    }

                    &.long-ingredients {
                        @include flex;
                        flex-wrap: wrap;

                        @include mq($until: laptop-l) {
                            padding-bottom: 10px;
                        }

                        @include mq($until: tablet) {
                            padding-bottom: 0;
                        }

                        h3 {
                            display: block;
                            width: 100%;
                        }

                        blockquote {
                            display: inline-block;
                            padding-right: 30px;
                            width: 25%;
                            flex-grow: 2;

                            @include mq($until: laptop-l) {
                                width: calc(100% / 3);
                                margin-bottom: 20px;
                            }

                            @include mq($until: laptop-s) {
                                width: 50%;
                            }

                            @include mq($until: mobile-l) {
                                width: 100%;
                            }

                        }

                    }

                }

                .recipe--article-main-video {
                    display: inline-block;
                    width: 50%;
                    margin-left: 15px;
                    position: relative;
                    overflow: hidden;
                    @include radius-bottom;
                    @include radius-top;

                    @include mq($until: laptop-s) {
                        margin-left: 10px;
                    }

                    @media screen and (max-width:600px) {
                        width: 100%;
                        margin-left: 0;
                        height: 50vw;
                        order: 1;
                    }

                    iframe {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                }

            }

            .recipe--article-main-copy {

                p,li {
                    @include font(18px, white);
                    @include font-weight(regular);
                }

                li {
                    padding-bottom: 10px;
                }

                a {
                    color: $gold;
                    text-decoration: underline;
                    @include font-weight(semibold);
                }

                ul {
                    list-style: disc;
                    padding-left: 20px;
                    padding-bottom: 10px;
                }

                ol {
                    padding-left: 20px;
                }

                h2 {
                    @include font(36px, white);
                    @include font-weight(semibold);
                    margin: 0 0 20px 0;
                }
    
                h3 {
                    @include font(32px, white);
                    @include font-weight(semibold);
                    margin: 0 0 20px 0;
                }
    
                h4 {
                    @include font(30px, white);
                    @include font-weight(semibold);
                    margin: 0 0 20px 0;
                }
    
                h5 {
                    @include font(26px, white);
                    @include font-weight(semibold);
                    margin: 0 0 20px 0;
                }
    
                h6 {
                    @include font(20px, white);
                    @include font-weight(semibold);
                    margin: 0 0 20px 0;
                }

                blockquote {
                    display: block;
                    border: 1px solid white;
                    @include radius-top;
                    @include radius-bottom;
                    padding: 30px;
                    margin-bottom: 40px;
                }

                figure {
                    img {
                        margin: 5px 0 25px 0;
                        display: block;
                        width: 100%;
                        height: 100%;
                        @include radius-top;
                        @include radius-bottom;
                    }
                }

            }

        }

        .recipe--article-related {
            display: inline-block;
            width: 25%;

            @media screen and (max-width:950px) {
                width: 30%;
            }

            @include mq($until: tablet) {
                width: 100%;
                @include flex;
                flex-wrap: wrap;
                margin-top: 40px;
            }

            .recipe--article-related-item {
                display: block;
                width: 100%;
                margin-bottom: 50px;
                container-type: inline-size;
                container-name: recipeRelated;
                text-decoration: none;

                @include mq($until: tablet)  {
                    display: inline-block;
                    width: calc(50% - 10px);

                    &:first-child {
                        margin-right: 20px;
                    }

                    &:last-child {
                        display: none;
                    }

                }

                @media screen and (max-width:450px) {
                    width: 100%;
                    margin-right: 0 !important;
                }

                .recipe--article-related-img {
                    display: block;
                    width: 100%;
                    position: relative;
                    overflow: hidden;
                    @include radius-bottom;
                    @include radius-top;
                    margin-bottom: 15px;
                    height: 60cqw;

                    img, picture {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                }

                .recipe--article-related-copy {
                    display: block;
                    width: 100%;

                    h5 {
                        @include font(24px, white);
                        @include font-weight(semibold);
                        margin: 0 0 20px 0;
                        position: relative;
                        padding-right: 35px;

                        i {
                            display: inline-block;
                            position: absolute;
                            right: 0;
                            top: 0;
                            height: 100%;
        
                            img {
                                display: block;
                                width: 25px;
                                height: 25px;
                                @include valign;
                            }
                        
                        }

                    }

                    p {
                        @include font(16px, white);
                        @include font-weight(regular);
                        padding-bottom: 0;
                    }

                }

            }

        }

    }

    .recipe--viewall {
        display: block;
        margin: 60px auto 0 auto;
        text-align: center;

        @include mq($until:tablet) {
            margin-top: 20px;
        }

        a {
            @include font(28px, white);
            @include font-weight(semibold);
            position: relative;
            padding-left: 50px;
            text-decoration: none;

            @include mq($until:tablet)  {
                font-size: 6vw;
                padding-left: 40px;
            }

            i {
                display: inline-block;
                position: absolute;
                left: 0;
                height: 100%;
    
                img {
                    display: block;
                    width: 30px;
                    height: 30px;
                    @include valign;

                    @include mq($until:tablet) {
                        width: 25px;
                        height: 25px;
                    }

                }
            
            }

        }
    }

}

.recipe--products {
    background-color: $bg-grey;
    padding: 60px 0;

    @include mq($until: tablet) {
        padding: 40px 0 0 0;
    }

    h3 {
        @include font(42px, $blue);
        @include font-weight(light);
        margin: 0 0 30px 0;

        @include mq($until: tablet) {
            font-size: 6.5vw;
        }

    }

    .recipe--products-flex {
        @include flex;

        @include mq($until: laptop-m) {
            flex-wrap: wrap;
        }

        .recipe--products-item {
            display: inline-flex;
            display: -webkit-inline-flex;
            flex-direction: column;
            width: 25%;
            border-radius: 2.2vw;
            background-color: $bg-grey;
            margin-right: 30px;
            text-decoration: none;
            container-type: inline-size;
            container-name: relatedcheese;

            @include mq($until: laptop-s) {
                width: calc(50% - 10px) !important;
                margin-bottom: 20px;

                &:nth-child(even) {
                    margin-right: 0 !important;
                }

                &:nth-child(odd) {
                    margin-right: 20px;
                }

            }

            &:last-child {
                margin-right: 0;
            }

            .recipe--products-item-img {
                width: 100%;
                height: 85cqw;
                @include radius-top;
                @include radius-bottom;
                overflow: hidden;

                img, picture {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

            }

            .recipe--products-item-copy {
                padding: 20px 0;

                @include mq($until: laptop-s) {
                    padding: 20px 0;
                }

                h5 {
                    @include font(30px, $blue);
                    @include font-weight(semibold);
                    margin: 0 0 8px 0;
                    position: relative;
                    padding-right: 45px;
                    text-decoration: none;

                    @include mq($until: desktop) {
                        font-size: 7.5cqw;
                        padding-right: 30px;
                    }

                    @include mq($until: tablet) {
                        font-size: 10cqw;

                        @supports not (container-type: inline-size) {
                            font-size: 22px;
                        }

                    }
                    
                    i {
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        top: 0;
                        height: 100%;
    
                        img {
                            display: block;
                            width: 25px;
                            height: 25px;
                            @include valign;

                            @include mq($until: tablet) {
                                width: 20px;
                                height: 20px;
                            }

                        }
                    
                    }

                }

                p {
                    @include font(16px, $blue);
                    @include font-weight(regular);
                    text-decoration: none;

                    @include mq($until: laptop-s) {
                        font-size: 5cqw;
                    }

                    @include mq($until: tablet) {
                        font-size: 7.5cqw;
                    }

                }

            }

        }

    }

}