header.header {
    margin-top: 40px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    @include mq($until: laptop-s) {
        margin-top: 20px;
    }

    .header--flex {
        @include flex;
        justify-content: space-between;

        @include mq($until: laptop-s) {
            align-items: center;
        }

        .logo {
            display: inline-block;

            a {
                display: block;
                width: 100%;
                max-width: 350px;

                @media screen and (max-width:1500px) {
                    max-width: 250px;
                }

                @media screen and (max-width:1070px) {
                    max-width: 200px;
                }


                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }

        }

        .header--nav {
            display: inline-block;

            @media screen and (max-width:1380px) {
                width: 77%;
                // width: calc(100% - 250px);
            }

        }

        .burger {
            height: 20px;
            display: none;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            cursor: pointer;
            z-index: 95;

            @include mq($until: laptop-s) {
                display: flex;
            }

            .burger__line {
                width: 25px;
                height: 2px;
                border-radius: 2px;
                background-color: white;
                transition: transform .4s ease-in-out, opacity .4s ease-in-out, visibility .4s ease-in-out;
            }

            &:hover {
                .burger__line {
                    background-color: $light-blue;
                }
            }

            &.active {
                .burger__line {
                    &.burger__line--top {
                        transform: translateY(9px) rotate(45deg);
                    }

                    &.burger__line--middle {
                        opacity: 0;
                        visibility: hidden;
                        transform: translateX(30px);
                    }

                    &.burger__line--bottom {
                        transform: translateY(-9px) rotate(-45deg);
                    }
                }
            }

        }

    }

}