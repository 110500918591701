.home--section-blocks {
    position: relative;
    margin-top: -15vw;
    margin-bottom: 100px;

    @include mq($until: tablet) {
        margin-bottom: 30px;
    }

    @media screen and (max-width: 660px) {
        margin-bottom: 0;
    }

    .home--section-blocks-flex {
        @include flex;

        @media screen and (max-width: 660px) {
            flex-wrap: wrap;
        }

        .home--section-block {
            display: inline-flex;
            flex-direction: column;
            align-items: flex-end;
            width: 25%;
            margin-right: 20px;
            text-decoration: none;
            overflow: hidden;
            position: relative;
            container-type: inline-size;
            container-name: card;

            @media screen and (max-width: 660px) {
                width: calc(50% - 10px);
                height: 100%;
                margin-bottom: 20px;

                &:nth-child(odd) {
                    margin-right: 20px !important;
                }
                &:nth-child(even) {
                    margin-right: 0px !important;
                }

            }

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                .home--section-block-copy {
                    height: auto;
                    bottom: 0;
                }
            }

            .home--section-block-img {
                display: block;
                width: 100%;
                height: 100%;
                overflow: hidden;
                @include radius-left;
                @include radius-right;
                @include animate;

                @media (pointer:coarse) {
                    height: auto;
                }

                img, picture {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

            }

            .home--section-block-copy {
                display: block;
                position: absolute;
                bottom: -100%;
                @include animate;
                width: 100%;
                padding: 6cqw 6cqw 0 6cqw;
                background-color: white;

                @media (pointer:coarse) {
                    position: static;
                    height: auto;
                }

                @include mq($until: tablet) {
                    position: static;
                    height: auto;
                }

                h2 {
                    @include font(34px, $blue);
                    @include font-weight(semibold);
                    // margin: 0 0 20px 0;
                    margin: 0;
                    text-decoration: none;
                    position: relative;
                    padding: 0 50px 0 0;

                    @include mq($until: laptop-l) {
                        font-size: 10cqw;
                        padding-right: 12cqw;
                    }

                    @media only screen 
                        and (min-width: 1024px) 
                        and (max-height: 1366px) 
                        and (-webkit-min-device-pixel-ratio: 1.5) {
                            font-size: 24px;
                            margin-bottom: 5px;
                        }

                    @include mq($until: tablet) {
                        @supports not (container-type: inline-size) {
                            font-size: 24px;
                        }
                    }

                    span {
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        top: 0;
                        height: 100%;
                        width: 30px;

                        @include mq($until: laptop-l) {
                            // width: 10cqw;
                            width: 20px;
                        }

                        img {
                            display: block;
                            width: 100%;
                            height: auto;
                            @include valign;
                        }
                    
                    }

                }

                p {
                    @include font(18px, $blue);
                    @include font-weight(regular);
                    padding-bottom: 0;
                    text-decoration: none;
                    font-size: 5cqw;

                    @include mq($until:mobile-l) {
                        font-size: 8cqw;
                    }

                }

            }

        }

    }

}